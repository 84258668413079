.navigationWrapper {
  position: absolute;
  right: 30px;
  bottom: 30px;

  @media (max-width: 1200px) {
    right: 10px;
    bottom: 10px;
  }
}

.textTT {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin-top: 60px;
  margin-left: 20px;
  color: #f6f6f6;
}

.textTrust {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-left: 50px;
  color: #f6f6f6;
}

.textRight {
  transition: 1s;
  font-family: 'Monserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #ffffffb2;

  &:hover {
    transform: scale(1.1);
    text-align: left;
    color: #ebebeb;
  }

  @media (max-width: 1200px) {
    display: none;
  }
}

.big {
  font-size: 18px;
  color: #ffffff;
  transform: scale(1.1);
}

.divRight {
  font-size: 18px;
  cursor: pointer;
  display: flex;
  margin-bottom: 15px;
  justify-content: flex-end;
  align-items: center;

  &:hover {
    transform: scale(1.1);
  }
}

.numberRight {
  width: 25px;
  height: 25px;
  line-height: 25px;
  margin-left: 12px;
  cursor: pointer;
  text-align: center;
  background-color: #ffffff99;

  @media (max-width: 1200px) {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
  }
}

.numRight {
  width: 10px;
  height: 10px;
  //border: 1px solid;
  padding-left: 5px;
  margin-left: 158px;
  margin-bottom: 15px;
  cursor: pointer;
  background-color: #afafaf;
}
// .numberRight:hover {
//   transform: scale(1.3);
// }

.layout {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  //color: var(--color-white);
  position: relative;

  .overlay {
    background-color: var(--color-overlay);
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: -1;
  }
}
.video {
  position: absolute;
  min-height: 100vh;
  z-index: -1;
  min-width: 100vw;
}
.videoWrapper {
  width: 1860px;
  max-width: 100%;
  position: absolute;
  z-index: -10;
}
