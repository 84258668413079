@import '/src/styles/mixins';

$greenColor: #4caf50;
$greyColor: #757575;

.contentBlock {
  width: 100%;
  // margin-left: 1.2% !important;
  // margin-right: 1.2% !important;
  // width: 1000px;
  // @media only screen and (max-width: 1660px) {
  //   width: 1000px;
  // }

  // @include for-mobile-max_767 {
  //   margin-left: 0% !important;
  //   margin-right: 0% !important;
  //   min-width: 320px !important;
  // }
  // @include for-mobile-max_767 {
  //   flex-wrap: wrap;
  // }

  .answersDetail {
    background-color: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    padding: 32px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;

    .noResult {
      margin-top: 20px;
    }

    h1 {
      margin: 0;
    }

    @media screen and (max-width: 540px) {
      padding: 16px;
    }
  }

  .blockHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
    .returnButton {
      cursor: pointer;
      display: flex;
      gap: 12px;
      padding: 0px 20px;
      align-items: center;
      font-family: Montserrat;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0%;
      color: #212121 !important;
      text-transform: none;
      @media screen and (max-width: 380px) {
        padding: 0;
      }
    }
  }
  .filtersRow {
    display: flex;
    justify-content: flex-end;
  }
  .backButton {
    padding: 0px 20px;
    border: 1px solid #18397a;
    border-radius: 4px;
    cursor: pointer;
  }
  .backButton p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    color: #18397a;
  }
  .shareButton {
    gap: 12px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
  }
  .shareButton p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    color: #18397a;
  }

  .localMainFrame {
    margin-top: 20px;

    .localAnswerBlock {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 40px;
      gap: 20px;
      width: 100%;
      height: auto;
      margin-top: 20px;
      background: #ffffff;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
      backdrop-filter: blur(8px);

      /* Note: backdrop-filter has minimal browser support */
      border-radius: 12px;

      .labelAnswer {
        font-weight: 700;
        color: rgb(100, 100, 100);
      }
      .questionTitle {
        width: auto;
      }
    }
  }

  .questionTitle {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 10px;
    -ms-flex-align: center;
    align-items: center;
    width: 240px;
    margin-right: 20px;
    word-wrap: break-word;
  }
  .qbuttonsvg {
    overflow: visible;
  }
  .answered {
    fill: $greenColor;
    color: $greenColor;
  }
  .required {
    fill: $greyColor;
    color: $greyColor;
  }

  .productMainFrame,
  .companyMainFrame,
  .globalMainFrame {
    margin-top: 20px;

    .sourceBlock {
      margin-bottom: 20px;
      padding: 24px 24px;
      isolation: isolate;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: start;
      -ms-flex-pack: justify;
      justify-content: space-between;
      align-items: flex-start;
      background: #ffffff;
      box-shadow: 0px 4px 16px rgb(0 0 0 / 6%);
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
      border-radius: 12px;

      @media screen and (max-width: 580px) {
        padding: 16px;
        flex-direction: column;
        row-gap: 16px;
      }
    }

    .componentNum {
      margin-bottom: 10px;
      font-weight: 700;
    }

    .sourceName {
      font-weight: 700;
      margin-bottom: 20px;
    }
    .componentBlock {
      display: -ms-flexbox;
      display: flex;
      width: 550px;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      row-gap: 30px;
      word-wrap: break-word;

      @media screen and (max-width: 580px) {
        width: 100%;
      }
    }

    .componentDetails {
      display: flex;
      width: fit-content;
      flex-wrap: wrap;
      row-gap: 10px;
      column-gap: 50px;

      div {
        span {
          font-weight: 700;
          color: rgb(100, 100, 100);
        }
      }
    }
  }
}

.spanText {
  font-weight: 700;
  color: rgb(100, 100, 100);
}

.globalMainFrame {
  .globalTitle {
    display: flex;
    flex-direction: row;

    h4 {
      margin-left: 10px;
    }
  }

  .subquestionBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .subquestionText {
      font-weight: 600;
    }
  }
}

.summary {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
