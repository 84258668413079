@import '../../../../styles/_mixins.scss';

.menuCarouselContainer {
  position: relative;
  font-size: 1.823vw;
  color: rgba(255, 255, 255, 0.4);
  overflow: hidden;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .menuCarouselItem {
    font-size: 0.833vw;
    font-weight: 400;

    display: flex;

    width: 17.7vw;
    padding: 1.25vw 0;
    height: 1vw;
    margin: 0 auto;

    cursor: pointer;
    transition:
      font-size 0.1s,
      font-weight 0.1s;

    color: #8f8f8f;

    align-items: center;
    justify-content: center;

    & > a {
      display: none;
    }

    @include for-mobile-max_767 {
      font-size: 1.823vw;
      padding: 3vw 0;
      width: 23vw;
    }

    @include for-mobile-max_480 {
      font-size: 14px;
      width: 80vw;
      padding: 20px 0;
    }

    @include for-mobile-s {
      padding: 3.3vw 0;
    }
  }

  .menuCarouselItemSizeFirst {
    font-size: 1.25vw;
    font-weight: 600;

    color: #d4b561;

    & > div {
      display: none;
    }

    & > a {
      display: block;
      position: relative;
      width: 100%;
      text-align: center;

      &::after {
        content: '';
        width: 1.5625vw;
        height: 1.5625vw;
        position: absolute;
        top: 0;
        right: -0.5vw;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 80%;
        background-image: url(../../../../../public/images/templates/arrow-circle-icon.svg);

        @include for-mobile-max_767 {
          width: 3.8125vw;
          height: 3.8125vw;
          right: 0;
        }

        @include for-mobile-max_480 {
          width: 24px;
          height: 24px;
          background-size: 100%;
        }
      }
    }

    @include for-mobile-max_767 {
      width: 33vw;
      font-size: 2.34375vw;
      line-height: 3.8125vw;
    }

    @include for-mobile-max_480 {
      width: 80vw;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .menuCarouselItemSizeSecond {
    font-size: 1.25vw;
    font-weight: 600;

    color: #fff;

    @include for-mobile-max_767 {
      font-size: 2.34375vw;
    }

    @include for-mobile-max_480 {
      font-size: 16px;
    }
  }

  .menuCarouselItemSizeThird {
    font-size: 1.04167vw;
    font-weight: 400;

    color: #fff;

    @include for-mobile-max_767 {
      font-size: 2.0833vw;
    }

    @include for-mobile-max_480 {
      font-size: 14px;
    }
  }
}
