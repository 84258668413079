@import '/src/styles/mixins';

.searchBox {
  height: 40px;
  width: 100%;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-radius: 12px;
}

.searchImg {
  margin: 20px 10px 16px 20px;
}

.search {
  background-color: #f9f9f9;
  height: 24px;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 16px;
}

.searchInput {
  border: #f9f9f9;
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #757575;

  &:focus {
    outline: none;
  }
}

//@media screen and (max-width: 520px) {
//  .searchBox {
//    width: 179px;
//  }
//
//  .searchInput {
//    font-weight: 400;
//    font-size: 14px;
//    line-height: 24px;
//  }
//
//  .searchImg {
//    width: 11px;
//    height: 11px;
//    margin: 0px 20px 2px 11px;
//  }
//}

//
//@media screen and (max-width: 375px) {
//  .searchBox {
//    width: 100%;
//  }
//}
