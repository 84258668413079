.container {
  margin-top: 16px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;

  .chartArea {
    width: 300px;
    height: 300px;
  }

  .textArea {
    max-width: max-content;
    font-family: 'Montserrat';
    font-style: normal;
    color: #212121;
    height: 100%;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 320px;

    .owerflowContainer {
      height: 60vh;
      padding-right: 30px;
      margin-right: -30px;
      overflow: auto;

      .agreeCheck {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  @media screen and (max-width: 700px) {
    flex-direction: row;

    .chartArea {
      margin: unset;
      padding: 10px;
    }

    .textArea {
      margin: auto auto auto 0;
      .owerflowContainer {
        height: 100%;
        padding-right: 0;
        margin-right: 0;
        overflow: visible;
      }
    }
  }
}

.buttonCont {
  display: flex;
  flex-direction: row;
  flex: 1 2 auto;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  gap: 16px;
  padding-top: 16px;
  padding-bottom: 16px;

  button {
    @media screen and (max-width: 600px) {
      width: 100%;
      max-width: none;
    }
  }
}
