.modalContainer {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .resultBlock {
    .radioGroup {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .radioItem {
        width: 100%;
        display: block;
        > span:nth-of-type(2) {
          width: fit-content;
          display: flex;
          width: 100%;
        }
        .radioItemBlock {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          > div {
            flex: 1 1;
          }
          .radioItemIcon {
            border-radius: 50%;
          }
        }
      }
    }
  }

  .buttonBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
