@import '../../../../../src/styles/mixins';

.filtersAndList {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .foundedCount {
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #757575;
  }
}
.newConnectionsBlock {
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  padding: 20px 40px;
  gap: 16px;
  flex-direction: column;

  @include for-mobile-max_620 {
    padding: 20px;
  }
  .title {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  .connectedUsers {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: 1560px) {
      flex-direction: column;
    }
    @media (max-width: 1024px) {
      flex-direction: row;
    }
    @media (max-width: 960px) {
      flex-direction: column;
    }
    .connectItem {
      width: calc(50% - 10px);
      padding: 12px 24px;
      border-radius: 12px;
      box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 1560px) {
        width: 100%;
      }

      @media (max-width: 1024px) {
        width: calc(50% - 10px);
      }

      @media (max-width: 960px) {
        width: 100%;
      }

      @media (max-width: 520px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
      }

      .infoBlock {
        display: flex;
        gap: 12px;

        .userInfo {
          display: flex;
          flex-direction: column;
          min-width: 143px;
          max-width: 143px;
          .userName {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: 'Montserrat';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .invitedBy {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: 'Montserrat';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
          .experiences {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            font-family: 'Montserrat';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;

            .experienceName {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: flex;
              align-items: center;
              flex-wrap: nowrap;

              .greenText {
                color: #4caf50;
                margin-left: 3px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
      .connectionActions {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: 520px) {
          flex-direction: row;
          gap: 12px;
        }

        .btn {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          color: #18397a;
          font-family: 'Montserrat' !important;
          font-size: 12px !important;
          font-style: normal !important;
          font-weight: 600 !important;
          line-height: 40px !important;
          text-transform: uppercase !important;
        }
      }
    }
  }
  .viewAllBlock {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .viewAllButton {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      .text {
        color: #18397a;
        text-decoration: underline;
        font-family: 'Montserrat';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      .downIcon {
        height: 20px;
        width: 20px;

        svg path {
          fill: #18397a;
        }
      }
    }
  }
}
