@import '../../../../../styles/_mixins.scss';

.modalContainer {
  max-width: 608px;

  .modalContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    .avatarBlock {
      display: flex;
      align-items: center;
      gap: 12px;

      @include for-mobile-max_620 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .bottomInputs {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
    }
  }
  .buttonGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.accountLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #757575;
  width: 128px;
  height: 128px;
  overflow: hidden;
  cursor: pointer;
  min-width: 128px;
  position: relative;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    .accountLogoHover {
      transition: opacity 0.3s ease-out;
      opacity: 1;
    }
  }

  .accountLogoHover {
    background-color: #05050598;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px;
    width: 100%;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 25px;
      height: 23px;
    }
  }
  svg path {
    fill: white;
  }
}

.modal_input {
  position: relative;
  width: 100%;

  @include for-mobile-max_767 {
    // width: 90% !important ;
  }

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }

  & > label {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 16px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.topInputs {
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.select {
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #efefef;

  font-family: 'Montserrat' !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  border-radius: 12px !important;
}

.tagChip {
  background-color: #efefef;
  border-radius: 12px;
  padding: 5px;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.removeIcon {
  padding: 0 !important;
  &:hover {
    background-color: transparent !important;
  }
}

.tagError {
  font-family: 'Montserrat';
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  margin-top: 0 !important;
  color: red;
}

.modalButton {
  @include for-mobile-max_620 {
    font-family: 'Montserrat' !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 40px !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
}
