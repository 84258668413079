@import '/src/styles/mixins';

.container {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  height: max-content;
  background-color: white;
  border-radius: 12px;
  padding: 20px 32px;

  @media only screen and (max-width: 1660px) {
    width: 100%;
    max-width: 100%;
  }
}

.prewiew {
  display: flex;
  margin-right: 30vw;
}

.select {
  > div > div {
    font-size: 16px;
    font-weight: 500;

    > img {
      height: 26px;
    }
  }

  @media screen and (max-width: 420px) {
    min-width: unset !important;
  }
}

.selectItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  padding: 5px 0;

  > span:nth-child(2) {
    margin-left: 9px;

    @media screen and (max-width: 420px) {
      display: none;
    }
  }
}

.selectBody {
  box-shadow: 0 4px 32px 0 #00000014 !important;
  border-radius: 12px !important;

  li {
    margin: 5px 0;
    background-color: transparent !important;

    &:hover {
      background-color: inherit !important;
      color: #143471 !important;
    }
  }
}

.addFileIcon {
  cursor: pointer;
  //width: 24px;
  //height: 24px;
  margin-left: 25px;
}

.inputOptions {
  display: flex;
  align-items: center;
  padding-left: 12px;
  border-radius: 12px;

  ::before,
  ::after {
    border: none !important;
  }

  *:focus {
    background: transparent !important;
  }

  @media screen and (max-width: 420px) {
    padding-left: 5px;
  }

  > div {
    border-radius: 12px !important;
  }

  > div > div {
    border: none !important;
    border-radius: 12px !important;
  }
}

.newPostButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  padding-left: 10px;

  @include for-mobile-max_767 {
    padding-left: 0;
  }
}

.newPostBtn {
  position: relative;
  width: 100%;
  border: 1px solid #efefef;
  border-radius: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #606060;
  cursor: pointer;
  padding: 12px 20px;

  &:hover {
    border: 1px solid #d3d3d3 !important;
  }
}

.newPostInput {
  position: relative;
  width: 100%;

  & > div {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  &:hover {
    fieldset {
      border: 1px solid #d3d3d3 !important;
    }
  }

  & > div > input {
    padding: 12px 20px;
    cursor: pointer;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;

    & > legend {
      font-style: normal;
      font-weight: 400;
    }
  }
}

.image {
  cursor: pointer;
}

.file {
  width: 2vw;
  height: 5vh;
}

.dropdown {
  position: absolute;
  z-index: 3231;
  background-color: white;
  border: 1px solid grey;
}
