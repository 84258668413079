.video {
  width: 100% !important;
  height: 100% !important;
  padding-top: 56% !important;
}

.videoBox {
  font-family: sans-serif;
  text-align: center;
  width: 100%;
  border-radius: 12px !important;

  video {
    border-radius: 12px !important;
  }
}

.video-js.video {
  * {
    & :before {
      text-shadow: 1px 1px 7px rgba(10, 10, 10, 0.5);
    }
  }

  &:hover {
    .vjs-big-play-button {
      background-color: rgba(255, 255, 255, 1);
    }
  }

  .vjs-big-play-button {
    height: initial;
    width: initial;
    font-size: 5em;
    line-height: 6.15rem;
    padding: 1em;
    border: none;
    border-radius: 9999px;
    background-color: rgba(206, 14, 14, 0.8);

    & :before {
      margin: 0;
      padding: 0;
    }
  }

  .vjs-control-bar {
    width: 90%;
    min-height: 5em;
    margin: 2rem auto;
    padding: 0 1em;
    border-radius: 1em;
  }
}
