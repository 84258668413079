@import '/src/styles/mixins';

.fieldWrapper {
  width: 100%;
  max-width: 1020px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 15px;

  @include for-mobile-max_767 {
    display: flex;
    align-items: center !important;
  }
}
.mainInformation {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px 50px 20px;
  width: 100%;

  @include for-mobile-max_767 {
    flex-direction: column;
    align-items: center;
    padding: 20px 26px;
  }
}
.leftContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
  padding-top: 40px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #212121;

  @include for-mobile-max_767 {
    position: relative;
    padding-bottom: 15px;
    width: 100%;
  }

  & > .rightContent {
    display: none;

    @include for-mobile-max_767 {
      display: flex;
      position: absolute;
      left: calc(75% - 40px);
      padding-top: 20px;
    }

    @include for-mobile-max_480 {
      left: calc(75% - 10px);
    }
  }
}
.middleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;

  @include for-mobile-max_767 {
    padding-bottom: 15px;
  }
}
.modal_buttonSend {
  display: flex;
  height: max-content;
  justify-content: space-around;
  width: 140px;
  background: none;
  border: 1px solid #18397a;
  outline: none;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0;
  color: #18397a;
  border-radius: 6px;
  margin-bottom: 10px;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  @include for-mobile-max_480 {
    width: 80px;
    font-size: 12px;
    line-height: 40px;
  }
}

.password {
  padding-bottom: 12px;
}

.headerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include for-mobile-max_767 {
    width: 100vw !important ;
    display: flex;
    justify-content: space-around;
    align-items: center !important;
  }
}
.language {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.midContent {
  display: flex;
  width: 98%;
  padding-top: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @include for-mobile-max_767 {
    width: 100vw !important ;
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
  }
}

.rightContent {
  display: flex;
  justify-content: center;
  width: 30%;
  padding-top: 40px;
  padding-left: 70px;

  & > div {
    width: 100%;
  }

  @include for-mobile-max_767 {
    display: none;
  }
}

.buttonsWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.editBtn {
  display: flex;
  gap: 12px;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  height: fit-content;

  font-size: 16px;
  line-height: 48px;
  font-weight: 700;
  color: #18397a;
  cursor: pointer;
}

.blocked {
  display: flex;
  justify-content: center;
}

.blockedMessage {
  display: flex;
  align-items: center;
  background: #757575;
  border-radius: 12px;
  padding: 12px 20px;
  width: fit-content;
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: #fff;
    margin-left: 10px;
  }
  .blockedIcon {
    svg > path {
      fill: #fff !important;
      // если не сработало, то так:
      stroke: #fff !important;
    }
  }
}
