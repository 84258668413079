@import '../../../../../src/styles/mixins';

.filtersAndList {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .foundedCount {
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #757575;
  }
}
