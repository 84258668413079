.callTimeoutModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 708px;
  height: 247px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 77px rgba(0, 0, 0, 0.08);
  padding: 32px;
  border-radius: 12px;

  .modalTitle {
    margin-bottom: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }

  .modalContent {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .modalActions {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
  }

  .leftButton {
    padding: 0px 20px;
    border: 1px solid #18397a;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    color: #18397a;
  }

  .rightButton {
    padding: 0px 20px;
    background: #18397a;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    color: #ffffff;
  }
}
