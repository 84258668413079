.toolsLeftSection {
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin-left: 80px;
  }
}

.toolsMain_1 {
  color: #ffffffcc;
  font-weight: 600;
  font-size: 40px;
  margin-top: 20px;
  margin-left: 180px;
  margin-bottom: -30px;

  @media (max-width: 1500px) {
    font-size: 35px;
    margin-left: 80px;
  }

  @media (max-width: 1200px) {
    font-size: 35px;
    margin-bottom: -10px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    margin-left: 8px;
  }
}

.toolsMain_2 {
  position: relative;
  color: #ffffff;
  font-weight: 800;
  font-size: 200px;
  margin-left: 10px;
  margin-top: -20px;
  margin-bottom: -20px;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1500px) {
    font-size: 120px;
    margin-left: 80px;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: 1200px) {
    font-size: 120px;
    margin-left: 80px;
    margin-top: -10px;
    margin-bottom: -30px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    font-size: 70px;
    margin-top: -5px;
    margin-bottom: 0;
  }
}

.toolsMain_3 {
  position: relative;
  color: #ffffffea;
  font-weight: 600;
  font-size: 100px;
  margin-left: 350px;
  margin-top: -70px;
  margin-bottom: -40px;
  z-index: 12;

  @media (max-width: 1500px) {
    font-size: 40px;
    margin-top: -40px;
    margin-bottom: 0;
  }

  @media (max-width: 1200px) {
    font-size: 40px;
    margin-left: 340px;
    margin-top: -15px;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    margin-left: 150px;
    margin-top: -17px;
  }
}

.toolsMain_4 {
  position: relative;
  color: #ffffff;
  font-weight: 700;
  font-size: 150px;
  margin-left: 140px;
  margin-top: -20px;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1500px) {
    font-size: 80px;
  }

  @media (max-width: 1200px) {
    font-size: 60px;
    margin-left: 160px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    margin-left: 35px;
    margin-top: -12px;
  }
}

.toolsStepLeft_1 {
  color: #ffffffcc;
  font-weight: 600;
  font-size: 40px;
  margin-top: 20px;
  margin-left: 200px;
  margin-bottom: -35px;

  @media (max-width: 1500px) {
    font-size: 35px;
    margin-left: 80px;
    margin-bottom: 0;
  }

  @media (max-width: 1200px) {
    font-size: 20px;
    margin-top: 0;
    margin-left: 0;
    padding-top: 20px;
    width: 120px;
    overflow: hidden;
    word-wrap: break-word;
    text-align: right;
  }

  @media (max-width: 768px) {
    width: 70px;
    font-size: 12px;
    line-height: 10px;
  }
}

.toolsStepLeft_2 {
  position: relative;
  color: #ffffff;
  font-weight: 800;
  font-size: 180px;
  margin-left: 80px;
  margin-top: -20px;
  margin-bottom: -20px;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1500px) {
    font-size: 120px;
  }

  @media (max-width: 1200px) {
    font-size: 70px;
    margin-top: -57px;
    margin-bottom: 10px;
    margin-left: 125px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    margin-left: 74px;
    margin-top: -27px;
  }
}

.toolsStepLeft_3 {
  position: relative;
  color: #ffffffea;
  font-weight: 600;
  font-size: 100px;
  margin-left: 350px;
  margin-top: -70px;
  margin-bottom: -10px;
  z-index: 12;

  @media (max-width: 1500px) {
    font-size: 40px;
    margin-left: 340px;
    margin-top: -25px;
    margin-bottom: -5px;
  }

  @media (max-width: 1200px) {
    font-size: 15px;
    margin-left: 320px;
    margin-top: -68px;
    padding-bottom: 20px;
  }

  @media (max-width: 768px) {
    font-size: 10px;
    margin-left: 160px;
    margin-top: -35px;
  }
}

.toolsStepLeft_4 {
  position: relative;
  color: #ffffff;
  font-weight: 700;
  font-size: 50px;
  margin-left: 290px;
  margin-top: -20px;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1500px) {
    font-size: 60px;
    margin-left: 105px;
  }

  @media (max-width: 1200px) {
    font-size: 20px;
    margin-left: 320px;
    padding-bottom: 30px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    margin-left: 160px;
    padding-bottom: 25px;
  }
}

.toolsStepContentWrapper {
  color: #ffffffea;
  font-weight: 500;
  font-size: 25px;
  width: 100%;
  max-width: 580px;
  line-height: 37.5px;
  margin-left: 60px;

  @media (max-width: 1500px) {
    max-width: 450px;
    font-size: 20px;
  }

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    margin-left: 25px;
    margin-right: 25px;
    width: unset;
    font-size: 12px;
    line-height: 20px;
  }
}
