.commentsContainer {
  display: flex;
  flex-direction: column;
}

.newCommentWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.newCommentBlock,
.newReplyBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.newReplyBlock {
  margin-top: 10px;
}

.newReplyContainer {
  display: flex;
  flex-direction: column;
}

.avatar {
  margin-right: 10px;

  @media screen and (max-width: 400px) {
    display: none !important;
  }
}

.newCommentButton {
  padding: 15px;

  * {
    cursor: pointer;
  }
}

.commentsPreviewFiles {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.newCommentInput,
.newCommentInputError {
  position: relative;
  width: 100%;
  outline: 0 !important;

  &:hover,
  &:focus {
    & > div > fieldset {
      border: 1px solid #e5e5e5;
    }
  }

  & > div {
    //font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12px 20px;
    outline: 0 !important;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;
    outline: 0 !important;

    & > legend {
      //font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}

.newCommentInputError {
  fieldset {
    border: 1px solid #d32f2f !important;
  }

  &:hover,
  &:focus {
    fieldset {
      border: 1px solid #d32f2f !important;
    }
  }
}

.commentsListContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.noCommentsView {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.commentsWrapper {
  margin: 26px 0;
  padding: 0 28px;

  @media screen and (max-width: 400px) {
    padding: 0;
  }
}

.filterContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  > div {
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
}

.commentContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 12px 0;
  border-bottom: 1px solid #efefef;

  &:last-child {
    border-bottom: none;
  }
}

.replyContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 12px 0;
  border-top: 1px solid #efefef;

  &:first-child {
    border-bottom: none;
  }
}

.menuContainer {
  position: absolute;
  top: 10px;
  right: 0;
}

.commentContent {
  width: 100%;
}

.commentUserName {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}

.commentText {
  font-size: 16px;
  line-height: 24px;
}

.commentMenuButton {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px !important;
}

.commentActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  margin-top: 8px;

  > div {
    color: #18397a;
    cursor: pointer;
  }
}

.commentsFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commentsShowMore {
  color: #18397a;
  cursor: pointer;
}

.repliesContainer {
  margin-top: 10px;
  border-top: 1px solid #efefef;
}

.fileBox {
  display: flex;
  max-width: 1020px !important;
  padding: 10px 0;
  margin-left: 0 !important;
  margin-right: 0 !important;

  @media only screen and (max-width: 1660px) {
    width: 100%;
    max-width: 100% !important;
  }
}

.videoplayer {
  max-height: 800px !important;
  width: 42vw !important;
  max-width: 1020px !important;
}

.audioPlayer {
  width: 100%;
}

.audioPlayerDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  width: 80% !important;
  margin-left: 5%;
}

.audioPlayerName {
  padding-left: 4%;
  width: max-content;
  white-space: nowrap;
  line-height: 20px;
  text-align: right;
  overflow: hidden;
  font-size: 16px;
  -webkit-animation: alternat 20s 1s infinite;
  animation: alternat 20s 1s infinite;
}

.imagesWrapper {
  max-width: unset !important;
  width: calc(100% + 64px);
  margin: 0 -32px !important;
  max-height: 200px !important;

  img {
    max-height: 200px !important;
    cursor: pointer;
  }

  @media screen and (max-width: 400px) {
    width: 100%;
    margin: 0 -16px !important;
  }
}

.dropdown {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 10px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.commentAction {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 8px;

  &:hover {
    background: #f0f0f0;
    border-radius: 8px;
  }
}
