.mainButton {
  .proccedIcon {
    width: 18px;
    height: 18px;
    > svg {
      > path {
        fill: #18397a;
      }
    }
  }
}

.warningModal {
  .buttonBlock {
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 400px) {
      display: flex;
      flex-direction: column;
      gap: 10px;
      button {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
