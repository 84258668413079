@import '../../../../../styles/_mixins.scss';

.sectionWrapper {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-left: 50px;

  @include for-desktop-up-max_1860 {
    width: 80%;
    align-items: center;
  }

  @include for-desktop-up-max_1440 {
    margin-left: 0;
    margin-top: 30px;
  }

  @include for-desktop-up-max_1200 {
    width: 90%;
  }

  @include for-mobile-max_480 {
    width: 100%;
  }
}

.imgBgWrapper {
  display: flex;
  margin-bottom: 32px;
  max-width: 1200px;
  height: 680px;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include for-desktop-up-max_1860 {
    height: 480px;
    max-width: 1000px;
  }

  @include for-desktop-up-max_1200 {
    height: 100%;
  }

  @include for-mobile-max_480 {
    flex-direction: column;
    width: 100%;
  }
}

.line {
  width: 44px;
  height: calc(100% - 40px);

  @include for-desktop-up-max_1860 {
    width: 30px;
    height: calc(100% - 25px);
  }

  @include for-mobile-max_480 {
    display: none;
  }
}

.descriptionWrapper {
  position: relative;
  display: flex;
  padding-right: 30px;
  margin-left: 67px;
  min-width: 780px;

  border-radius: 0 10px 10px 0;
  background: rgba(0, 0, 0, 0.32);
  backdrop-filter: blur(8px);

  @include for-desktop-up-max_1860 {
    padding-right: 20px;
    margin-left: 47px;
  }

  @include for-desktop-up-max_1200 {
    min-width: auto;
  }

  @include for-mobile-max_480 {
    padding-right: 0;
    margin-left: 0;
    background-color: transparent;
    backdrop-filter: none;
  }
}

.descriptionSecondWrapper {
  margin-left: 16px;
  width: fit-content;

  @include text-selection-enabled;

  @include for-mobile-max_480 {
    margin-left: 0;
  }
}

.title,
.subTitle {
  margin-bottom: 16px;
  color: #fff;
}

.title,
.firstTitle,
.titleMobile {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;

  @include for-desktop-up-max_1860 {
    font-size: 24px;
    line-height: 36px;
  }

  @include for-mobile-max_480 {
    font-size: 18px;
    line-height: 24px;
  }
}

.title {
  @include for-mobile-max_480 {
    display: none;
  }
}

.titleMobile {
  margin: 10px 50px 10px 24px;
  color: #fff;
}

.firstTitle {
  margin-top: 32px;
  color: #fff;
  margin-bottom: 0;

  @include for-mobile-max_480 {
    display: none;
  }
}

.subTitle,
.description {
  font-size: 16px;
  line-height: 24px;

  @include for-desktop-up-max_1860 {
    font-size: 14px;
    line-height: 20px;
  }

  @include for-mobile-max_480 {
    font-size: 12px;
    line-height: 16px;
  }
}

.subTitle {
  font-weight: 500;

  @include for-mobile-max_480 {
    font-size: 14px;
  }
}

.description {
  font-weight: 400;
  color: #fff;

  @include for-desktop-up-max_1200 {
    margin-bottom: 120px;
  }

  @include for-mobile-max_480 {
    margin-bottom: 0;
  }
}

.iconWrapper {
  position: absolute;
  bottom: 0;
  left: 56px;
  margin-bottom: 40px;

  @include for-desktop-up-max_1860 {
    margin-bottom: 0;
    left: 20px;
    transform: scale(0.5);
  }

  @include for-mobile-max_480 {
    display: none;
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;

  @include for-desktop-up-max_1860 {
    max-width: 1000px;
  }

  @include for-desktop-up-max_1440 {
    margin-bottom: 100px;
  }
}

.btn {
  align-self: end;
  padding: 0px 20px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  border: 2px solid #ffffff;
  background: transparent;
  color: #ffffff;

  &:disabled {
    background: lightgray;
    color: grey;
    border: 2px solid grey;
  }

  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.8);
  }

  @include for-mobile-max_480 {
    font-size: 14px;
    line-height: 32px;
  }
}

.lineIconMobileWrapper {
  position: relative;
  display: none;
  width: 100%;
  margin-bottom: 12px;

  @include for-mobile-max_480 {
    display: flex;
    align-items: center;
  }

  & > .lineMobile {
    width: 92%;
    margin-left: -24px;
  }

  & > .iconWrapperMobile {
    position: absolute;
  }
}
