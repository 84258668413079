.modalRoot {
  background: rgba(250, 250, 250, 0.8);
  backdrop-filter: blur(14px);
}

.deleteModalWrapper {
  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  max-width: 604px;
  padding: 12px 24px;
}

.modalTitle {
  align-self: flex-start;
  margin-bottom: 24px;

  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #555555;
}

.modalText {
  align-self: flex-start;
  margin-bottom: 24px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.buttonWrapper {
  align-self: flex-end;
  display: flex;
  gap: 20px;
}

.cancelBtn,
.deleteBtn {
  margin: 0;
  padding: 0 20px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
}
