@import '../../../../../../styles/_mixins.scss';

.modalWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(20px);
  border-radius: 10px;
}

.modalTitle {
  font-weight: 600;
  font-size: 25px;
  line-height: 150%;
  color: #ffffff;
  text-align: center;
}

.modalText {
  font-weight: 500;
  font-size: 25px;
  line-height: 150%;
  color: #ffffff;
  margin: 40px;

  @include for-mobile-max_767 {
    font-size: 16px;
    text-align: center;
    margin: 10px;
  }

  @include for-mobile-max_480 {
    font-size: 14px;
    margin: 0;
  }

  @include for-narrow {
    font-size: 14px;
    margin: 10px;
  }

  @include for-screen_3_4 {
    font-size: 14px;
    margin: 10px;
  }
}

.buttonsWrapper {
  margin-top: 20px;
  width: 90%;
  display: flex;
  justify-content: space-between;

  @include for-mobile-max_767 {
    flex-direction: column;
    gap: 16px;
  }

  @include for-mobile-max_480 {
    width: 100%;
  }

  @include for-narrow {
    margin-top: 10px;
  }

  @include for-screen_3_4 {
    margin-top: 10px;
  }
}

.oneChild {
  justify-content: flex-end;
}

.firstBtn,
.secondBtn {
  padding: 13px 28px;
  gap: 28px;
  min-width: 146px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  border: 2px solid #ffffff;

  &:disabled {
    background: lightgray;
    color: grey;
    border: 2px solid grey;
  }

  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.8);
  }

  @include for-mobile-max_767 {
    font-size: 14px;
  }

  @include for-narrow {
    font-size: 14px;
    padding: 8px 14px;
  }

  @include for-screen_3_4 {
    font-size: 14px;
    padding: 8px 14px;
  }
}

.firstBtn {
  background: transparent;
  color: #ffffff;
}

.secondBtn {
  background: #ffffff;
  color: #333333;
}
