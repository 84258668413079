@import '../../styles/_mixins.scss';

.formItemLeft {
  display: flex;

  @include for-mobile-max_767 {
    flex-direction: column;
    padding: 20px 0px;
  }
}

.formTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 48px;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 8px;

  @include for-mobile-max_767 {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    -webkit-writing-mode: horizontal-tb;
    -ms-writing-mode: lr-tb;
    writing-mode: horizontal-tb;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    margin-top: 0px;
    font-weight: 900;
    line-height: 48px;
    font-size: 32px;
  }
}
