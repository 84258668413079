@import '/src/styles/mixins';
.modalRoot {
  background: rgba(250, 250, 250, 0.8);
  backdrop-filter: blur(14px);
  display: flex;
  align-items: center;
  max-width: 100%;
  > div {
    margin: auto;
    > div {
      max-width: 100% !important;
    }
  }
  .modal {
    max-width: fit-content;
    width: unset;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 1200px) {
      max-width: 600px;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
      max-width: 80vw;
    }

    .carbonModalBlock {
      display: flex;
      flex-wrap: wrap;
      padding-top: 24px;
      max-width: 1000px;
      > div {
        flex: 1 1 460px;
      }
    }
  }
}

.modal {
  .text {
    margin-top: 16px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
  }

  .buttonBlock {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.companyBB {
      @media screen and (max-width: 540px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 16px;
        button {
          max-width: 100%;
          width: 100%;
        }
      }
    }
    &.roleBB {
      @media screen and (max-width: 460px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 16px;
        button {
          max-width: 100%;
          width: 100%;
        }
      }
    }
    &.alertBB {
      @media screen and (max-width: 400px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: fit-content;
        gap: 16px;
        button {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
}
.container {
  // @media screen and (min-width: 768px) {
  //   grid-template-columns: 50% 50%;
  // }
  // @media screen and (max-width: 768px) {
  //   grid-template-columns: 100%;
  // }
}
