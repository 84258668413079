.deleteModal {
  width: 604px !important;
  .title {
    padding-right: 40px;
  }
  .modalBody {
    display: flex;
    gap: 12px;
  }
}
