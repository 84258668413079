@import '../../../../styles/_mixins.scss';

.mainEdit {
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > div {
    width: 100%;
  }

  @include for-mobile-max_767 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center !important;
  }
}

.topContent {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 38px;

  @include for-mobile-max_767 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    margin-bottom: 0;
  }
}

.avaDiv {
  position: relative;
  width: 128px;
  height: 128px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}

.avaButton {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  background: rgba(0, 0, 0, 0.4) !important;
  backdrop-filter: blur(2px);
  pointer-events: visible;
}

.nameDiv,
.emailPasswordDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  & > div {
    flex-grow: 1;
  }

  @include for-mobile-max_767 {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
  }
}

// .language {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-around;
//   align-items: center;

//   & input {
//     height: 16px;
//     border-radius: 12px;
//   }
// }

.modal_select {
  font-family: 'Montserrat' !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  height: 48px !important;
  background: white !important;
  border-radius: 16px !important;
  border: 1px solid #efefef !important;
  outline: none !important;
  width: 100% !important;
}

.modal_input {
  position: relative;
  width: 100%;

  @include for-mobile-max_767 {
    // width: 90% !important ;
  }

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }

  & > label {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 16px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.iconWrapper {
  position: absolute !important;
  top: 0.8px;
  right: 1px;
  display: flex;
  align-items: center;
  height: 46px;
  width: 46px;
  background-color: #ffffff !important;
  z-index: 2;
  border-radius: 15px !important;
}

.editIcon {
  opacity: 0.8;
}

.infoDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 3;
}

.midContent {
  display: flex;
  width: 100%;
  gap: 20px;

  & > div {
    flex-grow: 1;
  }

  @include for-mobile-max_767 {
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
  }
}

.timezone {
  width: 100%;

  & > span + div {
    height: 48px;
    border: 1px solid #efefef;

    & > div:nth-child(2) > span {
      background-color: #efefef;
    }
  }

  & > div {
    z-index: 2;
    border-radius: 12px;

    & > div {
      & > div {
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}

.phone {
  height: 48px;
  width: 44px !important;
  background: transparent !important;
  border: none !important;
  border-radius: 16px !important;

  & > div {
    padding: 0 0 0 20px !important;
    margin: 12px 0 12px 0 !important;
    &:focus,
    &:hover {
      background-color: transparent !important;
    }

    &[aria-expanded='true'] {
      background: transparent !important;
    }
  }
}

.phoneInput {
  height: 48px !important;
  width: 100% !important;
  background: #ffffff !important;
  border: 1px solid #efefef !important;
  border-radius: 16px !important;
}

.inputWrapper {
  position: relative;
  flex-grow: 1;
  width: 100%;
}

.arrowWrapper {
  padding: 0 8px;
}

.errorInput {
  border-color: #d32f2f !important;
}

.errorMessage {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
}

// .notificationWrapper > div {
//   background: #2D8342;
//   backdrop-filter: blur(16px);
//   border-radius: 12px;
// }
