@import '../../../../../styles/_mixins.scss';

.contentWrapper {
  align-items: center;
  display: flex;
  height: 65vh;
  margin-top: 10vh;
  transition: 0.5s;
  width: 22.5vw;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  background: hsla(0, 0%, 100%, 0.3);
  border-radius: 6px;

  @include for-mobile-max_480 {
    height: 47vw;
    margin-top: 20vw;
    width: 76.5625vw;
    margin-left: -24px;
  }
}

.heroTop {
  position: relative;
  text-transform: uppercase;
  color: hsla(0, 0%, 100%, 0.6);
  font-size: 8.333vw;
  font-weight: 700;
  margin-left: 3.5vw;
  margin-top: -13vw;

  @include for-mobile-max_480 {
    font-size: 25vw;
    margin-left: 19.5vw;
    margin-top: -16vw;
  }
}

.heroTitle {
  position: absolute;
  bottom: -4.25vw;
  color: hsla(0, 0%, 100%, 0.4);
  font-size: 4.6875vw;
  font-weight: 700;

  @include for-mobile-max_480 {
    display: none;
  }
}

.heroMid {
  position: absolute;
  bottom: -4.5vw;
  font-size: 6.25vw;
  right: 0;

  @include for-mobile-max_480 {
    bottom: -14.5vw;
    font-size: 18.75vw;
  }
}

.heroBot {
  position: absolute;
  bottom: 0;
  color: hsla(0, 0%, 100%, 0.4);
  right: -6.125vw;

  @include for-mobile-max_480 {
    right: -18.5vw;
  }
}

.heroRotate {
  position: absolute;
  display: none;
  bottom: -31vw;
  font-size: 12.5vw;
  right: -3vw;
  color: hsla(0, 0%, 100%, 0.4);
  font-weight: 700;
  text-transform: uppercase;

  @include for-mobile-max_480 {
    display: block;
  }
}
