.rowWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  row-gap: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border-radius: 12px;
  margin-bottom: 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 28px;

  .first {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 8px;
    width: 100%;

    .draftName {
      flex-wrap: nowrap;
      display: flex;
      align-items: center;
      gap: 8px;

      .logo {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
      }

      button > svg {
        width: 16px;
        height: 16px;
        margin-left: 10px;
        margin-top: 4px;
        flex-shrink: 0;
      }
      .rename {
        border: #efefef 1px solid;
        border-radius: 4px;
        padding: 7px;
      }

      .title {
        font-family: 'Montserrat';
        color: #212121;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 0 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 80px;
      }
    }

    .deleteButton {
      width: 24px;
      height: 24px;
      svg > path {
        fill: rgba(211, 47, 47, 1) !important;
      }
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;

    .container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .data {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
      .dataValue {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}
