.editRoleModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 42%;
  height: auto;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 77px rgba(0, 0, 0, 0.08);
  padding: 24px 40px;
  border-radius: 12px;

  @media screen and (max-width: 1024px) {
    width: 78%;
  }

  @media screen and (max-width: 600px) {
    width: 90%;
  }

  .modalTitle {
    margin-bottom: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }

  .modalContent {
    text-transform: capitalize;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    .roleName {
      text-decoration: none;
      font-family: 'Montserrat' !important;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
    }
    .errorText {
      color: red;
      font-family: 'Montserrat' !important;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
    }
    checkedRoleName {
      color: #ff1f00;
    }
  }

  .modalActions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .leftButton {
    padding: 0px 20px;
    border: 1px solid #18397a;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    color: #18397a;
  }

  .rightButton {
    padding: 0px 20px;
    background: #18397a;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    color: #ffffff;
  }
}
