@import '../../../../styles/_mixins.scss';

.primaryButton {
  padding: 12px 20px;
  margin-left: auto;
  margin-right: 0;
  height: 48px;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.buttonWithImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
