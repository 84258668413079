@import '../../styles/_mixins.scss';

.stepItem {
  justify-content: flex-end;
  display: flex;
  margin-bottom: 1.613vw;

  &.active .title,
  &:hover .title {
    display: block;
    color: #fff;
    font-size: 18px;

    @include for-tablet-landscape-max_1024 {
      display: none;
    }
  }
  &.active .count,
  &:hover .count {
    color: #000;
    height: 25px;
    line-height: 25px;
    width: 25px;
  }
}

.container {
  display: flex;
  cursor: pointer;
  font-size: 18px;
  height: 25px;
}

.title {
  color: #ffffffb2;
  font-size: 14px;
  font-weight: 500;
  transition: 0.2s;
  display: none;

  @include for-tablet-landscape-max_1024 {
    display: none;
  }
}

.count {
  background-color: #bbbfbf;
  color: transparent;
  height: 20px;
  line-height: 20px;
  margin-left: 15px;
  text-align: center;
  transition: 0.2s;
  width: 20px;
}

.notClickable {
  .container {
    cursor: default;
  }

  &:hover .title {
    display: none;
  }
  &.active .title {
    display: block;
    color: #fff;
    font-size: 18px;

    @include for-tablet-landscape-max_1024 {
      display: none;
    }
  }
}
