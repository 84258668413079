.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.568);
}

.modal_dialog {
  width: 708px;
  max-width: 812px;
  height: max-content;
  max-height: 800px !important;
  padding-bottom: 20px;
  background: white;
  position: relative;
  margin: 0 20px;
  max-height: calc(100vh - 40px);
  text-align: left;
  display: flex;
  flex-direction: column;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: slide-in;
  animation-duration: 0.5s;
  border-radius: 12px;
  padding: 24px 40px;
  overflow: auto;

  @media only screen and (max-width: 676px) {
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0;
    border-radius: 0;
    padding: 24px;
  }
}

.modal_header,
.modal_footer {
  display: flex;
  align-items: center;
}

.modal-header {
  border-bottom: 1px solid #dbdbdb;
  justify-content: space-between;
}

.modal_footer {
  border-top: 2px solid #dbdbdb;
  justify-content: flex-end;
}

.modal_close {
  cursor: pointer;
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal_body {
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 676px) {
    overflow-x: hidden;
    height: 100%;
  }
}

.modal_content {
  padding: 1rem;
  margin: 1rem;
}

.modal_title {
  margin: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

.videoPlayerContainer {
  width: 100%;
  margin-top: 10px;
  background: transparent !important;

  > div > div {
    background: transparent !important;
  }
}

.prevUploadContainer,
.modalButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.inputWrapper {
  position: relative;
  flex-grow: 1;
  width: 100%;
  margin-top: 20px;
}

.formInput {
  position: relative;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;

  & > label {
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;

    & > legend {
      font-style: normal;
      font-weight: 400;
    }
  }
}

.buttonDefault {
  height: 48px !important;
  max-width: 230px;
  padding: 12px 20px;
  font-size: 16px !important;
  margin: 0;
  cursor: pointer;

  * {
    cursor: pointer;
  }
}

.submitButton {
  font-weight: 700;
  font-size: 16px;
  height: 48px;
  width: 150px;
  padding: 0 20px;
  border-radius: 4px;
  margin-left: 15px;
  margin: 0;
  cursor: pointer;

  * {
    cursor: pointer;
  }
}
