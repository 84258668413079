@import '/src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  padding: 0 32px 24px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(8px);
  border-radius: 12px;
  margin: 0 7px 14px;
  padding-top: 28px;

  .headerContainer {
    display: flex;
    margin-bottom: 17px;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      .icon {
        width: fit-content;
        margin-right: 16px;
      }

      .headerText {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        display: flex;
        color: #212121;
      }
    }
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    margin-bottom: 20px;
  }
  .wide {
    width: 100%;
    max-width: none;
  }
}
