@import '/src/styles/mixins';

.pageContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;

  @include for-tablet-landscape-max_1024 {
    gap: 16px;
  }

  @include for-tablet-landscape-max_900 {
    gap: 12px;
  }
}
