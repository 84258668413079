@import '../../styles/_mixins.scss';

.companyRow {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .name {
    margin-left: 10px;
    white-space: nowrap;
    max-width: 250px;
    position: relative;
    > p {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > svg {
      position: absolute;
      top: -12px;
      right: -13px;
    }
  }

  > button {
    position: relative;
    top: -16px;
  }

  @media screen and (max-width: 500px) {
    .name {
      white-space: wrap;
    }
  }
}

.logo {
  width: 48px;
  height: 48px;

  @include for-mobile-max_767 {
    width: 40px;
    height: 40px;
  }
}
