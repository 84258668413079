@import '/src/styles/mixins';

.contentBox {
  width: 100%;
  display: flex;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
  margin-left: 10px;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);

  @media screen and (max-width: 420px) {
    padding: 16px 15px;
  }
}

.userInfo {
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userInfoBlock {
  display: flex;
  align-items: center;
}

.backButton {
  width: 30px !important;
  height: 30px;
  margin-right: 5px !important;
  margin-left: -5px !important;

  @media screen and (max-width: 420px) {
    margin-left: -10px !important;
  }
}

.chatButtons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 2px;
  margin-right: -5px;

  @media screen and (max-width: 420px) {
    > button {
      display: none !important;
    }
  }
}

.iconDeleted {
  width: 48px;
  height: 48px;
  background: #c4c4c4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat {
  width: 95%;
  display: flex;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  padding-bottom: 15px;
}

.nameAndAge {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.avatar > div > div > div {
  height: 48px;
  width: 48px;
}

.name {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  //line-height: 40px;
  color: #212121;
  white-space: nowrap;
}

.status {
  font-size: 16px;
  line-height: 24px;
  color: #555555;
}

.deleteStatus {
  color: #d32f2f;
}

.deleteUserMessageBlock {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;

  .deleteUserMessage {
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
    color: #d32f2f;
    margin-left: 6px;
  }
}

.typeMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: fit-content;
  position: absolute;
  bottom: 0;
  background: #ffffff;
}

.audioMessage {
  width: 10%;
  display: flex;
  justify-content: center;
}

.typing {
  color: rgba(51, 51, 51, 0.6);
  font-weight: 400;
  font-size: 13px;
  position: absolute;
  left: 25px;
  top: -20px;
}

.typingIcon {
  margin-right: 7px;
}

.typeInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  min-height: 48px;
  padding: 0 20px;
  justify-content: space-between;
  box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 0 50px 50px 12px;
}

.emojiPicker {
  position: relative;
  margin-bottom: -3px;
  margin-right: 5px;
}

.emojiPickerDropdown {
  position: absolute;
  z-index: 3231;
  bottom: 30px;
  left: 0;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.08);
}

.textFieldDiv {
  background-color: #ffffff;
  height: 60%;
  width: 85%;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 16px;
  padding-left: 4px;

  @include for-mobile-max_767 {
    width: 100%;
  }

  @include for-mobile-max_480 {
    width: 60%;
    font-size: 13px;
    padding-left: 0;
  }
}

.textField {
  border: #ffffff;
  width: 98%;
  resize: vertical;
  border: none !important;
  height: 100%;

  @include for-mobile-max_767 {
    width: 100%;
  }
  /* @include for-mobile-max_480 {
    width: 60%
  } */
}

.buttonOptions {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 5px;
  padding: 10px !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
}

.dropdown {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: #ffffff;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 10px;
}

.dropdownText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
}

.dropdownBox {
  position: absolute !important;
  left: -6em;
  font-weight: 500;
  font-size: 16px;
  color: #555555 !important;
}

.dropdownItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 8px;

  &:hover {
    background: #f0f0f0;
    border-radius: 8px;
  }
}

.dropdownItemIcon {
  min-width: 25px !important;
}

.dropdownBox {
  position: absolute !important;
  right: -25px;
}

.noSelectedChat {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputArea {
  position: absolute;
  height: 100%;
  right: 60px;
  width: 252px;
  background-color: #ffffff;
  padding-top: 22px;
  border-radius: 12px;
  > div > div {
    font-size: 16px;
  }
  @include for-mobile-max_620 {
    width: 86%;
  }
  @include for-mobile-max_480 {
    width: 81%;
  }
}

.menuButtons {
  position: relative;
}

.inputGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;

  .chatSearchInput {
    background: #f9f9f9;
    border-radius: 12px;
    height: 40px;
    width: 100%;
    padding: 8px 20px;
  }

  .upAndDownIcons {
    display: flex;
    position: absolute;
    right: 8px;

    > button {
      width: 28px !important;
      height: 28px !important;
      padding: 10px !important;
      margin: 0 !important;
    }

    .upOrDownIcon {
      height: 8px;
      width: 13px;
      margin: 7px;
    }
  }
}

.bottomButton {
  height: 30px !important;
  width: 30px !important;
  border-radius: 8px !important;
  padding: 15px !important;
}

#call {
  display: none;
}

@media screen and (max-width: 420px) {
  #call {
    display: flex;
  }
}
