@import '../../../../styles/_mixins.scss';

.menuFooterWrapper {
  position: fixed;
  bottom: 0;
  display: flex;
  // flex-direction: column;
  gap: 3vw;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
  margin-bottom: 30px;

  @include for-mobile-max_767 {
    flex-direction: column;
    width: 100%;
  }

  @include for-mobile-max_480 {
    margin: 60px 20px 30px 20px;
  }

  @include for-mobile-s {
    margin: 20px 20px 10px 20px;
  }

  .menuFooterSecondWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5vw;
    width: 100%;

    @include for-desktop-up-max_1440 {
      gap: 4vw;
    }

    @include for-desktop-up-max_1200 {
      flex-direction: column;
      gap: 3vw;
      width: 100%;
    }
  }

  .menuFooterFirstLayer {
    display: flex;
    align-items: center;
    gap: 5vw;

    @include for-desktop-up-max_1440 {
      gap: 4vw;
    }

    @include for-desktop-up-max_1200 {
      flex-direction: column;
      gap: 3vw;
      width: 100%;
    }
  }

  .menuFooterHome {
    & > a {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @include for-desktop-up-max_1200 {
      display: none;
    }
  }

  .menuFooterHomeIcon {
    width: 28px;
    height: 33px;
    background-image: url(../../../../../public/images/templates/menu-house.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @include for-desktop-up-max_1440 {
      width: 25px;
      height: 21.25px;
    }
  }

  .menuFooterHomeTitle {
    display: block;
    margin-top: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    color: #ffffff;

    @include for-desktop-up-max_1440 {
      font-size: 14px;
      line-height: 18px;
    }

    @include for-desktop-up-max_1200 {
      margin-top: 0;
    }
  }

  .menuFooterHomeNav {
    display: none;

    @include for-desktop-up-max_1200 {
      display: block;
    }

    & > a {
      display: flex;
      align-items: center;
      gap: 13.5px;

      & > .menuFooterHomeIcon {
        @include for-mobile-max_767 {
          display: none;
        }
      }
    }
  }

  .menuFooterNavigation {
    display: flex;
    flex-direction: column;

    @include for-desktop-up-max_1200 {
      flex-direction: row;
      gap: 0;
      width: 100%;
      justify-content: space-between;
    }

    @include for-mobile-max_767 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    @include for-mobile-max_480 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .menuFooterNavigationItem {
    width: fit-content;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;

    margin-bottom: 10px;

    color: rgba(255, 255, 255, 0.6);
    transition: 0.25s;

    &:last-child {
      margin: 0;
    }

    &:hover,
    &:focus {
      color: #fff;
    }

    @include for-desktop-up-max_1440 {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .menuFooterButtons {
    display: flex;
    gap: 32px;

    @include for-desktop-up-max_1200 {
      gap: 0;
      width: 100%;
      justify-content: space-between;
    }

    @include for-mobile-max_767 {
      display: grid;
      gap: 4vw;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
    }

    @include for-mobile-max_480 {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .menuFooterButton {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;

    display: flex;

    width: 144px;
    height: 144px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);
    border-radius: 10px;

    justify-content: center;
    align-items: center;

    color: rgba(255, 255, 255, 0.6);
    transition: 0.25s;

    &:hover,
    &:focus {
      color: #fff;
    }

    @include for-desktop-up-max_1440 {
      width: 120px;
      height: 120px;
      font-size: 14px;
      line-height: 18px;
    }

    @include for-desktop-up-max_1200 {
      width: 155px;
      height: 50px;
    }

    @include for-mobile-max_767 {
      justify-self: center;
      width: 100%;
    }

    @include for-mobile-max_480 {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .menuFooterSecondLayer {
    display: flex;
    align-items: center;

    @include for-desktop-up-max_1200 {
      width: 100%;
    }

    @include for-mobile-max_767 {
      justify-content: center;
    }
  }

  .menuFooterSecondLayerFirstBlock {
    display: flex;
    // flex-direction: column;
    gap: 3vw;

    @include for-mobile-max_767 {
      flex-wrap: wrap;
      align-items: center;
      gap: 20px;
      width: 100%;
      justify-content: space-around;
    }

    @include for-mobile-max_480 {
      justify-content: center;
    }
  }

  .menuFooterDesignedBy {
    text-align: end;
    font-size: 16px;
    line-height: 30px;

    color: #fff;

    @include text-selection-enabled;

    @include for-desktop-up-max_1500 {
      max-width: 250px;
    }

    @include for-desktop-up-max_1440 {
      font-size: 14px;
      line-height: 18px;
      max-width: 210px;
    }
    @include for-desktop-up-max_1200 {
      max-width: 300px;
    }
    @include for-mobile-max_480 {
      text-align: center;
    }
  }

  .menuFooterDesignedByTitle {
    font-weight: 500;
    line-height: 30px;
  }

  .menuFooterCertification {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  .menuFooterCertificate {
    & > a {
      position: relative;
      display: block;
      width: 151px;
      height: 64px;

      & > img {
        width: 151px;
        height: 64px;
      }

      @include for-desktop-up-max_1500 {
        width: 100px;
        height: 44px;

        & > img {
          width: 100px;
          height: 44px;
        }
      }
    }
  }

  .menuFooterMulticoloredCircle {
    cursor: pointer;

    .menuFooterMulticoloredCircleIcon {
      width: 60px;
      height: 60px;
      background-image: url(../../../../../public/images/templates/menu-multicolored-circle.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      @include for-desktop-up-max_1500 {
        width: 40px;
        height: 40px;
      }
    }
  }

  .menuFooterSecondLayerSecondBlock {
    text-align: left;
  }

  .menuFooterSecondLayerSocialMedia {
    display: none;

    @include for-mobile-max_767 {
      flex-basis: 100%;
    }
  }

  .menuFooterSocialMedia {
    display: grid;
    width: 180px;
    margin-bottom: 10px;

    column-gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    @include for-desktop-up-max_1200 {
      width: 126px;
      column-gap: 10px;
    }

    @include for-mobile-max_767 {
      display: flex;
      gap: 10px;
      margin: 0 auto;
    }

    .menuFooterSocialMediaIcon {
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      svg > path {
        fill: #ffffff !important;
      }

      @include for-desktop-up-max_1200 {
        width: 24px;
        height: 24px;
      }
    }
  }

  .menuFooterCopyright {
    font-size: 16px;
    line-height: 30px;

    color: #fff;

    @include for-desktop-up-max_1200 {
      font-size: 14px;
      line-height: 14px;
      text-align: end;
    }

    @include for-mobile-max_767 {
      margin-top: 10px;
      text-align: center;
      font-size: 12px;
      line-height: 15px;
    }
  }
}
