.rowWrapper {
  cursor: pointer;
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(8px);
  border-radius: 12px;
  margin-bottom: 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 24px 18px 24px 18px;

  .menuStyle {
    height: 50px;
  }
  .summary {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    column-gap: 30px;
    row-gap: 16px;
    padding-left: 20px;
    flex-wrap: wrap;

    .vertical {
      display: flex;
      flex-direction: column;
    }
    .horizontal {
      display: flex;
      flex-direction: row;
    }
    .draftName {
      flex-wrap: nowrap;
      flex: 1 0 300px;
    }

    .info {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      column-gap: 30px;
      flex: 1 0 350px;
      @media screen and (max-width: 460px) {
        flex-direction: column;
        gap: 10px;
      }
    }
    .period{
      @media screen and (max-width: 460px) {
        flex-direction: row !important;
        gap: 10px !important;
      }
    }
    .dataBlock {
      column-gap: 5px;
      .container {
        display: flex;
        column-gap: 10px;
      }
      .gray {
        color: #b3b3b3;
      }
    }
    .dataName {
      min-width: max-content;
    }
    .dataValue {
      color: #757575;
      font-weight: 400;
    }
  }

  .buttonCont {
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: 4px;

    > div {
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      flex-grow: 0;
      flex-wrap: nowrap;
      align-items: center;
    }
  }
  &.shortFormat {
    column-gap: 8px;
    .summary {
      padding-left: 0;
      .draftName {
        flex: 1 0 260px;
      }

      .title {
        width: 100%;
      }
    }
  }
}
