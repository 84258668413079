@import '../../../../styles/_mixins.scss';

.formWrapper,
.form {
  width: 100%;
  padding: 0 10px;
}

.formWrapper {
  position: relative;
  z-index: 1;
  padding-bottom: 40px;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track-piece {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.stepWrapper {
  display: none;
  flex-direction: column;
}

.active {
  display: flex;
}

.stepNavWrapper {
  position: fixed;
  bottom: 2.7vw;
  right: 2.7vw;
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.addedZIndex {
  z-index: 2;
}
