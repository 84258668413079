@import '../../../styles/_mixins.scss';

.menuPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > h1 {
    font-size: 36px;
    font-weight: 800;
    line-height: 121%;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    margin: 0 0 0.67em 0;

    @include for-mobile-max_767 {
      font-size: 5.2vw;
    }

    @include for-mobile-max_480 {
      font-size: 20px;
      line-height: 30px;
    }

    @include for-mobile-s {
      line-height: 22px;
      margin-bottom: 10px;
      margin-top: 16px;
    }
  }

  .menuContainer {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    @include for-mobile-max_767 {
      justify-content: center;
    }

    .menuHelpContainer {
      width: 29.5vw;
      margin-left: 2.6vw;

      @include text-selection-enabled;

      @include for-mobile-max_767 {
        display: none;
      }

      .menuHelpSecondContainer {
        width: 100%;
        min-height: 11.77vw;
        padding: 1vw;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 10px;
        backdrop-filter: blur(15px);

        @include for-mobile-max_767 {
          display: none;
        }

        &::after {
          right: -5vw;
          background-image: url(../../../../public/images/templates/left-arrow.svg);
          content: '';
          position: absolute;
          top: calc(50% - 0.415vw);
          width: 3.125vw;
          height: 0.8333vw;
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center center;
        }
      }

      .menuHelpTitle {
        font-size: 1.867vw;
        color: rgba(255, 255, 255, 1);
        font-weight: 800;
        line-height: 122%;
      }

      .menuHelpText {
        margin-top: 0.52vw;
        font-weight: 500;
        font-size: 0.933vw;
        line-height: 122%;
        color: rgba(255, 255, 255, 1);
      }
    }

    .menuMainContainer {
      width: 30vw;
      margin-left: 2.9vw;
      position: relative;
      overflow: hidden;
      height: 100%;
      max-height: 20vw;

      @include for-mobile-max_767 {
        width: 50vw;
        margin: 0 auto;
        max-height: 54vw;
      }

      @include for-mobile-max_480 {
        width: 100vw;
        max-height: none;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0px;
        width: 1.6vw;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;

        @include for-mobile-max_767 {
          width: 4.32vw;
        }

        @include for-mobile-max_480 {
          display: none;
        }
      }

      &::before {
        left: 3vw;
        background-image: url(../../../../public/images/templates/menu-roll-left.png);
      }

      &::after {
        right: 3vw;
        background-image: url(../../../../public/images/templates/menu-roll-right.png);
      }
    }

    .subMenuContainer {
      position: relative;
      width: 25.8vw;
      max-height: 20vw;

      .subMenuSecondContainer {
        transition: width 0.1s;
        &::before {
          left: -2vw;
          background-image: url(../../../../public/images/templates/right-arrow.svg);
          content: '';
          position: absolute;
          top: calc(50% - 0.415vw);
          width: 3.125vw;
          height: 0.8333vw;
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center center;

          @include for-mobile-max_767 {
            left: 0vw;
            top: calc(50% - 0.9vw);
            width: 7vw;
            height: 1.8vw;
          }
        }
      }

      .subMenuItem {
        position: relative;
        text-align: center;
        font-size: 1.25vw;
        line-height: 3.125vw;
        font-weight: 500;
        white-space: nowrap;
        transition:
          margin 0.2s,
          font-size 0.2s,
          font-weight 0.2s;
        text-decoration: none;
        color: #fff;

        @include for-mobile-max_767 {
          font-size: 2.34375vw;
          line-height: 8vw;
        }

        .helpIconWrapper {
          display: none;

          @include for-mobile-max_767 {
            position: absolute;
            top: calc(50% - 1.9vw);
            right: 5vw;
            display: block;
            width: 3.8125vw;
            height: 3.8125vw;
            z-index: 5;

            background-size: 80%;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url(../../../../public/images/templates/help-icon.svg);
            cursor: pointer;
          }
        }
      }

      @include for-mobile-max_767 {
        width: 0vw;
        transition: width 0.1s;
        max-height: none;
      }

      @include for-mobile-max_480 {
        display: none;
      }

      &.active {
        @include for-mobile-max_767 {
          width: 50vw;
        }
      }
    }
  }
}

.sumMenuContain {
  @include for-mobile-max_480 {
    overflow: hidden;
  }
  & > .menuContainer {
    @include for-mobile-max_480 {
      display: flex;
      width: 200vw;
      transform: translate(50vw);
    }
    & > .menuMainContainer {
      @include for-mobile-max_480 {
        width: 100vw;
      }
    }

    & > .subMenuContainer {
      @include for-mobile-max_480 {
        display: block;
        width: 100vw !important;
      }

      & > .subMenuSecondContainer {
        @include for-mobile-max_480 {
          position: relative;
        }
        &::before {
          @include for-mobile-max_480 {
            display: none;
          }
        }

        & > .subMenuItem {
          @include for-mobile-max_480 {
            font-size: 3vw;
            line-height: 7vw;
          }

          & > .helpIconWrapper {
            @include for-mobile-max_480 {
              right: 16vw;
            }
          }
        }
      }

      & > .backArrow {
        display: none;
        @include for-mobile-max_480 {
          display: flex;
          width: 24px;
          height: 24px;
          position: absolute;
          top: calc(50% - 12px);
          left: 10vw;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 80%;
          background-image: url(../.../../../../../public/images/templates/arrow-circle-icon-white.svg);
          transform: rotate(180deg);
          z-index: 2;
        }
      }
    }
  }
}

.sumMenuActive {
  & > .menuContainer {
    transform: translate(-50vw) !important;
  }
}
