@import '../../../src/styles/mixins';

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  gap: 20px;

  @include for-mobile-max_767 {
    gap: 12px;
  }

  .infoBlock {
    padding: 20px 60px;
    border-radius: 12px;
    background-color: #ffffff;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;

    @media screen and (max-width: 1503px) {
      flex-wrap: wrap;
      gap: 12px;
    }
    @include for-tablet-landscape-max_1024 {
      padding: 20px 40px;
      flex-wrap: nowrap;
    }
    @media screen and (max-width: 950px) {
      flex-wrap: wrap;
      gap: 12px;
    }
    @include for-mobile-max_620 {
      padding: 20px;
    }

    .menuDotsIcon {
      position: absolute;
      padding: 0 !important;
      border-radius: 50% !important;
      right: 60px;
      top: 26px;

      @include for-tablet-landscape-max_1024 {
        right: 20px;
      }

      svg path {
        color: #212121;
      }
    }

    .mainInfo {
      display: flex;
      align-items: center;
      gap: 20px;
      @include for-mobile-max_620 {
        gap: 8px;
      }

      .groupInfo {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .groupName {
          font-family: 'Montserrat';
          white-space: normal;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 36px;
          flex-wrap: wrap;
          @media screen and (max-width: 1503px) {
            padding-right: 31px;
          }
          @include for-mobile-max_620 {
            font-size: 16px;
          }

          img {
            vertical-align: middle;
          }
        }
        .type {
          font-family: 'Montserrat';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          color: #18397a;
          @include for-mobile-max_620 {
            font-size: 12px;
          }
        }
        .countMembers {
          font-family: 'Montserrat';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: #555555;

          @include for-mobile-max_620 {
            font-size: 12px;
          }
        }
      }
    }
    .buttons {
      display: flex;
      gap: 20px;
      align-items: center;
      @media screen and (max-width: 1503px) {
        gap: 12px;
      }
      @include for-mobile-max_620 {
        font-size: 12px;
      }
      .inviteBtn {
        display: flex;
        gap: 12px;
        .inviteText {
          font-family: 'Montserrat';
          font-size: 16px;
          font-weight: 600;
          white-space: nowrap;

          .inviteTextMobile {
            @media screen and (max-width: 1600px) {
              display: none;
            }
            @media screen and (max-width: 1503px) {
              display: inline-block;
            }

            @include for-mobile-max_620 {
              display: none;
            }
          }
        }
      }
    }
  }

  .reportBtn {
    color: red;
  }
}
