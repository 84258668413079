@import '/src/styles/mixins';

.contentBox {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 24px 60px;
  border-radius: 12px;
  margin-bottom: 20px;

  @include for-mobile-max_767 {
    padding: 20px;
  }
}

.itemWrapper {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.textPreview {
  margin-bottom: 12px;
}

.text {
  font-weight: 400;
  width: 100%;
  word-break: break-word;
}

.noInformation {
  font-weight: 400;
  color: #757575;
}

.textArea {
  width: 980px;
  min-width: 80%;
  height: 60px;

  @include for-tablet-landscape-max_1024 {
    width: 95%;
  }
}
.inputLabel {
  text-decoration: none;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.selectLevel {
  width: 18vw;
  height: 46px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: start;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);

  @include for-mobile-max_767 {
    flex-direction: row;
    width: 95%;
    border: none;
    margin-top: 10px;
  }
}
.input {
  height: 48px;
  width: 18vw;
  left: 396px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  text-align: start;
  padding-left: 10px;
  padding-top: 7px;

  @include for-mobile-max_767 {
    width: 90%;
  }
}

.language {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;

  @include for-mobile-max_767 {
    flex-direction: column;
    width: 100%;
  }
}

.content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.editButton {
  background-color: white;
  border: none;
  padding-left: 10px;
}

.editButtonT {
  background-color: white;
  border: none;
  padding-left: 10px;

  @include for-mobile-max_767 {
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
  }
}

.interestsList {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: max-content;
  gap: 12px;
  overflow-x: auto;
}

.interest {
  display: flex;
  flex-direction: row;
  margin: 5px 5px 5px 30px;
  padding-left: 10px;
  padding-right: 10px;
  height: max-content;
  text-align: center;
  width: max-content;
  background: #efefef;
  border: 1px solid #efefef;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #212121;
}
