.rowWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(8px);
  border-radius: 12px;
  margin-bottom: 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 24px 18px 24px 18px;

  .summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-left: 40px;
    width: 100%;
    .draftData {
      width: 100%;
    }

    .info {
      display: flex;
      flex-direction: row;
      column-gap: 30px;
    }

    .dataBlock {
      min-width: fit-content;

      .container {
        display: flex;
        column-gap: 10px;

        .data {
          text-align: right;
          margin-bottom: 5px;
        }
        .dataSecond {
          text-align: right;
          margin-bottom: 5px;
        }
        .dataValue {
          color: #757575;
          font-weight: 400;
        }
      }
    }
  }

  .buttonCont {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 7px;

    > div {
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      flex-grow: 0;
      flex-wrap: nowrap;
    }
  }
}

.shortFormat {
  .summary {
    flex-wrap: wrap;

    .title {
      width: 100%;
    }
  }

  .buttonCont {
    flex-direction: row;
    justify-content: end;
    flex-wrap: wrap;

    .more {
      margin-right: 25px;
    }
  }
}
