@import '../../../../../styles/_mixins.scss';

$red: #cd0606;

.secondWrapper {
  display: flex;
  justify-content: space-between;
  padding: 8px 30px;
  border-radius: 10px;

  @include for-mobile-max_767 {
    padding: 12px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  & > button {
    border: 2px solid #ffffff;

    &:hover {
      border: 2px solid rgba(255, 255, 255, 0.8);
    }

    @include for-desktop-up-max_1200 {
      padding: 15px 20px;
    }

    @include for-mobile-max_767 {
      padding: 15px 76px;
    }

    @include for-mobile-max_480 {
      padding: 15px 0;
      width: 100%;
      max-width: 100%;
    }
  }
}

.input {
  width: 100%;
  background: transparent;
  border-width: 0px;
  outline: none;
  font-size: 14px;
  line-height: 17px;
  padding: 15.5px 30px;
  color: #212121 !important;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;

  @include for-mobile-max_767 {
    width: 100%;
    padding: 15.5px 30px 15.5px 32px;
  }

  @include for-mobile-max_480 {
    max-width: 100%;
  }
}

.digital {
  & > input {
    width: 60%;
    @include for-mobile-max_767 {
      width: 100%;
    }
  }

  @include for-mobile-max_767 {
    padding: 12px 30px;
  }

  @include for-mobile-max_480 {
    padding: 12px 30px;
  }
}

.errorInputWrapper {
  padding: 4px;

  & > .secondWrapper {
    border: 2px solid $red;
    padding: 2px 24px;

    & > .space {
      border-right: 2px solid $red;

      @include for-mobile-max_767 {
        border-bottom: 2px solid $red;
      }
    }

    & > .descriptionWrapper {
      color: $red;
    }
  }
}

.inputToken {
  display: none;
}

.thirdWrapper {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include for-mobile-max_767 {
    width: 100%;
    align-items: center;
  }
}

.errorMessage {
  margin-top: 4px;

  font-size: 12px;
  color: $red;
}

.errorPlaceholder {
  &::-webkit-input-placeholder {
    color: $red;
  }

  &::-moz-placeholder {
    color: $red;
  }

  &:-ms-input-placeholder {
    color: $red;
  }

  &::-ms-input-placeholder {
    color: $red;
  }

  &::placeholder {
    color: $red;
  }
}

.space {
  height: auto;
  padding-right: 5%;
  border-right: 2px solid #212121;

  @include for-mobile-max_767 {
    width: 100%;
    padding-right: 0;
    border-right: none;
    border-bottom: 2px solid #212121;
  }
}

.descriptionWrapper {
  display: flex;
  align-items: center;
  width: 35%;
  margin-left: 20px;
  line-height: 20px;

  font-size: 14px;
  font-weight: 400;

  @include for-mobile-max_767 {
    align-items: flex-start;
    width: 100%;
    margin-left: 0;
  }
}
