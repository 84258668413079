@import '/src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000000;
  width: 100%;
  border-radius: 12px;
  background-position-x: center;
  margin-bottom: 16px;
}

.box {
  padding: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
}

.text {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: white;

  @include for-mobile-max_767 {
    width: 80% !important;
    left: 0 !important;
    flex-wrap: wrap !important;
    font-size: 14px !important;
  }
}

.confirmCheckbox {
  display: flex;
  //flex-direction: row;
  align-items: center;
  //justify-items: space-between;
}

.actions {
  display: flex;
  align-items: center;
  column-gap: 30px;

  @media screen and (max-width: 500px) {
    justify-content: space-between;
  }
}

.buttonSkip {
  text-align: left !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-transform: uppercase !important;
  color: #ffffff !important;
}

.buttonProceed {
  padding: 0 20px !important;
  text-align: left !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 48px !important;
}
