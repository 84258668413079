.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 40px;
  background-color: #ffffff;
  border-radius: 12px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  //margin: 20px 0;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;

  h1 {
    font-size: 24px;
    font-weight: 600;
    margin: 0 !important;
  }
}

.mainInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  margin-top: 60px;
  margin-bottom: 40px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 16px;
    font-weight: 500;
  }
}

.usersInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 100px;
}

.usersInfoCol {
  display: flex;
  flex-direction: column;
  gap: 8px;

  > h2 {
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
  }

  > p {
    margin: 0;
    color: #757575;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}

.tableWrapper {
  margin-top: 60px;
}

.total {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    font-size: 16px;
    font-weight: 600;
  }
}
