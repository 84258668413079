.contactModal {
  width: calc(100% - 28px); // current padding
  max-height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
  bottom: 57px;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 20px;
  gap: 20px;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: rgba(239, 239, 239, 0.6);
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 4px;
  }

  .modalTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
      margin: 0;
    }

    button {
      border: none;
      background: none;
      padding: 3px 4px;
      cursor: pointer;
    }
  }

  form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;

    input {
      &:disabled {
        background: #efefef;
      }
    }

    input,
    textArea {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
      box-sizing: border-box;
      width: 100%;
      background-color: #ffffff;
      border: 1px solid #efefef;
      border-radius: 12px;
      padding: 12px 20px;

      &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #757575;
      }

      &:focus {
        outline: none;
      }
    }

    textArea {
      resize: vertical;
      max-height: 77px;
      &::-webkit-resizer {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMCA2TDEgN0w3IDFMNiAwTDAgNloiIGZpbGw9IiM3NTc1NzUiLz4KPHBhdGggZD0iTTMgN0w0IDhMOCA0TDcgM0wzIDdaIiBmaWxsPSIjNzU3NTc1Ii8+Cjwvc3ZnPgo=');
        background-repeat: no-repeat;
      }
    }

    .sendFile {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 8px;

      button {
        cursor: pointer;
        padding: 0px 20px;
        gap: 12px;
        border: none;
        background: none;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 40px;
          text-transform: uppercase;
          color: #212121;
        }
      }
    }

    .submitForm {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;

      button {
        cursor: pointer;
        border-radius: 4px;
        text-transform: uppercase;
        background: none;
        padding: 11px 18px;
        border-radius: 4px;
      }
    }

    .filesList {
      max-height: 200px;
      overflow-y: auto;
      width: 100%;
      padding: 5px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      &::-webkit-scrollbar {
        width: 4px;
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background: rgba(239, 239, 239, 0.6);
      }

      &::-webkit-scrollbar-thumb {
        background: #c4c4c4;
        border-radius: 4px;
      }

      .fileItem {
        display: flex;
        justify-content: space-between;
        column-gap: 63px;
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        color: #555555;
        text-align: start;
        margin: 0;

        .fileName {
          max-width: 25%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          font-weight: 500;
          color: #212121;
        }
        .fileSize,
        .fileType {
          max-width: 25%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          margin: 0;
          font-style: normal;
          font-weight: 400;
          color: #212121;
        }

        button {
          cursor: pointer;
          border: none;
          background: none;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 467px) {
  .contactModal {
    .modalTitle {
      h2 {
        font-size: 14px;
      }
    }

    form {
      input,
      textArea {
        &::placeholder {
          font-size: 14px;
        }
      }

      .filesList {
        .fileItem {
          column-gap: 17px;
          font-size: 14px;

          h2 {
            font-size: 14px;
          }
        }
      }
    }
  }
}
