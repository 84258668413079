@import '/src/styles/mixins';

.tabContent {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .contentBox,
  .contentBoxWallet {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;

    .emptyContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
    }

    @include for-mobile-max_620 {
      padding: 20px;
    }
    border-radius: 12px;

    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
    }
    .subtitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      margin: 8px 0 16px 0;
      color: #757575;
    }
    .requestsList {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
      flex-wrap: wrap;
      align-items: stretch;

      @include for-desktop-up-max_1200 {
        flex-direction: column;
      }

      .user {
        padding: 12px 24px 12px 24px;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 12px;
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.08);
        width: calc(50% - 10px);
        display: flex;
        align-items: center;
        gap: 12px;
        @include for-desktop-up-max_1200 {
          width: 100%;
        }
        @include for-mobile-max_620 {
          flex-wrap: wrap;
        }

        .userInfo {
          @include for-desktop-up-max_1200 {
            width: 100%;
          }
          display: flex;
          flex-direction: column;

          .userName {
            font-size: 16px;
            font-weight: 600;
            line-height: 19.5px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            overflow-wrap: anywhere;
            word-break: break-word;
          }

          .text {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #757575;
          }
        }

        .actions {
          display: flex;
          align-items: center;
          flex-direction: column;
          @include for-mobile-max_620 {
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
          }
        }
      }
    }

    .viewAllBlock {
      display: flex;
      margin-top: 16px;
      align-items: center;
      justify-content: flex-end;

      .viewAllButton {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        text-decoration: none !important;

        .text {
          color: #18397a;
          text-decoration: underline;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }

        .downIcon {
          height: 20px;
          width: 20px;

          svg path {
            fill: #18397a;
          }
        }
      }
    }
  }
  .contentBoxWallet {
    padding: 20px 60px !important;
    @include for-tablet-landscape-max_1024 {
      padding: 20px 40px !important;
    }
    @include for-mobile-max_620 {
      padding: 20px !important;
    }
    display: flex;
    flex-direction: column;
    gap: 16px;
    .search {
      display: flex;
      gap: 24px;
      width: 100%;
      align-items: center;
      @include for-mobile-max_620 {
        flex-direction: column;
      }
      .searchInput {
        width: 100%;
      }
    }
    .titleAndSwitch {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .switchBlock {
        display: flex;
        align-items: center;
        gap: 8px;

        .text {
          font-size: 16px;
          @include for-mobile-max_620 {
            font-size: 14px !important;
          }
          font-weight: 400;
          line-height: 24px;
          color: #212121;

          .desktopText {
            @include for-mobile-max_620 {
              display: none;
            }
          }
        }
      }
    }

    .walletItem {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 20px;
      row-gap: 10px;
      width: calc(100% - 30px);
      flex-wrap: wrap;

      .walletAddress {
        display: flex;
        align-items: center;
        gap: 4px;

        .address {
          word-break: break-word;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    .cardsBlockHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 12px;
      .title {
        display: flex;
        align-items: center;
        gap: 4px;

        .text {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }

    .cardsList {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .cardItem {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
        width: calc(100% - 30px);
        row-gap: 10px;

        @include for-mobile-max_620 {
          flex-direction: column;
          align-items: flex-start;
        }

        .cardNumber {
          display: flex;
          align-items: center;
          gap: 6px;

          .number {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }

          .defaultCard {
            display: flex;
            align-items: center;
            gap: 4px;
            svg path {
              fill: #757575;
            }

            .text {
              font-size: 12px;
              font-weight: 400;
              color: rgba(117, 117, 117, 1);
            }
          }
        }
      }
    }
  }
}

.optionsIcon {
  position: absolute !important;
  right: -40px !important;
  top: 0 !important;
  cursor: pointer;

  @include for-tablet-landscape-max_1024 {
    right: -30px !important;
  }
}
.menuItemWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  width: -webkit-fill-available;
}

.addWalletBtn,
.addWalletBtnWhite {
  display: flex;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
  width: -webkit-fill-available;
  svg > path {
    fill: #ffffff;
  }
  @include for-mobile-max_620 {
    max-width: 100% !important;
  }
}

.addWalletBtnWhite {
  svg > path {
    fill: rgba(24, 57, 122, 1);
  }
}

.dateBlock {
  display: flex;
  align-items: center;
  gap: 4px;

  @include for-mobile-max_620 {
    width: 100%;
    justify-content: space-between;
  }

  .connectDate {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #757575;
  }
}
