@import '/src/styles/mixins';

.pageContainer {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  min-height: 60vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.chatList {
  width: 100%;
  height: 100%;
  max-width: 375px;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border-radius: 12px;
}

.iconDeleted {
  width: 40px;
  height: 40px;
  background: #c4c4c4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatListOptions {
  width: 20% !important;
  min-width: 20% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  //box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  background-color: #f9f9f9;
  border-radius: 12px 0 0 12px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #919191 #f1f1f1;
}

.chatListUsers {
  width: 80%;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include for-desktop-up-max_1500 {
    padding: 10px 15px;
  }
  @include for-desktop-up-max_1200 {
    height: 100%;
  }
}

.chatListUsersContent {
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #919191 #f1f1f1;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #919191;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #6a6a6a;
  }
  @include for-desktop-up-max_1200 {
    padding-right: 6px;
  }
}

.userChat {
  display: flex;
  cursor: pointer;
  margin: 8px 0;
}

.chatInfo {
  width: 75%;
  position: relative;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.userAvatarContent {
  margin-right: 10px;
}

.userAvatar {
  width: 48px;
  height: 48px;
}

.userChatName {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  position: relative;
  width: 86%;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 5px;

  @include for-desktop-up-max_1500 {
    font-size: 12px;
  }

  @include for-tablet-landscape-max_1024 {
    font-size: 16px;
  }
}

.userChatMessege {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #555555;
  position: relative;
  width: 70%;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 5px;

  @include for-desktop-up-max_1500 {
    font-size: 10px;
  }

  @include for-tablet-landscape-max_1024 {
    font-size: 12px;
  }
}

.deleteUserChatMessege {
  color: #d32f2f;
}

.userChatMessegeAndDate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 15px;
}

.forMobileMenu {
  @include for-tablet-landscape-min_1024 {
    display: none;
  }

  @include for-tablet-landscape-max_1024 {
    position: absolute;
    z-index: 9999;
    width: 50%;
    padding-right: 40px;
    padding-left: 5px;
    padding-top: 30px;
  }
}

.buttonOptions {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.openedChat {
  width: 638px;
  height: 705px;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
  margin-left: 20px;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-desktop-up-max_1660 {
    display: none;
  }
}

.rightColumn {
  display: flex;
  flex-direction: column;

  @include for-desktop-up-max_1660 {
    display: none;
  }
}

.invite {
  margin-bottom: 4%;
}

.folderButton {
  min-height: 64px;
  padding: 12px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  border: none;
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
}

.date {
  position: absolute;
  top: 5px;
  right: 0;
  color: #757575;
  font-size: 12px !important;
}

// Mobile styles

.mobileContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabsContainer {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-bottom: 15px;
}

.tabButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.tabButtonInside {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.chatsLoading {
  display: flex;
  justify-content: center;
  margin-top: 24px !important;
}

.mobileTabTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #18397a;
}

.mobileChatSetting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mobileSettingTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.tabSettings {
  margin-top: 12px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.tabs {
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-top: 4px;

  .tabItem {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #212121;
    padding: 0;
    min-width: unset !important;
    margin: 0 20px !important;

    @media screen and (max-width: 1340px) {
      margin: 0 10px;
    }
  }

  .activeItem {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: #143471 !important;

    @include for-desktop-up-max_1500 {
      margin: 5px 10px;
    }
  }
}

.mobileChatsContainer {
  width: 100%;
  background-color: #ffffff;
  overflow-x: hidden;
  height: 65vh;
  margin-bottom: 120px;
  border-radius: 12px;

  > div {
    width: 100% !important;
    margin: 0 !important;
  }

  @media screen and (max-width: 1024px) {
    height: 60vh;
  }

  @media screen and (min-height: 810px) {
    height: 62vh;
  }

  @media screen and (min-height: 850px) {
    height: 64vh;
  }

  @media screen and (min-height: 900px) {
    height: 66vh;
  }
}
