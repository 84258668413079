@import '../../../../../../src/styles/mixins';

.connectedUsers {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
  .connectItem {
    width: 100%;
    padding: 12px 24px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .infoBlock {
      display: flex;
      gap: 12px;

      .userInfo {
        display: flex;
        flex-direction: column;
        min-width: 143px;
        max-width: 143px;
        .userName {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: 'Montserrat';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .invitedBy {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: 'Montserrat';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
        .experiences {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          font-family: 'Montserrat';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;

          .experienceName {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;

            .greenText {
              color: #4caf50;
              margin-left: 3px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    .connectionActions {
      display: flex;
      align-items: center;
      button {
        border-radius: 50% !important;
      }
      .itemIcon {
        width: 24px;
        height: 24px;

        svg > path {
          fill: #18397a !important;
        }
      }
    }
  }
}
