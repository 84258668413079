@import '../../../styles/_mixins.scss';

.sectionsWrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 1200px) {
    position: absolute;
    top: 10px;
    left: 0;
  }
}

.headerWrapper {
  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
  }
}

.section {
  font-family: 'Montserrat', sans-serif;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.mainSection {
  @media (max-width: 768px) {
    margin-top: 90px !important;
  }
}

.leftBlock {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 600px;
  height: 100vh;
  align-items: center;
  background: rgba(45, 45, 45, 0.55);

  @media (max-width: 1500px) {
    width: 450px;
  }

  @media (max-width: 1200px) {
    position: relative;
    overflow: hidden;
    width: calc(100% + 100px);
    height: unset;
    justify-content: center;
    margin-left: -80px;
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    //margin-top: 80px;
  }
}

.rightBlock {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 600px);
  height: 100vh;

  @media (max-width: 1500px) {
    width: calc(100% - 450px);
  }

  @media (max-width: 1200px) {
    position: relative;
    width: 700px;
    height: 100%;
    margin-top: 30px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}
