.leftSection {
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin-left: 80px;
  }
}

.mainSection_1 {
  position: relative;
  color: #ffffff99;
  font-weight: 800;
  font-size: 220px;
  margin-top: -100px;
  margin-left: 180px;
  margin-bottom: -5px;

  @media (max-width: 1500px) {
    font-size: 200px;
    margin-left: 70px;
  }

  @media (max-width: 1200px) {
    font-size: 140px;
    margin-top: 60px;
    margin-left: -40px;
    font-weight: 800;
  }

  @media (max-width: 768px) {
    font-size: 60px;
    margin-top: 40px;
    margin-left: -20px;
  }
}

.mainSection_2 {
  position: relative;
  color: #ffffff66;
  font-weight: 700;
  font-size: 200px;
  margin-top: -200px;
  margin-left: 600px;
  width: 600px;
  margin-bottom: -10px;

  @media (max-width: 1500px) {
    font-size: 180px;
    margin-left: 455px;
    margin-top: -180px;
  }

  @media (max-width: 1200px) {
    color: #ffffff99;
    font-size: 140px;
    margin-top: -133px;
    margin-left: 224px;
    width: 150px;
    text-align: right;
    line-height: 115px;
    font-weight: 800;
  }

  @media (max-width: 768px) {
    font-size: 60px;
    width: 150px;
    margin-top: -86px;
    margin-left: 78px;
  }
}

.mainSection_3 {
  position: relative;
  color: #ffffff66;
  font-weight: 600;
  font-size: 30px;
  margin-left: 610px;
  margin-top: -210px;

  @media (max-width: 1500px) {
    font-size: 25px;
    margin-left: 470px;
    margin-top: -200px;
  }

  @media (max-width: 1200px) {
    width: 150px;
    word-break: break-word;
    margin-top: -75px;
    margin-left: 70px;
    margin-bottom: 95px;
    text-align: right;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    margin-left: 16px;
    margin-top: -20px;
    margin-bottom: 40px;
  }
}

.stepSection_1 {
  position: relative;
  color: #ffffff99;
  font-weight: 700;
  font-size: 150px;
  margin-top: 0;
  margin-left: 120px;
  margin-bottom: -5px;

  @media (max-width: 1500px) {
    font-size: 120px;
    margin-left: 70px;
  }

  @media (max-width: 1200px) {
    margin-top: 20px;
    font-size: 70px;
    font-weight: 800;
    margin-left: 0;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    margin-top: 10px;
  }
}

.stepSection_2 {
  position: relative;
  color: #ffffff99;
  font-weight: 700;
  font-size: 150px;
  margin-top: -35px;
  margin-left: 430px;

  @media (max-width: 1500px) {
    font-size: 120px;
    margin-left: 320px;
  }

  @media (max-width: 1200px) {
    font-size: 70px;
    margin-top: -75px;
    margin-left: 230px;
    font-weight: 800;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    margin-top: -29px;
    margin-left: 97px;
  }
}

.stepSection_3 {
  position: relative;
  color: #ffffff99;
  font-weight: 600;
  font-size: 50px;
  margin-left: 100px;
  margin-top: -140px;
  line-height: 40px;

  @media (max-width: 1500px) {
    font-size: 40px;
    margin-top: -120px;
    margin-left: 80px;
    line-height: 35px;
  }

  @media (max-width: 1200px) {
    font-size: 20px;
    margin-top: -65px;
    margin-left: 300px;
    line-height: 20px;
    margin-bottom: 40px;
    font-weight: 700;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    margin-left: 128px;
    margin-top: -26px;
    margin-bottom: 15px;
    line-height: 10.5px;
  }
}

.stepContent {
  color: #ffffff;
  font-size: 25px;
  font-weight: 500;
  line-height: 37.5px;
  width: 100%;
  max-width: 580px;
  margin-left: 60px;

  @media (max-width: 1500px) {
    max-width: 450px;
    font-size: 20px;
  }

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    margin-left: 25px;
    margin-right: 25px;
    width: unset;
    font-size: 12px;
    line-height: 20px;
  }
}
