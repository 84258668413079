@import '../../../styles/_mixins.scss';

.formResetText {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 10px;
}

.formResetBox {
  position: relative;
  margin-bottom: 40px;

  & > div {
    margin-bottom: 0px;
  }
}

.error.errorReset.active {
  bottom: 24px;
}

.resetSuccess {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 12px;
}

.successText {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.successIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.successIcon > span {
  width: 24px !important;
}

.textInput {
  width: 100%;
}

.textInput input {
  color: #fff;
  outline-color: #fff;
  padding: 8px 0;
  width: 100%;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
