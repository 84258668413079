.tbsTooltip {
  display: flex !important;
  align-items: center !important;
  visibility: visible !important;

  padding: 12px 20px !important;

  transition: 0.3s ease-in-out;

  color: #333333 !important;

  align-items: center;

  background: rgba(255, 255, 255, 0.45) !important;
  backdrop-filter: blur(15px) !important;
  border-radius: 10px !important;
  z-index: 1000;

  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Montserrat';
  line-height: 18px;

  & > span {
    margin-right: 12px !important;
  }
}

.icon {
  width: 26px;
  height: 26px;

  svg > path {
    fill: #333333 !important;
  }
}

.hidden {
  opacity: 0;
}
