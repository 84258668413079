.pageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.tabContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  padding: 24px 60px;
  gap: 20px;

  @media (max-width: 768px) {
    padding: 24px;
  }
}

.filter {
  justify-content: space-between;
  gap: 20px;

  > div {
    width: 100%;
  }
}

.templateItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0px 4px 24px 0px #0000000a;
  background: #ffffff;

  > b {
    font-weight: 500;
  }
}
