@import '../../../../styles/_mixins.scss';

.headerNavItem {
  display: flex;
  align-items: center;
  margin-right: 23px;
  font-size: 0.97vw;
  font-weight: 400;
  transition: 0.25s;
  cursor: pointer;
  color: #fff;
  white-space: nowrap;

  & > a {
    display: flex;
    align-items: center;
  }

  &:last-child {
    margin-right: 0 !important;
  }

  &:hover img {
    transform: scale(1.2);
  }

  &:hover > a > span:first-child {
    transform: scale(1.2);
  }

  > a > span:first-child {
    margin-right: 12px;
    transition: 0.2s;
  }

  @include for-mobile-max_480 {
    margin-right: 0;
  }
}

.headerNavImage {
  margin-right: 12px;
  transition: 0.2s;
}

.headerNavText {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  transition: 0.25s;
  cursor: pointer;
  color: #fff;
  white-space: nowrap;
  transition: 0.2s;
  text-transform: uppercase;

  @include for-mobile-max_767 {
    display: none;
  }
  @include for-mobile-max_480 {
    display: none;
  }
}
