@import '/src/styles/mixins';

.content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #e5e5e5;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

.pageContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.8% 7%;

  @include for-mobile-max_767 {
    padding-left: 3% !important;
    padding-right: 3% !important;
  }

  @include for-mobile-max_767 {
    flex-wrap: wrap;
  }
}
.forMobileMenu {
  @include for-tablet-landscape-min_1024 {
    display: none;
  }

  @include for-tablet-landscape-max_1024 {
    display: block;
    /* position: absolute;
    z-index: 9999;
    width: 50%;
    padding-right: 40px;
    padding-left: 5px;
    padding-top: 30px; */
  }
}

.contentBox {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 90vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.contentBox::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.middleContent {
  width: 1020px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1.2% !important;
  margin-right: 1.2% !important;

  @include for-desktop-up-max_1200 {
    width: 100vw;
  }
  /* @include for-desktop-up-max_1660 {
    display: block;
    max-width: 50vw;
  } */
}
.leftColumn {
  display: block;
  flex-direction: column;
  align-items: center;

  @include for-desktop-up-max_1200 {
    display: none;
  }
}

.rightColumn {
  display: flex;
  flex-direction: column;

  @include for-desktop-up-max_1200 {
    display: none;
  }
}

.invite {
  margin-bottom: 4%;
}

.buttonGroup {
  width: 100%;
}

.button {
  padding: 6px 36px !important;
  background: #ffffff !important;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04) !important;
  border-radius: 12px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #212121 !important;
  margin-right: 20px !important;
}

.activeButton {
  background: #bad4e7 !important;
  color: #18397a !important;
}

.notificationContent {
  width: 100%;
  max-height: 700px;
  margin-top: 24px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  background: #ffffff;
  overflow-y: scroll;
}
.emptyList {
  width: 100%;
  margin-top: 20px;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  text-align: center;

  padding: 32px 20px;
  border-radius: 12px;
  background-color: #ffffff;
  text-align: center;
}
.notificationContent::-webkit-scrollbar {
  display: none;
}

.notificationItem {
  position: relative;
  padding: 32px 60px 32px 60px;
  display: flex;
  align-items: center;
  gap: 20px;
  background: #ffffff;
  border-bottom: 1px solid #efefef;

  @include for-tablet-landscape-max_900 {
    padding: 24px;
  }

  .readBlueDot {
    width: 5px;
    height: 5px;
    background-color: blue;
    border-radius: 50%;
    position: absolute;
    top: 47%;
    left: 38px;
  }

  .notificationInfo {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .notificationTitle {
      font-weight: 500;
      white-space: nowrap;
    }
  }
  &:hover {
    background: rgba(224, 237, 250, 1);
    cursor: pointer;
  }
}

.notificationDescription {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  //margin-right: 54px;
  //margin-left: 20px;
  width: 100%;
  p {
    a {
      text-decoration: underline;
      margin: 0 5px;
      color: #18397a;
    }
  }
}

.dateArea {
  white-space: nowrap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  margin-bottom: 8px;
}

.dataBlock {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
