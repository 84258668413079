.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 12px;

  .logo {
    width: 40px;
    height: 20px;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
}
.title {
  font-family: 'Montserrat';
  color: #212121;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0 !important;
}
.wrap {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(8px);
  border-radius: 12px;
  margin-bottom: 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 18px 14px 14px 15px;
  flex-direction: row;

  @media screen and (max-width: 540px) {
    flex-direction: column;
  }
}
