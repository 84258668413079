@import '../../../styles/_mixins.scss';

.homePage {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.mainWrapper {
  height: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.startBl {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    margin: 0;
  }
}

.startHeader {
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  font-size: 4vw;
  margin-bottom: 12px;

  @include text-selection-enabled;
}

.startSubHeader {
  font-size: 2vw;
  font-weight: 600;
  line-height: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.startHeader {
  @include for-mobile-max_767 {
    font-size: 8vw;
  }

  @include for-mobile-max_480 {
    font-size: 9.5vw;
    line-height: 40px;
  }
}

.startSubHeader {
  @include for-mobile-max_767 {
    font-size: 3vw;
  }

  @include for-mobile-max_480 {
    font-size: 6vw;
    br {
      display: none;
    }
  }
}

.startTxt {
  color: #fff;
  font-weight: 500;
  width: 57vw;
  margin-top: 32px;
  font-size: 1.5vw;
  line-height: 130%;

  @include text-selection-enabled;

  @include for-mobile-max_767 {
    width: 55vw;
    font-size: 2vw;
    line-height: 130%;
    margin-top: 24px;
  }

  @include for-mobile-max_480 {
    width: 87vw;
    font-size: 4.5vw;
    line-height: 150%;
    margin-top: 20px;

    br {
      display: none;
    }
  }
}

.startBut {
  margin: 20px 0;
}

.startBtn {
  box-sizing: border-box;
  border: solid 1px rgba(255, 255, 255, 1);
  background-color: transparent;
  color: rgba(255, 255, 255, 1);
  transition: 0.3s;
  display: inline-block;
  margin-top: 32px;
  padding: 1vw 4.6875vw;
  border-radius: 0.52vw;
  font-size: 1.3583vw;
  font-weight: 600;
  cursor: pointer;

  @include for-mobile-max_767 {
    padding: 5px 50px;
    border-radius: 6px;
    font-size: 16px;
    margin-top: 20px;

    .startBtn:active {
      border: 1px solid #fff;
      color: #000;
      background-color: #fff;
    }
    .startBtn:hover {
      border: 1px solid #fff;
      color: #000;
      background-color: #fff;
    }
  }

  @include for-mobile-max_480 {
    height: 40px;
    width: 120px;
    font-size: 12px;
    font-weight: 400;
    padding: 0;
    margin-top: 0px;
  }
}

.startBtn:hover {
  border: 1px solid #fff;
  color: #000;
  background-color: #fff;
}

.mobile_v .start__btn {
  padding: 5px 50px;
  border-radius: 6px;
  font-size: 16px;
}
