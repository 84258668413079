.modalEditInputWrapper {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .textFieldInput {
    border-radius: 12px;

    input {
      ::placeholder {
        font-weight: 400;
        font-size: 16px;
        color: #757575;
      }
    }
  }
}
