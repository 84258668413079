.container {
  background-color: white;
  border-radius: 16px;
  width: 100%;
  box-shadow: 0px 4px 24px rgb(0 0 0 / 4%);
  display: block;
  &.empty {
    padding: 32px 20px 32px 20px;
    text-align: center;
    //styleName: Text/textBody 16px-24px-normal-400;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
  }
}
