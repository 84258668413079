.indicatorWrapper {
  display: flex;
  flex-direction: column;
}

.progress {
  margin-top: 4px;
  width: 70%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 8px;
  border-radius: 8px;

  &::-webkit-progress-bar {
    border-radius: 8px;
    background: #ececec;
  }

  &::-moz-progress-bar {
    border-radius: 8px;
    background: #ececec;
  }

  &::-moz-progress-bar {
    border-radius: 8px;
  }

  &::-webkit-progress-value {
    border-radius: 8px;
  }

  &.weak::-moz-progress-bar {
    background-color: #d32f2f;
  }

  &.weak::-webkit-progress-value {
    background-color: #d32f2f;
  }

  &.good::-webkit-progress-value {
    background-color: #fdd835;
  }

  &.good::-moz-progress-bar {
    background-color: #fdd835;
  }

  &.strong::-webkit-progress-value {
    background-color: #237d26;
  }

  &.strong::-moz-progress-bar {
    background-color: #237d26;
  }
}

.label > .strength {
  text-transform: capitalize;
}

.label {
  &.weak > span {
    color: #d32f2f;
  }
  &.good > span {
    color: #fdd835;
  }
  &.strong > span {
    color: #237d26;
  }
}
