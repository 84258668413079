@import '../../styles/_mixins.scss';

.navbarWrapper {
  margin-bottom: 15px;
  padding: 16px 52px;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  background-color: white;
  @media screen and (max-width: 1350px) {
    display: none;
  }
}
.rowWrapper {
  border-top: 1px solid #c4c4c4;
}
.navbarSelectWrapper {
  display: none !important;
  background: #ffffff;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 12px !important;
  border: none !important;
  outline: none !important;
  margin-bottom: 16px;

  &:active,
  &:hover,
  &:focus {
    outline: 0 !important;
    outline-offset: 0 !important;
  }

  & > div {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #143471 !important;
  }

  @media screen and (max-width: 1350px) {
    display: flex !important;
  }
}

.rowMenu {
  display: flex;
  flex-direction: row;
  column-gap: 40px;
}

.rowSubMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  // padding: 10px 0px;
}

.tabsContainer {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-bottom: 15px;

  @media screen and (max-width: 1350px) {
    display: none !important;
  }
}

.tabs {
  width: 100%;
  height: 56px;

  padding-top: 4px;

  @media screen and (max-width: 1350px) {
    display: none !important;
  }

  .tabItem {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #212121;
    padding: 0;
    min-width: unset !important;
    margin: 0 20px !important;

    @media screen and (max-width: 1340px) {
      margin: 0 10px;
    }
  }

  .activeItem {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: #143471 !important;

    @include for-desktop-up-max_1500 {
      margin: 5px 10px;
    }
  }
}
