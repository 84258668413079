.rowWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  row-gap: 48px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(8px);
  border-radius: 12px;
  margin-bottom: 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 24px 18px 24px 40px;

  .summary {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 48px;
    width: 100%;
    margin-right: 40px;
    .draftName {
      width: 100%;
      max-width: 368px;
      justify-content: space-between;
      flex-direction: column;
      .title {
        font-family: 'Montserrat';
        color: #212121;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 0 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-wrap: nowrap;
      }
      .calcInfo {
        font-weight: 400;
        color: #757575;
      }
    }

    .infoBlock {
      display: flex;
      flex-direction: row;
      column-gap: 30px;
      .dataItem {
        display: flex;
        flex-direction: column;
        .dataName {
          text-wrap: nowrap;
        }
        .dataValue {
          text-wrap: nowrap;
          color: #757575;
          font-weight: 400;
        }
      }
    }
  }

  .buttonCont {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 7px;

    > div {
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      flex-grow: 0;
      flex-wrap: nowrap;
    }
  }
}

.shortFormat {
  .summary {
    flex-wrap: wrap;

    .title {
      width: 100%;
    }
  }

  .buttonCont {
    flex-direction: row;
    justify-content: end;
    flex-wrap: wrap;

    .payBtn {
      margin-right: 25px;
    }
  }
}
