.videoBg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  z-index: -1;
  background-size: cover;

  & video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
}

.bgDarken {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #00000091;
  z-index: 1;
}

.hidden {
  display: none;
}
