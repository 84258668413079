@import '../../styles/_mixins.scss';

.pagination {
  justify-content: center;
  padding-top: 15px;
  margin-top: 15px;
}

.paginationItem {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  padding: 8px 15px !important;
  background: #ffffff !important;
  border: 1px solid #efefef !important;
  border-radius: 12px !important;
  color: #212121 !important;
  @include for-mobile-max_480 {
    font-size: 12px !important;
    line-height: 20px !important;
  }
}

.paginationInfo {
  font-family: Montserrat !important;
  padding-top: 4px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 20px;
  text-align: center;
  color: #757575;
}
