@import '../../../../src/styles/mixins';

.content {
  padding: 24px 60px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);

  @include for-tablet-landscape-max_1024 {
    padding: 24px 40px;
  }

  @include for-mobile-max_620 {
    padding: 20px;
  }
  .nameTitle {
    display: flex;
    align-items: center;
    gap: 4px;

    .groupStatus {
      font-family: 'Montserrat';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
  .infoItemBlock {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      font-family: 'Montserrat';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }
    .description {
      font-family: 'Montserrat';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      .rulesBlock {
        display: flex;
        flex-direction: column;
        .ruleItem {
          display: flex;
          align-items: flex-start;
          .ruleText {
            white-space: normal;
          }
        }
      }
    }
    .tagChip {
      background-color: #efefef;
      border-radius: 12px;
      padding: 4px 12px;
      font-family: 'Montserrat';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
