.summary {
  display: flex;
  align-items: center;
  column-gap: 8px;
  align-items: baseline;
}

.title {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.header {
}

.tabs {
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid #efefef;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
  display: flex;
  justify-content: space-between;
}

.tab {
  cursor: pointer;
  padding-bottom: 8px;
  margin-bottom: 24px;
}

.tabActive {
  transition: color 0.2s ease-in;
  cursor: pointer;
  color: #18397a;
  margin-bottom: 24px;
  border-bottom: 2px solid #18397a;
}

.filterBlock {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-start;
  flex-wrap: wrap;

  .filterInput {
    min-width: auto;
    flex-basis: 200px;
    flex-grow: 9999;
    > div {
      width: 100%;
    }

    .BlockNumber {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      align-items: baseline;

      @media screen and (max-width: 420px) {
        flex-wrap: wrap;
      }
      > div {
        display: flex;
        flex-direction: row;
        gap: 8px;

        input {
          min-width: 76px;
          width: 100%;
        }
      }
    }

    &.btnApplyRow {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  }
}

.FilterDropdownRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  gap: 16px;

  .FilterDropdown {
    width: 250px;
  }
}

.menuitemhidden {
  display: none;
}

.FilterDropdownCompanyRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  border-bottom: 1px solid #efefef;
  padding-bottom: 24px;
}

.sliderRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
}

.sliderTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #555555;
}
