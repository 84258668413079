@import '../../../../styles/_mixins.scss';

.filterWrapper {
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border-radius: 12px;
  padding: 18px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  margin-bottom: 16px;
  gap: 20px;
  flex-wrap: wrap;

  .filter {
    flex: 1 1 250px;
    > div > div {
      width: 100%;
    }
  }
}
