.field {
  width: 100%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(20px);
  margin-bottom: 16px;

  color: #212121;
  font-size: 16px;
  line-height: 24px;
}

.disabled {
  background: rgba(178, 178, 178, 0.6);
  * {
    cursor: not-allowed;
  }
}

.dragActive {
  background: rgba(255, 255, 255, 0.8);
}

.addedZIndex {
  z-index: 2;
}
