.itemWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: center;
  max-width: 210px;
  cursor: pointer;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
}

.location {
  text-decoration: underline;
  margin-left: 10px;
}
