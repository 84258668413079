@import '../../../../../../src/styles/mixins';

.filterContent {
  margin-top: 20px;
  margin-bottom: -15px;

  .filterInputs {
    display: flex;
    align-items: center;
    gap: 9px;

    @media screen and (max-width: 1480px) {
      flex-wrap: wrap;
    }
    @include for-desktop-up-max_1200 {
      flex-wrap: initial;
    }

    @media screen and (max-width: 1146px) {
      flex-wrap: wrap;
    }
    @include for-tablet-landscape-max_1024 {
      flex-wrap: initial;
    }

    @include for-mobile-max_767 {
      flex-wrap: wrap;
    }

    .selectGroup {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 40%;

      @media screen and (max-width: 1480px) {
        width: 100%;
      }
      @include for-desktop-up-max_1200 {
        width: 40%;
      }
      @media screen and (max-width: 1146px) {
        width: 100%;
      }
      @include for-tablet-landscape-max_1024 {
        width: 40%;
      }

      @include for-mobile-max_767 {
        width: 100%;
      }
    }
  }

  .inputGroup {
    position: relative;
    width: 24%;
    min-width: 140px;

    @media screen and (max-width: 1480px) {
      width: 100%;
    }
    @include for-desktop-up-max_1200 {
      width: 24%;
    }
    @media screen and (max-width: 1146px) {
      width: 100%;
    }
    @include for-tablet-landscape-max_1024 {
      width: 24%;
    }
    @include for-mobile-max_767 {
      width: 100%;
    }

    .searchInput {
      font-family: 'Montserrat';
      width: 100%;
      height: 48px;
      padding: 0 56px;
      background: #ffffff;
      border: 1px solid #efefef;
      border-radius: 12px;
    }

    .searchIcon {
      display: flex;
      align-items: center;
      position: absolute;
      left: 22px;
      bottom: 14px;
    }
  }
}
.buttonsGroup {
  display: flex !important;
  justify-content: space-between;
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  padding: 9px 10px;
}
.filterActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  @include for-tablet-landscape-max_900 {
    justify-content: space-between;
  }

  .filterIcons,
  .activeIcon {
    width: 20px !important;
    height: 20px !important;
    margin: 5px;
    flex-shrink: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .activeIcon {
    svg > path {
      fill: #18397a !important;
    }
  }
}
.countInfo {
  color: #757575;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px !important;
}

.sortIcon {
  display: flex;
  align-items: center;
  gap: 12px;
}

.select {
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: none;
  font-family: 'Montserrat' !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  border-radius: 12px !important;
}
.sortSelect {
  width: calc(50% - 6px);
}

.roleSelect {
  width: calc(50% - 6px);
}
.recentlySelect {
  width: 100%;
}

.hiddenDown {
  display: block;

  @media screen and (max-width: 600px) {
    display: none;
  }
}

.hiddenUp {
  display: none;

  @media screen and (max-width: 600px) {
    display: block;
  }
}
