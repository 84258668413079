@import '/src/styles/mixins';

.midContent {
  display: flex;
  flex-direction: row;
  margin-left: 1.2% !important;
  margin-right: 1.2% !important;
  min-width: 545px;
  width: 1020px;
  @media only screen and (max-width: 1660px) {
    width: 100%;
  }

  @include for-mobile-max_767 {
    margin-left: 0 !important;
    margin-right: 0 !important;
    min-width: 320px !important;
  }
  @include for-mobile-max_767 {
    flex-wrap: wrap;
  }
}

.invite {
  margin-bottom: 4%;
}

.expertsList {
  height: 64vh;
  padding-bottom: 20px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.communityContent {
  width: 100%;
  height: 80%;

  .filterActions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    @include for-tablet-landscape-max_900 {
      justify-content: space-between;
    }

    .filterIcons,
    .activeIcon {
      width: 20px;
      height: 20px;
      margin: 5px;
      flex-shrink: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .activeIcon {
      svg > path {
        fill: #18397a !important;
      }
    }

    .filterNewFirst {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      text-transform: none;
      text-decoration: none;
      padding: 0;
      margin-right: 10px;
      min-width: 80px;
    }
  }

  .selectArrow {
    width: 25px;
    height: 100%;
    display: flex;
    margin-right: 10px;

    svg {
      path {
        fill: #757575 !important;
      }
    }
  }

  .smFilterActions {
    margin-bottom: 12px;
    justify-content: space-between;
  }

  .expertsListItem {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    width: 100%;
    background: #ffffff;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
    border-radius: 12px;

    .avatar {
      cursor: pointer;
      margin: 17px 0 17px 30px;
      border-radius: 50%;
      width: 57px;
      height: 57px;
    }
  }

  .info {
    margin-left: 18px;
    cursor: pointer;

    .name {
      font-weight: 600;
      font-size: 16px;
      color: #000000;
    }

    .moreInfo {
      margin-top: 2px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .cardButtons {
    @media screen and (max-width: 550px) {
      align-items: start !important;
    }
  }

  .buttons {
    @media only screen and (max-width: 550px) {
      display: none;
      align-items: center;
      flex-direction: column;
      margin: 10px 0;
    }
  }

  .itemIcon {
    width: 24px;
    height: 24px;

    svg > path {
      fill: #18397a !important;
    }
  }

  .rolesName {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
  }

  .blockedItemIcon {
    width: 24px;
    height: 24px;
    cursor: default;

    svg > path {
      fill: #8c9dbd !important;
    }
  }

  .optionsWindow {
    position: absolute;
    top: -10px;
    right: 45px;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0 4px 32px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    z-index: 111;

    .options {
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .tabSelect {
    width: 100%;
    height: 48px;
    border: none;
    background: #ffffff;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #18397a;

    fieldset {
      border: 0;
    }
  }
}

.filterContent {
  margin-top: 20px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include for-tablet-landscape-max_900 {
    margin-top: 0;
  }

  .inputGroup {
    position: relative;
    width: 100%;

    .searchInput {
      font-family: 'Montserrat';
      width: 100%;
      height: 48px;
      padding: 0 56px;
      background: #ffffff;
      border: 1px solid #efefef;
      border-radius: 12px;
      @media screen and (max-width: 1520px) {
        width: 90%;
      }
      @media screen and (max-width: 1380px) {
        width: 70%;
      }
      @media screen and (max-width: 900px) {
        width: 100%;
      }
      @include for-tablet-landscape-max_900 {
        margin-top: 12px;
      }
    }

    .searchIcon {
      display: flex;
      align-items: center;
      position: absolute;
      left: 22px;
      bottom: 14px;
    }
  }
}

.filterSelectContent {
  padding-right: 20px;
  @include for-tablet-landscape-max_900 {
    padding-right: 0;
    margin-top: 12px !important;
  }

  .filterSelect {
    font-family: 'Montserrat';
    border-radius: 12px !important;
    width: 100%;
    height: 48px;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    text-align: start;
    background: rgba(255, 255, 255, 1);
    color: #757575 !important;

    fieldset {
      border: 0;
    }
  }
}

.menuItem {
  font-family: 'Montserrat' !important;
}

.gridContent {
  height: 60vh;
  margin-top: 0 !important;
  padding-bottom: 20px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-radius: 12px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.card {
  min-height: 294px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  overflow: unset !important;
  width: 100%;
  border-radius: 16px !important;

  @include for-mobile-max_620 {
    padding: 20px;
  }

  .cardImageBlock {
    min-width: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cardImage {
    border-radius: 50%;
    width: 128px;
    height: 128px;
    cursor: pointer;
  }

  .gridCardName {
    margin-top: 8px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    cursor: pointer;
    @include for-mobile-max_620 {
      font-weight: 500;
      font-size: 14px;
    }
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .gridCardDescription {
    width: 100%;
    min-height: 20px;
    white-space: nowrap;
    overflow: hidden;

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      min-height: 20px;
      color: #757575;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .cardMoreInfo {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #757575;
  }

  .cardActions {
    margin-top: 4px;
    display: flex;
    justify-content: center;
    position: relative;
  }
}

.buttonsGroup {
  display: flex !important;
  background: #fff;
  height: 100%;
  margin-left: 12px;
  border-radius: 12px;
  padding: 10px;
}

@media screen and (max-width: 900px) {
  .mapGrid {
    margin-top: 16px !important;
  }
}
