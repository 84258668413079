.pickerContainer {
  position: relative;
}

.placeholderWrapper {
  display: block;
}

.pickerWrapper {
  position: absolute;
  margin-top: -4px;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 156px;
  height: 44px;
  background: #f9f9f9;
  border-radius: 12px;

  > span {
    cursor: pointer;
  }
}

.pickerWrapperComment {
  position: absolute;
  margin-top: -4px;
  top: -2px;
  left: -10px;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 160px;
  height: 28px;
  background: #262626ff;
  border-radius: 12px;

  > span {
    cursor: pointer;
    height: 16px !important;
  }
}

.pickerShow {
  position: absolute;
  margin-top: -4px;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100px;
  height: 44px;
  background: #f9f9f9;
  border-radius: 12px;

  > span {
    cursor: pointer;
  }

  @media only screen and (max-width: 572px) {
    width: 50px;
  }
}

.pickerShowComment {
  position: absolute;
  margin-top: -4px;
  top: -2px;
  left: -10px;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 50px;
  height: 28px;
  background: #262626ff;
  border-radius: 14px;

  > span {
    cursor: pointer;
    height: 16px !important;
  }

  @media only screen and (max-width: 572px) {
    width: 50px;
  }
}

.emojiDisplay {
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    margin-right: -2px;
    outline: 1px #ffffff;
    position: relative;
  }
}
