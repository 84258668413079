@import '/src/styles/mixins';

.container,
.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: max-content;
  background-color: white;
  padding-top: 20px;
  /* width: 100vw; */
  max-width: 1020px;
  border-radius: 12px;
  margin-bottom: 16px;

  @include for-mobile-max_767 {
    display: block;
    width: 98vw !important;
    left: 0 !important;
    flex-wrap: wrap !important;
    font-size: 14px !important;
  }

  @media only screen and (max-width: 1660px) {
    width: 100%;
    max-width: 100%;
  }
}

.loadingContainer {
  display: flex !important;
  padding: 20px 32px;
  gap: 15px;

  @media only screen and (max-width: 370px) {
    padding: 20px;
  }
}

.loadingHeader,
.loadingFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.loadingFooter {
  width: 100%;
  padding: 0 40px;
  justify-content: space-between;
}

.header {
  display: flex;
  flex-direction: row;
  align-content: center;
  /* width: 100%; */
  padding-left: 3%;
}

.headerComment {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 8%;
  padding-top: 1%;
}

.nameAndAge {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.headeroption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
}

.buttonOption {
  cursor: pointer;
}

.age {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #757575;
}

.name {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #212121;
}

.nameComment {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #212121;
}

.body {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  height: max-content;
  white-space: pre-line;
  line-height: 24px;
  color: #212121;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 64px;
  padding-right: 24px;
}

.fileBox {
  display: flex;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  max-width: 1020px !important;
  padding-bottom: 10px;
  margin-left: 0 !important;
  margin-right: 0 !important;

  @media only screen and (max-width: 1660px) {
    width: 100%;
    max-width: 100% !important;
  }
}

.likesAndEmojy {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  padding-left: 34px;

  @include for-mobile-max_767 {
    font-size: 12px;
    line-height: 20px;
    flex-wrap: nowrap;
  }
}

.reactedBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  width: 100%;
}

.emojys {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.emojy {
  padding-right: 0px;
}

.comments {
  padding-right: 30px;
  @include for-mobile-max_767 {
    flex-wrap: nowrap;
    padding-right: 10px;
  }
}

.postButtons {
  width: 120%;
  display: flex;
  flex-direction: row !important;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

.unauthPostButtons {
  width: 100%;
  justify-content: flex-end;

  > div {
    margin-right: 20px;
  }
}

.buttonPost {
  column-gap: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  width: 130%;
  align-items: center;
  height: 48px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background-color: white;
  color: #212121;
  border: none;
  outline: none;

  @include for-mobile-max_767 {
    width: 100%;
    height: 38px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }
}

.chooseEmoji {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-left: 10px;
  cursor: pointer;
}

.videoplayer {
  max-height: 800px !important;
  width: 42vw !important;
  max-width: 1020px !important;
}

.audioPlayer {
  width: 100%;
}

.audioPlayerDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  width: 80% !important;
  margin-left: 5%;
}

.audioPlayerName {
  padding-left: 4%;
  width: max-content;
  white-space: nowrap;
  line-height: 20px;
  text-align: right;
  overflow: hidden;
  font-size: 16px;
  -webkit-animation: alternat 20s 1s infinite;
  animation: alternat 20s 1s infinite;
}

@keyframes alternat {
  0% {
    transform: translateX(40%);
  }
  100% {
    transform: translateX(-30%);
  }
}

.feed {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.feedPartLoader {
  margin: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
}

.scrollLoading {
  background-color: #e5e5e5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
