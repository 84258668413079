.leftSection {
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin-left: 80px;
  }
}

.mainSection_1 {
  position: relative;
  color: #ffffff99;
  font-weight: 800;
  font-size: 200px;
  margin-top: 20px;
  margin-left: 190px;
  margin-bottom: -5px;

  @media (max-width: 1500px) {
    font-size: 160px;
    margin-left: 120px;
  }

  @media (max-width: 1200px) {
    display: none;
  }
}

.mainSection_2 {
  position: relative;
  color: #ffffff66;
  font-weight: 600;
  font-size: 30px;
  margin-top: -180px;
  margin-left: 610px;
  margin-bottom: -10px;

  @media (max-width: 1500px) {
    font-size: 25px;
    margin-top: -145px;
    margin-left: 470px;
  }

  @media (max-width: 1200px) {
    display: none;
  }
}

.mainSection_3 {
  position: relative;
  color: #ffffff66;
  font-weight: 700;
  font-size: 160px;
  margin-left: 600px;
  margin-top: -25px;

  @media (max-width: 1500px) {
    font-size: 120px;
    margin-left: 460px;
    margin-top: -15px;
  }

  @media (max-width: 1200px) {
    display: none;
  }
}

.respMainSection_1 {
  display: none;

  @media (max-width: 1200px) {
    display: block;
    color: #ffffff80;
    font-size: 25px;
    font-weight: 600;
    text-align: right;
    line-height: 30.48px;
    width: 120px;
    margin-left: 0;
    margin-top: 60px;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 17.29px;
    width: 75px;
    margin-top: 40px;
  }
}

.respMainSection_2 {
  display: none;

  @media (max-width: 1200px) {
    display: block;
    color: #ffffff99;
    font-size: 140px;
    font-weight: 800;
    line-height: 113.96px;
    text-align: right;
    width: 540px;
    margin-left: 40px;
    margin-top: -70px;
    margin-bottom: 60px;
  }

  @media (max-width: 768px) {
    font-size: 60px;
    line-height: 43.74px;
    width: 215px;
    margin-top: -35px;
    margin-left: 55px;
    margin-bottom: 40px;
  }
}

.stepSection_1 {
  position: relative;
  color: #ffffff99;
  font-weight: 600;
  font-size: 50px;
  line-height: 40px;
  width: 250px;
  margin-top: 60px;
  margin-left: 90px;
  margin-bottom: 0;
  text-align: right;

  @media (max-width: 1500px) {
    font-size: 40px;
    margin-left: 35px;
    line-height: 32px;
  }

  @media (max-width: 1200px) {
    font-size: 20px;
    margin-left: 0;
    width: 120px;
    line-height: 15.5px;
    margin-top: 30px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 10.5px;
    width: 70px;
    margin-top: 20px;
  }
}

.stepSection_2 {
  position: relative;
  color: #ffffff99;
  font-weight: 700;
  font-size: 200px;
  width: 100px;
  margin-top: -170px;
  margin-left: 350px;
  line-height: 160px;
  text-align: right;

  @media (max-width: 1500px) {
    font-size: 120px;
    line-height: 98.88px;
    margin-left: 300px;
    margin-top: -105px;
  }

  @media (max-width: 1200px) {
    font-weight: 800;
    font-size: 70px;
    margin-left: 105px;
    margin-top: -60px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    margin-top: -60px;
    margin-left: 10px;
  }
}

.stepSection_3 {
  position: relative;
  color: #ffffff99;
  font-weight: 700;
  font-size: 120px;
  margin-top: 0;
  width: 430px;
  margin-left: 120px;
  line-height: 100px;
  text-align: right;

  @media (max-width: 1500px) {
    line-height: 65.92px;
    font-size: 80px;
    margin-left: 0;
  }

  @media (max-width: 1200px) {
    font-weight: 800;
    font-size: 70px;
    margin-top: -82px;
    margin-left: 185px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    margin-left: 85px;
    width: 200px;
    margin-bottom: 0;
  }
}

.stepFinalSection_1 {
  color: #ffffff99;
  font-size: 120px;
  font-weight: 800;
  line-height: 92.4px;
  width: 330px;
  text-align: right;
  margin-left: 250px;

  @media (max-width: 1500px) {
    font-size: 100px;
    line-height: 70px;
    margin-left: 110px;
  }

  @media (max-width: 1200px) {
    font-size: 120px;
    width: 620px;
    margin-left: 20px;
    line-height: 97.68px;
    margin-top: 30px;
  }

  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 29.16px;
    width: 210px;
    margin-left: 0;
    margin-top: 20px;
  }
}

.stepFinalSection_2 {
  color: #ffffff66;
  font-size: 100px;
  font-weight: 700;
  line-height: 90px;
  width: 790px;
  margin-left: 610px;
  margin-top: -170px;

  @media (max-width: 1500px) {
    font-size: 80px;
    line-height: 70px;
    margin-left: 470px;
    margin-top: -135px;
  }

  @media (max-width: 1200px) {
    font-size: 25px;
    margin-top: -20px;
    margin-left: 260px;
    width: 380px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    font-size: 10px;
    font-weight: 600;
    line-height: 12.19px;
    width: 150px;
    margin-left: 60px;
    margin-top: 5px;
    margin-bottom: 15px;
  }
}

.stepContent {
  color: #ffffff;
  font-size: 25px;
  font-weight: 500;
  line-height: 37.5px;
  width: 100%;
  max-width: 580px;
  margin-left: 60px;

  > div {
    margin-bottom: 40px;

    @media (max-width: 1500px) {
      margin-bottom: 30px;
    }

    @media (max-width: 1200px) {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1500px) {
    max-width: 480px;
    font-size: 20px;
  }

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    margin-left: 25px;
    margin-right: 35px;
    width: unset;
    font-size: 12px;
    line-height: 20px;
  }
}

.finalStepContent {
  color: #ffffff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  //max-width: 680px;

  @media (max-width: 1500px) {
    max-width: 580px;
  }

  @media (max-width: 1200px) {
    max-width: unset;
    height: 35vh;
  }

  @media (max-width: 768px) {
    margin-left: 25px;
    margin-right: 25px;
    width: 100%;
    height: 40vh;
  }
}

.guideButton {
  width: 350px;
  height: 70px;
  margin-bottom: 30px;
  margin-left: -25%;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;
  font-weight: 400;
  text-align: center;

  border: 1px solid #ffffff;
  border-radius: 10px;

  @media (max-width: 1500px) {
    font-size: 20px;
    width: 300px;
    height: 60px;
    margin-left: 30px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    width: 136px;
    height: 34px;
    border-radius: 6px;
    margin-top: 10px;
  }
}
