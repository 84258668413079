.container {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  width: 100%;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}

.headerContent {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.headerTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

.headerSubtitle {
  font-family: 'Montserrat';
  font-weight: 500;
  color: #555555;
}

.headerYear {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  color: #555555;
}

.SortRow {
  display: flex;
  column-gap: 20px;
}

.SortTitle {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.Sort {
  width: 226px;
}

.SortHeadRow {
  padding: 15px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 12px;
}

.Search {
  width: 425px;
  position: relative;
}

.SearchInput {
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 12px;
  width: 100%;
  padding: 15px 0px;
  font-family: 'Montserrat';
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding-left: 56px;
}

.SearchImg {
  position: absolute;
  left: 19px;
  top: 17px;
}
