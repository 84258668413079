@import '../../../../styles/_mixins.scss';

.rolesItem {
  display: block;
  width: 16.354vw;
  height: 9.53125vw;
  margin-bottom: 3.125vw;
  cursor: pointer;

  & > a {
    text-decoration: none;
  }

  @include for-mobile-max_767 {
    width: 27.5vw;
    height: 15.88vw;
    margin-bottom: 13.28125vw;
  }

  @include for-mobile-max_480 {
    width: 40vw;
    height: 22.8125vw;
    margin: 0 auto 4.6875vw;
  }

  @include for-screen_3_4 {
    width: 20vw;
    height: 9vw;
    margin-bottom: 7vw;
  }
}

.rolesBig {
  font-size: 6.25vw;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.4);
  position: relative;
  transition: 0.3s;
  text-transform: uppercase;

  &::after {
    content: '';
    width: 0px;
    height: 0.36458vw;
    position: absolute;
    right: 0;
    bottom: 1.04166vw;
    transition: 0.2s;
  }

  &:hover {
    font-size: 7.8125vw;
  }

  &:hover .roles__small {
    font-size: 2.083vw;
    font-weight: 700;
  }

  &:hover:after {
    width: 4.167vw;
  }

  @include for-mobile-max_767 {
    font-size: 13.02vw;
  }

  @include for-mobile-max_480 {
    font-size: 18.75vw;
  }

  @include for-screen_3_4 {
    font-size: 9vw;
  }
}

.rolesSmall {
  display: block;
  position: absolute;
  right: 0;
  color: #fff;
  font-weight: 600;
  transition: 0.1s;
  font-size: 1.875vw;
  bottom: 1.25vw;
  letter-spacing: normal;

  @include for-mobile-max_767 {
    font-size: 3.90625vw;
    bottom: 2.5vw;
  }

  @include for-mobile-max_480 {
    font-size: 4.6875vw;
    bottom: 3vw;
  }

  @include for-screen_3_4 {
    font-size: 2vw;
    bottom: 1.5vw;
  }
}

.exp::after {
  background-color: rgba(10, 178, 171, 1);
}
.inv::after {
  background-color: rgba(60, 112, 151, 1);
}
.bsn::after {
  background-color: rgba(45, 131, 66, 1);
}
.ind::after {
  background-color: rgba(216, 145, 60, 1);
}
