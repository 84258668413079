@import '/src/styles/mixins';

.foldersListItem {
  display: flex;
}

.myFoldersText {
  font-weight: 600;
  font-size: 16px;
  color: #212121;
}

.dialogContent {
  margin-top: 10px;
}

.dialogActions {
  justify-content: flex-start !important;
  padding: 8px 0 !important;
}

.dialogActionButtonIcon {
  color: #143471;
}

.dialogActionButton {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.folderNameInputContent {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.folderColorBtn {
  width: 54px;
  height: 54px;
  position: relative !important;
  overflow: hidden !important;
}

.folderPenIcon {
  width: 100%;
  height: 30%;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.folderNameInput {
  box-sizing: border-box;
  width: 409px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 12px;
  padding: 0 20px;
}

.folderNameInput::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: #757575;
}

.searchInputContent {
  background: #f9f9f9 !important;
  border-radius: 12px;
  height: 40px;
  box-shadow: none !important;
  align-items: center;
  display: flex;
}

.searchInputIcon {
  padding-left: 26px;
  padding-right: 11px;
}

.usersListContent {
}

.errorText {
  color: red;
  position: absolute;
  bottom: -18px;
  left: 10px;
  font-size: 14px !important;
}

.usersListContentHeader {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.addChatsContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  @include for-mobile-max_620 {
    width: 50%;
  }
}

.addChatsTitle {
  font-weight: 600;
  font-size: 16px;
  color: #212121;
}

.addChatsCount {
  font-weight: 400;
  font-size: 12px;
  color: #212121;
  @include for-mobile-max_480 {
    width: 60%;
  }
}

.createDialogActions {
  justify-content: space-between !important;
  margin-top: 10px !important;
  padding: 0 !important;
}

.createDialogActionButton {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.colorPickerContent {
  display: flex;
  gap: 20px;
  margin-top: 14px;
}

.colorPickerLabel {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #555555;
}

.colorPickerFolder {
  width: 54px;
  height: 54px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
