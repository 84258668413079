.leftSection {
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin-left: 80px;
  }
}

.mainSection_1 {
  position: relative;
  color: #ffffff99;
  font-weight: 800;
  font-size: 220px;
  margin-top: 20px;
  margin-left: 185px;
  margin-bottom: -5px;

  @media (max-width: 1500px) {
    font-size: 200px;
    margin-left: 75px;
  }

  @media (max-width: 1200px) {
    margin-top: 60px;
    font-size: 180px;
    margin-left: 0;
  }

  @media (max-width: 768px) {
    font-size: 80px;
    margin-top: 40px;
  }
}

.mainSection_2 {
  position: relative;
  color: #ffffff66;
  font-weight: 600;
  font-size: 30px;
  margin-top: -195px;
  margin-left: 610px;
  margin-bottom: -30px;
  word-break: break-word;
  width: 180px;
  text-align: right;

  @media (max-width: 1500px) {
    font-size: 25px;
    margin-top: -170px;
    margin-left: 450px;
    margin-bottom: -40px;
    width: 150px;
  }

  @media (max-width: 1200px) {
    font-size: 25px;
    width: 240px;
    margin-top: -26px;
    margin-bottom: -203px;
    margin-left: 60px;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    width: 150px;
    margin-left: 2px;
    margin-top: -10px;
    margin-bottom: -93px;
  }
}

.mainSection_3 {
  position: relative;
  color: #ffffff66;
  font-weight: 700;
  font-size: 220px;
  margin-left: 605px;
  margin-top: -50px;

  @media (max-width: 1500px) {
    font-size: 200px;
    margin-left: 455px;
    margin-top: -30px;
  }

  @media (max-width: 1200px) {
    color: #ffffff99;
    font-size: 180px;
    font-weight: 800;
    margin-top: 0;
    margin-left: 340px;
    padding-bottom: 60px;
  }

  @media (max-width: 768px) {
    font-size: 80px;
    margin-left: 152px;
    padding-bottom: 40px;
  }
}

.stepSection_1 {
  position: relative;
  color: #ffffff99;
  font-weight: 700;
  font-size: 150px;
  margin-top: 20px;
  width: 200px;
  margin-left: 190px;
  margin-bottom: 0;
  text-align: right;

  @media (max-width: 1500px) {
    font-size: 120px;
    margin-left: 130px;
  }

  @media (max-width: 1200px) {
    font-size: 70px;
    font-weight: 800;
    margin-left: -20px;
    //width: 490px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    margin-top: 10px;
    margin-left: -50px;
  }
}

.stepSection_2 {
  position: relative;
  color: #ffffff99;
  font-weight: 600;
  font-size: 50px;
  margin-top: -40px;
  margin-left: 110px;
  text-align: left;

  @media (max-width: 1500px) {
    font-size: 40px;
    line-height: 32px;
    margin-left: 110px;
    margin-top: -25px;
  }

  @media (max-width: 1200px) {
    text-align: left;
    font-size: 20px;
    font-weight: 700;
    line-height: 15.5px;
    margin-top: -60px;
    margin-left: 185px;
    padding-bottom: 50px;
    width: 100px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    margin-left: 155px;
    margin-top: -27px;
    font-weight: 600;
    line-height: 10.5px;
    padding-bottom: 15px;
  }
}

.stepContent {
  color: #ffffff;
  font-size: 25px;
  font-weight: 500;
  line-height: 37.5px;
  width: 100%;
  max-width: 580px;
  margin-left: 60px;

  > h3 {
    margin-bottom: 39px;

    @media (max-width: 1500px) {
      margin-bottom: 30px;
    }

    @media (max-width: 1200px) {
      margin-bottom: 20px;
    }
  }

  li {
    margin-bottom: 30px;

    @media (max-width: 1500px) {
      margin-bottom: 20px;
    }

    @media (max-width: 1200px) {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 1500px) {
    max-width: 480px;
    font-size: 20px;
  }

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    margin-left: 25px;
    margin-right: 30px;
    width: unset;
    font-size: 12px;
    line-height: 20px;
  }
}
