@import '../../../../../styles/_mixins.scss';

$red: #cd0606;

.dragDropFieldWrapper {
}

.errorInputWrapper {
  padding: 4px;

  & > .dragDropFieldSecondWrapper {
    border: 2px solid $red;
    border-radius: 10px;
    padding: 6px 24px 6px 26px;

    & > .mapBtn {
      @include for-mobile-max_767 {
        top: 6px;
      }
    }

    & > input {
      border-right: 2px solid $red;

      @include for-mobile-max_767 {
        border-right: none;
        border-bottom: 2px solid $red;
      }
    }
  }
}

.fieldIconDefaultFill {
  width: 30px;
  height: 30px;

  svg > path {
    fill: #757575 !important;
  }
}

.fieldIconDefaultStroke {
  width: 30px;
  height: 30px;

  svg > path {
    stroke: #757575 !important;
  }
}

.dragDragActive {
  padding: 4px;

  & > .dragDropFieldSecondWrapper {
    border-radius: 10px;
    border: 2px solid #757575;
    padding: 6px 24px 6px 26px;
  }
}

.dragDropActive {
  z-index: 1000;
}

.dragDropFieldSecondWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 30px 12px 32px;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #212121;
  cursor: pointer;

  & > .uploadedFilesWrapper {
    display: none;

    @include for-mobile-max_620 {
      display: flex;
    }
  }

  @include for-mobile-max_767 {
    flex-direction: column;
  }
}

.textWrapper {
  & > .uploadedFilesWrapper {
    @include for-mobile-max_620 {
      display: none;
    }
  }
}

.textFull {
  width: 100%;
  & > .uploadedFilesWrapper {
    @include for-mobile-max_620 {
      display: none;
    }
  }
}

.dropZone {
  display: flex;
  align-items: center;
  cursor: pointer;

  width: 65%;

  border-right: 2px solid #212121;

  @include for-mobile-max_767 {
    width: 100%;
    border-right: none;
    border-bottom: 2px solid #212121;
  }
}

.dropZoneOther {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.iconWrapper {
  position: relative;
  margin-right: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  height: 50px;
  border: 1px solid #757575;
  filter: drop-shadow(0px 10px 30px rgba(119, 240, 46, 0.3));
  border-radius: 14px;
  overflow: hidden;

  @include for-mobile-max_767 {
    margin-left: 8px;
  }
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.add {
  font-size: 12px;
}

.descriptionWrapper {
  width: 35%;
  margin-left: 20px;

  @include for-mobile-max_767 {
    width: 100%;
    margin-left: 0;
  }
}

.textWrapper {
  width: calc(100% - 140px);
  max-width: 700px;

  @include for-desktop-up-max_1860 {
    max-width: 600px;
  }
  @include for-desktop-up-max_1440 {
    max-width: 500px;
  }
  @include for-tablet-landscape-max_1024 {
    max-width: 350px;
  }
  @include for-mobile-max_767 {
    width: unset;
    max-width: none;
  }
}

.uploadedFilesWrapper {
  display: flex;
  overflow: auto;
  max-width: 100%;
  z-index: 10;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
    height: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #a6a6a6;
  }

  &::-webkit-scrollbar-track-piece {
    background: #a6a6a6;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #757575;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @include for-mobile-max_767 {
    margin-bottom: 10px;
  }

  @include for-mobile-max_620 {
    width: 100%;
  }
}

.uploadedFilesSecondWrapper {
  position: relative;
  display: flex;

  & > div {
    margin-right: 16px;
  }

  & > div:last-child {
    margin-right: 0;
  }
}

.error {
  color: #d32f2f;
}

.description {
  @include for-mobile-max_767 {
    font-size: 12px;
  }
}

.linearGradientLeft {
  display: none;
  position: absolute;
  left: 0;
  height: 36px;
  width: 40px;
  z-index: 2;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.6), hsla(0, 0%, 100%, 0));
  transform: rotate(180deg);
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.3);
}

.linearGradientRight {
  display: none;
  position: absolute;
  right: 0;
  height: 36px;
  width: 40px;
  z-index: 2;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.6), hsla(0, 0%, 100%, 0));
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.3);
}

.visible {
  display: block;
}
