@import '/src/styles/mixins';

$greenColor: #4caf50;

#deleteButton {
  border-color: #d32f2f !important;
  color: #d32f2f !important;
  > svg > path {
    fill: #d32f2f;
  }
  background-color: transparent !important;
}

.blockHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
  .returnButton {
    cursor: pointer;
    display: flex;
    gap: 12px;
    padding: 0px 20px;
    align-items: center;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0%;
    color: #212121 !important;
    text-transform: none;
    @media screen and (max-width: 380px) {
      padding: 0;
    }
  }
}
.calculatorDetail {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 32px;
  margin-bottom: 20px;

  h1 {
    margin: 0;
  }
  .blockBody {
    // display: block;
    // min-height: 360px;
    // height: fit-content;
    // padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 22px;
    min-height: 350px;
    height: fit-content;

    &.vertical {
      align-items: center;
      flex-direction: column;
    }
    .chartArea {
      min-width: 250px;
      min-height: 250px;
      flex-basis: 450px;
      flex-grow: 1;
      // margin-bottom: 16px;
      // @media screen and (min-width: 1024px) and (max-width: 1200px) {
      //   height: 50vw;
      // }
      // @media screen and (max-width: 680px) {
      //   height: 81vw;
      // }
      // @media screen and (max-width: 380px) {
      //   height: calc(100vw - 80px);
      // }
      // @media screen and (min-width: 1200px) {
      //   height: 22vw;
      // }
    }
    .textArea {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 340px;
      flex-basis: 450px;
      margin: auto;
      .statistic {
        width: 100%;
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);
        padding: 16px 20px;
        margin-bottom: 16px;

        .headerBlock {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          margin-bottom: 16px;

          div {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #212121;
          }
        }
        .scoreBlock {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          margin-bottom: 16px;
        }
        .list {
          margin-top: 16px;
          .lineofList {
            display: flex;
            justify-content: space-between;
            align-items: inherit;
            .option {
              display: flex;
              column-gap: 10px;
              margin-bottom: 16px;
              @media screen and (max-width: 400px) {
                width: min-content;
              }
            }
          }
        }
      }
      .buttonBlock {
        display: flex;
        margin-left: auto;
        gap: 20px;
        flex-direction: row;
        width: min-content;
        @media screen and (max-width: 580px) {
          width: 100%;
          align-items: center;
          flex-direction: column;
          margin: 0;
        }

        button {
          font-size: 16px;
          font-weight: 700;
          line-height: 38px;
          text-transform: uppercase;
          color: #18397a;
          border-color: #18397a;

          @media screen and (max-width: 580px) {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}
.nested {
  margin-left: 20px;
}
.qbuttonsvg {
  overflow: visible;
  fill: $greenColor;
  color: $greenColor;
}
