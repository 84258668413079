.tableRow {
  transition: all 0.3s ease;
  th {
    padding: 16px 7px;
    background-color: transparent !important;
  }

  &:hover {
    background-color: rgba(140, 157, 189, 0.12);
    td,
    th {
      color: #18397a;
    }
  }

  &.current {
    background: rgba(140, 157, 189, 0.24);
  }
}

.filtersRow {
  display: flex;
  justify-content: flex-end;
}
