@import '/src/styles/mixins';

$greenColor: #4caf50;
$greyColor: #757575;

.companyMainFrame {
  margin-top: 20px;
  .container {
    margin-bottom: 20px;
    padding: 24px 24px;
    isolation: isolate;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 6%);
    backdrop-filter: blur(8px);
    border-radius: 12px;
    .buttonBlock {
      padding-top: 16px;
      margin-left: auto;
    }
    .sourceBlock {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
      gap: 16px;

      .questionTitle {
        min-width: 200px;
        max-width: 200px;
        display: flex;
        word-wrap: break-word;
        gap: 10px;
      }
      .qbuttonsvg {
        overflow: visible;
      }
      .answered {
        fill: $greenColor;
        color: $greenColor;
      }
      .required {
        fill: $greyColor;
        color: $greyColor;
      }
    }

    .componentsContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;

      .componentBlock {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 30px;
        word-wrap: break-word;

        .componentNum {
          margin-bottom: 10px;
          font-weight: 700;
        }
        .componentDetails {
          display: flex;
          width: fit-content;
          flex-wrap: wrap;
          row-gap: 10px;
          column-gap: 50px;

          div {
            span {
              font-weight: 700;
              color: rgb(100, 100, 100);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .companyMainFrame {
    .container {
      .sourceBlock {
        padding: 16px;
        flex-direction: column;
        row-gap: 16px;
      }
      .buttonBlock {
        margin: 0 auto;
      }
      .componentsContainer {
        .componentBlock {
          width: 100%;
          flex-direction: column;
        }
      }
    }
  }
}

.summary {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
