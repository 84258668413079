.uploadedFileWrapper {
  display: flex;
  align-items: center;
  width: max-content;
  margin: 6px 0;
  gap: 10px;
}

.uploadedIconWrapper,
.closeIconWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

.fileName {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.uploading {
  & .fileName {
    color: #757575;
  }
}

.uploaded {
  & .fileName {
    color: #4caf50;
  }
}

.error {
  & .fileName {
    color: #d32f2f;
  }
}

.defaultIcon {
  width: 20px;
  height: 20px;

  svg > path {
    fill: #757575 !important;
  }
}

.successIcon {
  width: 20px;
  height: 20px;

  svg > path {
    fill: #4caf50 !important;
  }
}

.errorIcon {
  width: 20px;
  height: 20px;

  svg > path {
    fill: #d32f2f !important;
  }
}

.closeIconWrapper {
  margin-left: auto;
}
