@import '../../../../../styles/_mixins.scss';

.modal {
  padding: 28px 40px !important;
}

.modalBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.modalTitleRow {
  width: 100%;
  display: flex;
  justify-content: space-between;

  > img {
    width: 14px;
    height: 14px;
    cursor: pointer;
    filter: invert(1);
  }
}

.modalTitle {
  font-weight: 800;
  font-size: 32px;
  line-height: 150%;
  color: #ffffff;
  text-align: start;

  @media screen and (max-width: 450px) {
    font-size: 20px;
    line-height: 24.38px;
  }
}

.modalContent {
  margin-top: 20px;
  overflow: auto;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 4px !important;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 370px) {
    margin-top: 5px;
  }
}

.error {
  color: red;
  margin-top: 20px;
}

.modalText {
  font-weight: 500;
  font-size: 18px !important;
  line-height: 150%;
  color: #ffffff;
  margin: 20px 5px;

  @include for-mobile-max_767 {
    font-size: 16px;
    text-align: center;
  }

  @include for-mobile-max_480 {
    margin: 20px 0;
  }

  @media screen and (max-width: 370px) {
    font-size: 12px;
    line-height: unset;
    margin: 5px 0;
  }

  @include for-narrow {
    font-size: 14px;
  }

  @include for-screen_3_4 {
    font-size: 14px;
  }
}

.buttonsWrapper {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @include for-mobile-max_767 {
    flex-direction: column;
    gap: 16px;
  }

  @include for-mobile-max_480 {
    width: 100%;
  }

  @media screen and (max-width: 380px) {
    margin-top: 5px;
    gap: 5px;
  }

  @include for-narrow {
    margin-top: 10px;
  }

  @include for-screen_3_4 {
    margin-top: 10px;
  }
}

.firstBtn,
.secondBtn {
  padding: 8px 28px !important;
  gap: 28px;
  min-width: 146px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  border: 2px solid #ffffff;

  &:disabled {
    border: 2px solid #ffffff !important;
    opacity: 0.6;
    cursor: default;
  }

  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.8);
  }

  @include for-mobile-max_767 {
    font-size: 14px;
  }

  @include for-narrow {
    font-size: 14px;
    padding: 8px 14px;
  }

  @include for-screen_3_4 {
    font-size: 14px;
    padding: 8px 14px;
  }
}

.firstBtn {
  background: transparent;
  color: #ffffff;
}

.secondBtn {
  background: #ffffff;
  color: #333333;
}
