.guideLeftSection {
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin-left: 80px;
  }
}

.guideMain_1 {
  color: #ffffffcc;
  font-weight: 600;
  font-size: 40px;
  margin-top: 20px;
  margin-left: 490px;
  margin-bottom: -50px;

  @media (max-width: 1500px) {
    font-size: 35px;
    margin-left: 350px;
    margin-bottom: -30px;
  }

  @media (max-width: 1200px) {
    font-size: 30px;
    margin-left: 300px;
    margin-bottom: -30px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    margin-left: 162px;
    margin-bottom: -10px;
  }
}

.guideMain_2 {
  position: relative;
  color: #ffffff;
  font-weight: 800;
  font-size: 180px;
  margin-left: 10px;
  margin-top: -5px;
  margin-bottom: -40px;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1500px) {
    font-size: 120px;
    margin-left: 50px;
  }

  @media (max-width: 1200px) {
    margin-left: 0;
  }

  @media (max-width: 768px) {
    font-size: 70px;
    margin-bottom: -20px;
  }
}

.guideMain_3 {
  position: relative;
  color: #ffffffea;
  font-weight: 600;
  font-size: 70px;
  margin-left: 115px;
  margin-top: -20px;
  width: 700px;
  z-index: 11;

  @media (max-width: 1500px) {
    margin-left: 90px;
    font-size: 40px;
    margin-top: 5px;
    width: unset;
  }

  @media (max-width: 1200px) {
    margin-left: 50px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    margin-top: 5px;
  }
}

.guideMain_4 {
  position: relative;
  color: #ffffffea;
  font-weight: 600;
  font-size: 70px;
  margin-left: 115px;
  margin-top: -20px;
  margin-bottom: -30px;
  z-index: 12;

  @media (max-width: 1500px) {
    margin-left: 135px;
    margin-bottom: 0;
    margin-top: -15px;
    font-size: 40px;
  }

  @media (max-width: 1200px) {
    margin-left: 95px;
    margin-top: -15px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    margin-top: -5px;
    margin-left: 72px;
  }
}

.guideMain_5 {
  position: relative;
  color: #ffffff;
  font-weight: 700;
  font-size: 120px;
  margin-left: 350px;
  margin-top: 0;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1500px) {
    margin-left: 340px;
    font-size: 60px;
    margin-top: -18px;
  }

  @media (max-width: 1200px) {
    margin-left: 294px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    margin-top: -8px;
    margin-left: 117px;
  }
}

.guideStepLeft_1 {
  color: #ffffffcc;
  font-weight: 600;
  font-size: 40px;
  margin-top: 20px;
  margin-left: 490px;
  margin-bottom: -50px;

  @media (max-width: 1500px) {
    font-size: 35px;
    margin-left: 360px;
    margin-bottom: -28px;
  }

  @media (max-width: 1200px) {
    font-size: 20px;
    margin-left: 0;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: -20px;
  }
}

.guideStepLeft_2 {
  position: relative;
  color: #ffffff;
  font-weight: 800;
  font-size: 180px;
  margin-left: 10px;
  margin-top: -5px;
  margin-bottom: -30px;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1500px) {
    font-size: 120px;
    margin-left: 60px;
  }

  @media (max-width: 1200px) {
    font-size: 70px;
    margin-left: 40px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    margin-left: 25px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.guideStepLeft_3 {
  position: relative;
  color: #ffffffea;
  font-weight: 600;
  font-size: 50px;
  margin-left: 150px;
  margin-top: -10px;
  z-index: 11;

  @media (max-width: 1500px) {
    font-size: 40px;
    margin-left: 100px;
    margin-top: -8px;
  }

  @media (max-width: 1200px) {
    font-size: 15px;
    margin-left: 260px;
    margin-top: -30px;
  }

  @media (max-width: 768px) {
    font-size: 10px;
    margin-top: -30px;
    margin-left: 120px;
  }
}

.guideStepLeft_4 {
  position: relative;
  color: #ffffffea;
  font-weight: 600;
  font-size: 50px;
  margin-left: 205px;
  margin-top: -10px;
  margin-bottom: -20px;
  z-index: 12;

  @media (max-width: 1500px) {
    font-size: 40px;
    margin-left: 145px;
    margin-bottom: -5px;
  }

  @media (max-width: 1200px) {
    font-size: 15px;
    margin-left: 260px;
    margin-bottom: 0;
    margin-top: -5px;
  }

  @media (max-width: 768px) {
    font-size: 10px;
    margin-left: 120px;
    margin-top: -3px;
  }
}

.guideStepLeft_5 {
  position: relative;
  color: #ffffff;
  font-weight: 700;
  font-size: 120px;
  margin-left: 180px;
  margin-top: -10px;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1500px) {
    font-size: 60px;
    margin-left: 230px;
  }

  @media (max-width: 1200px) {
    font-size: 20px;
    margin-left: 260px;
    margin-top: -4px;
    padding-bottom: 20px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    margin-left: 120px;
  }
}

.guideStepContentWrapper {
  color: #ffffffea;
  font-weight: 500;
  font-size: 25px;
  width: 100%;
  max-width: 580px;
  line-height: 37.5px;
  margin-left: 60px;

  @media (max-width: 1500px) {
    max-width: 450px;
    font-size: 20px;
  }

  @media (max-width: 1200px) {
    font-size: 16px;
    max-width: 500px;
  }

  @media (max-width: 768px) {
    margin-left: 25px;
    margin-right: 25px;
    width: unset;
    font-size: 12px;
    line-height: 20px;
  }
}

.guideButton {
  width: 350px;
  height: 70px;
  margin-top: 5px;
  margin-left: 20px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;
  font-weight: 400;
  text-align: center;

  border: 1px solid #ffffff;
  border-radius: 10px;

  @media (max-width: 1500px) {
    font-size: 20px;
    width: 300px;
    height: 60px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    width: 136px;
    height: 34px;
    border-radius: 6px;
    margin-top: 10px;
  }
}
