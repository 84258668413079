@import '../../styles/mixins';

.loginError {
  color: #bb1d20;
  font-size: 16px;
}
.formLinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  margin-bottom: 40px;
}

.formLink {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #ffffff;
  cursor: pointer;
}

.checkboxItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: max-content;
  margin: 0 auto;
  cursor: pointer;

  & .checkboxInput {
    display: none;
  }

  & .checkboxInput:checked + .fake::before {
    opacity: 1;
  }

  & .fake {
    height: 18px;
    width: 18px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 2px solid #fff;
    border-radius: 2px;
    display: block;
    margin-right: 10px;
    position: relative;
  }

  & .fake::before {
    content: '';
    position: absolute;
    display: block;
    height: 10px;
    width: 10px;
    background-image: url(../../../public/images/templates/checkbox.svg);
    background-repeat: no-repeat;
    background-size: contain;
    top: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
  }

  & .fakeSpan {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
  }
}
