@import '../../../styles/_mixins.scss';

.pageContainer {
  @include for-desktop-up-min_1860 {
    margin: 10px 56px;
  }
  @include for-desktop-up-max_1860 {
    margin: 10px 56px;
  }
  @include for-desktop-up-max_1440 {
    margin: 10px 40px;
  }
  @include for-tablet-landscape-max_1024 {
    margin: 10px 24px;
  }
}

.imgBg {
  margin: 0;
}

.cookieWrapper {
  width: 950px !important;
  backdrop-filter: blur(15px) !important;
  border-radius: 12px;
  left: calc(50% - 475px) !important;
  bottom: 30px !important;
  align-items: center !important;
  line-height: 24px;

  @include for-tablet-landscape-max_1024 {
    left: calc(50% - 225px) !important;
    width: 450px !important;
    justify-content: center !important;
  }

  @include for-mobile-max_767 {
    bottom: 0 !important;
    width: 100% !important;
    left: 0 !important;
    border-radius: 0px;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
  }

  @include for-mobile-max_480 {
    border-radius: 0 !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    width: 100% !important;
    left: 0 !important;
    font-size: 14px !important;
  }
  div:first-child {
    @include for-mobile-max_480 {
      flex: 1 0 90% !important;
    }
  }
}

.link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.btn {
  border-radius: 6px !important;
  font-size: 16px !important;
  padding: 10px 36px !important;
  background: transparent !important;
  border: 1px solid #fff !important;
  transition: 0.25s !important;
  color: #fff !important;

  &:hover {
    background: #fff !important;
    border: 1px solid #fff !important;
    color: #333 !important;
  }

  @include for-mobile-max_480 {
    font-size: 14px !important;
  }
}
