@import '../../../../../../src/styles/mixins';

.inputGroup {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;

  .searchInput {
    font-family: 'Montserrat';
    width: 100%;
    height: 48px;
    padding: 0 56px;
    background: #ffffff;
    border: 1px solid #efefef;
    border-radius: 12px;
  }

  .searchIcon {
    display: flex;
    align-items: center;
    position: absolute;
    left: 22px;
    bottom: 14px;
  }
}

.filters,
.inlineMobileFilters {
  display: flex;
  align-items: center;
  gap: 16px;
  .datePickerInput {
    position: relative;
    width: 100%;

    @include for-mobile-max_767 {
      flex-direction: column;
    }

    &[data-type='location'] {
      cursor: pointer !important;
    }

    &[data-type='location'] > label[data-shrink='false'] {
      transform: translate(56px, 14px) scale(1) !important;
    }

    & > label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;

      &[data-shrink='false'] {
        transform: translate(14px, 14px) scale(1);
      }
    }

    & > div {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #212121;
      border-radius: 12px;
    }

    & > div > input {
      padding: 12.5px 16px;
    }

    & > div > fieldset {
      border-radius: 12px;
      border: 1px solid #dcdfe2;

      & > legend {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
      }
    }
  }
  .select {
    width: 100%;
    height: 48px;
    background: #ffffff;
    border: 1px solid #efefef;
    font-family: 'Montserrat' !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    border-radius: 12px !important;
  }
  .sortIcon {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
.filters {
  @include for-mobile-max_767 {
    flex-direction: column;
  }
}
.inlineMobileFilters {
  width: 100%;

  .buttonsGroup {
    display: flex !important;
    justify-content: space-between;
    border: 1px solid #efefef;
    background: #fff;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    padding: 9px 10px;
  }
  .filterActions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    @include for-tablet-landscape-max_900 {
      justify-content: space-between;
    }

    .filterIcons,
    .activeIcon {
      width: 20px !important;
      height: 20px !important;
      margin: 5px;
      flex-shrink: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .activeIcon {
      svg > path {
        fill: #18397a !important;
      }
    }
  }
}
