.questionsListBlock {
  .topTopic {
    width: 100%;
  }

  .topicTitle {
    //styleName: Heading/Tab H6 16px-24px-semibold-640;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    padding-left: 16px;
  }

  .questionBlock {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;
    margin-left: 32px;

    .labelBlock {
      display: flex;
      flex-direction: row;
      align-items: center;
      //styleName: Body/Tab Body 16px-24px-normal-400;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #757575;
    }
    .fileButton {
      margin-top: 16px;
      width: 100%;
      text-align: right;
      padding-right: 48px;
      display: flex;
      justify-content: end;
      gap: 12px;
      align-items: center;
    }
  }
}
