.rowWrapper {
  cursor: pointer;
  display: flex;
  align-items: end;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(8px);
  border-radius: 12px;
  margin-bottom: 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  padding: 24px 18px 24px 18px;

  .menuStyle {
    height: 50px;
  }
  .summary {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-left: 20px;
    flex-wrap: wrap;

    .vertical {
      display: flex;
      flex-direction: column;
    }
    .horizontal {
      display: flex;
      flex-direction: row;
    }
    .draftName {
      flex-wrap: nowrap;
    }

    .info {
      display: flex;
      flex-direction: row;
      column-gap: 30px;
    }

    .dataBlock {
      column-gap: 5px;
      .container {
        display: flex;
        column-gap: 10px;
      }
    }
    .dataName {
      min-width: max-content;
    }
    .dataValue {
      color: #757575;
      font-weight: 400;
    }
  }

  .buttonCont {
    display: flex;
    align-items: center;
    gap: 16px;

    > div {
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      flex-grow: 0;
      flex-wrap: nowrap;
      align-items: center;
    }
    .item1 {
      order: 1;
    }
    .item2 {
      order: 2;
    }
  }
}

.shortFormat {
  .summary {
    flex-wrap: wrap;

    .title {
      width: 100%;
    }
  }

  .buttonCont {
    flex-direction: row;
    justify-content: end;

    .payBtn {
      margin-right: 25px;
    }
  }
}
