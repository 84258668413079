.modalBody {
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 676px) {
    overflow-x: hidden;
    height: 100%;
  }
}

.shareOptions {
  margin-top: 10px;

  span {
    font-weight: 500 !important;
  }
}

.shareMessage {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
  margin-bottom: 20px;

  > span {
    font-size: 16px;
    font-weight: 500;
  }

  > div > div {
    border-radius: 12px !important;
  }
}

.buttonField {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.optionButton {
  display: flex;
  justify-content: space-between;
  width: 17%;
  gap: 10px;

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
}

.submitButton {
  font-weight: 700;
  font-size: 16px;
  height: 48px;
  width: 150px;
  padding: 0 20px;
  border-radius: 4px;
  margin-left: 15px;

  @media screen and (max-width: 768px) {
    font-size: 12px !important;
    height: 40px !important;
    padding: 0 12px !important;
    width: 111px !important;
    margin-left: 0 !important;
  }
}

.reactionsModalContainer {
  min-height: 300px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.reactionsModalTabs {
  margin-bottom: 20px;
}

.reactionsList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.reactionsModalReactionItem {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.reactionsListUserRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.reactionsListUserName {
  font-size: 16px;
  font-weight: 500;
}

.reactionsListAvatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.postAction {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}
