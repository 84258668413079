@import '../../../../styles/_mixins.scss';

.rolesMenuSelect {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  color: #fff;

  @include for-mobile-max_767 {
    margin-top: 24px;
    order: 2;
    width: 100vw;
    justify-content: center;
  }
}

.rolesSelectH {
  display: flex;
  align-items: center;
}

// .rolesSelectH > *,
// .selected > span {
//   display: inline-block;
// 	vertical-align: bottom;
// }

.selected {
  display: flex;
  margin-left: 1.5625vw;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.roleMenuLabel {
  font-size: 1.25vw;
  line-height: 40px;
}

.triangle {
  display: block;
  border-radius: 5px;
  padding: 15px;
  position: relative;
  color: #fff;

  &::after {
    content: '';
    top: 18px;
    position: absolute;
    border: 5px solid transparent;
    border-top: 5px solid #a6a6a6;
  }
}

.triangle.open::after {
  top: 14px;
  border: 5px solid transparent;
  border-bottom: 5px solid #a6a6a6;
}

.selectBox {
  position: absolute;
  left: -1vw;
  top: 64px;
  width: 280px;
  padding: 15px 0;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  z-index: 3;
  display: none;

  @include for-mobile-max_767 {
    left: auto;
  }
}

.selectBox.open {
  display: flex;
  flex-direction: column;
}

.selectEl {
  display: flex;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  display: block;

  & > a {
    display: flex;
    align-items: center;
    padding: 15px 30px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
}

.exp {
  background: rgba(10, 178, 171, 1);
}
.inv {
  background: rgba(60, 112, 151, 1);
}
.bsn {
  background: rgba(45, 131, 66, 1);
}
.ind {
  background: rgba(216, 145, 60, 1);
}

.rolesMenuElPrev {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
}

.selectEl .rolesMenuElPrev {
  margin-right: 25px;
}

.label {
  display: block;
}

.signUpPageSelect {
  z-index: 2;

  & .selected {
    &:hover {
      cursor: default;
    }
  }

  & .selectEl {
    cursor: default;
  }

  & .triangle {
    display: none;
  }

  @include for-mobile-max_767 {
    display: none;
  }
}

.scrolled {
  display: none;

  @include for-mobile-max_767 {
    display: flex;
    height: max-content;
    margin-bottom: 32px;
  }
}
