@import '../../../../../../../styles/_mixins.scss';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 30px;

  @include for-mobile-max_480 {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
}

.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;

  & + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
  }

  & + label::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid #757575;
    border-radius: 2px;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 80% 60%;
    cursor: pointer;
  }

  &:checked + label::before {
    background-color: #757575;
    background-image: url(../../../../../../../../public/images/templates/checkbox.svg);
  }

  &:disabled + label::before {
    background-color: #e9ecef;
    cursor: not-allowed;
  }

  //   /* стили при наведении курсора на checkbox */
  // .custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
  //   border-color: #b3d7ff;
  // }
  // /* стили для активного состояния чекбокса (при нажатии на него) */
  // .custom-checkbox:not(:disabled):active+label::before {
  //   background-color: #b3d7ff;
  //   border-color: #b3d7ff;
  // }
  // /* стили для чекбокса, находящегося в фокусе */
  // .custom-checkbox:focus+label::before {
  //   box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  // }
  // /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
  // .custom-checkbox:focus:not(:checked)+label::before {
  //   border-color: #80bdff;
  // }
  // /* стили для чекбокса, находящегося в состоянии disabled */
  // .custom-checkbox:disabled+label::before {
  //   background-color: #e9ecef;
  // }
}

.btn {
  padding: 13px 28px;
  gap: 28px;
  width: 168px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  background: #18397a;
  color: #ffffff;
  border: none;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background: #8c9dbd;
    border: none;
    cursor: not-allowed;
  }
}
