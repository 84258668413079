.modalHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.closeIcon {
  path {
    fill: #fff !important;
    stroke: #fff !important;
  }
}

.title {
  font-size: 3.646vw;
  color: rgba(255, 255, 255, 1);
  font-weight: 800;
  line-height: 122%;
}

.text {
  margin-top: 2.6vw;
  font-size: 2.083vw;
  line-height: 122%;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}
