.startModal {
  .panelBlock {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    .panelItem {
      padding: 32px;
      column-gap: 24px;
      row-gap: 12px;
      border-radius: 20px;
      display: flex;
      flex-direction: column; /* Вертикальная ориентация по умолчанию */
      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.08);

      .itemHeader {
        flex: 1 1 100%;
        //styleName: Heading/Desktop/H5 24px-36px-normal-600;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
      .itemText {
        flex: 1 1 100%;
        //styleName: Text/textBody 16px-24px-normal-400;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
      .itemButton {
        flex: 1 1 auto; /* Установим автоматический расчет высоты */
        max-width: 100%;
      }

      @media (min-width: 768px) {
        flex-direction: row; /* Горизонтальная ориентация для широких экранов */
        align-items: stretch; /* Позволяет элементам выравниваться по высоте */
        flex-wrap: wrap;

        .itemHeader {
          order: 0;
          flex: 1; /* Заголовок занимает часть ширины */
          display: flex;
          align-items: center; /* Центрирование текста по вертикали */
        }

        .itemButton {
          order: 1;
          flex: 0 0 auto; /* Кнопка занимает минимально необходимую ширину */
          align-self: center; /* Кнопка выравнивается по центру */
        }

        .itemText {
          order: 2;
          flex: 1 1 100%; /* Текст переносится на второй ряд */
        }

        /* Установим высоту itemHeader равной высоте кнопки */
        .itemHeader,
        .itemButton {
          min-height: 100%;
        }
      }
    }
  }
}
