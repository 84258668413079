$whiteColor: #fff;
$darkColor: rgba(0, 0, 0, 0.7);
$lightColor: rgba(250, 250, 250, 0.8);
$grayColor: #858585;
$lineHeight: 17px;

.container {
  position: absolute;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: $lineHeight;
  backdrop-filter: blur(40px);

  &.closed {
    opacity: 0;
    pointer-events: none;
    //transition: opacity 0.8s ease-in 0.5s;
  }
}
