.leftSection {
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin-left: 80px;
  }
}

.mainSection_1 {
  position: relative;
  color: #ffffff99;
  font-weight: 800;
  font-size: 200px;
  margin-top: 20px;
  margin-left: 80px;
  margin-bottom: -5px;

  @media (max-width: 1500px) {
    font-size: 160px;
    margin-left: 30px;
  }

  @media (max-width: 1200px) {
    font-size: 140px;
    margin-left: 200px;
    margin-top: 60px;
  }

  @media (max-width: 768px) {
    font-size: 60px;
    margin-left: 90px;
    margin-top: 40px;
  }
}

.mainSection_2 {
  position: relative;
  color: #ffffff66;
  font-weight: 600;
  font-size: 30px;
  margin-top: -150px;
  margin-left: 610px;
  margin-bottom: -10px;

  @media (max-width: 1500px) {
    font-size: 25px;
    margin-top: -130px;
    margin-left: 470px;
  }

  @media (max-width: 1200px) {
    font-size: 25px;
    word-break: break-word;
    width: 150px;
    margin-top: -36px;
    margin-bottom: -135px;
    margin-left: 0;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    width: 90px;
    margin-top: -24px;
    margin-bottom: -100px;
  }
}

.mainSection_3 {
  position: relative;
  color: #ffffff66;
  font-weight: 700;
  font-size: 200px;
  margin-left: 600px;
  margin-top: -50px;

  @media (max-width: 1500px) {
    font-size: 140px;
    margin-left: 460px;
    margin-top: -30px;
  }

  @media (max-width: 1200px) {
    color: #ffffff99;
    font-size: 140px;
    font-weight: 800;
    margin-top: 70px;
    margin-left: 100px;
    padding-bottom: 60px;
  }

  @media (max-width: 768px) {
    font-size: 60px;
    margin-left: 50px;
    padding-bottom: 40px;
  }
}

.stepSection_1 {
  position: relative;
  color: #ffffff99;
  font-weight: 700;
  font-size: 150px;
  line-height: 88.04px;
  width: 380px;
  margin-top: 20px;
  margin-left: 200px;
  margin-bottom: 0;
  text-align: right;

  > span {
    font-size: 116px;
  }

  @media (max-width: 1500px) {
    font-size: 120px;
    line-height: 74.16px;
    margin-left: 40px;

    > span {
      font-size: 90px;
    }
  }

  @media (max-width: 1200px) {
    font-size: 70px;
    font-weight: 800;
    width: 430px;
    margin-left: -20px;

    > span {
      font-size: inherit;
    }
  }

  @media (max-width: 768px) {
    font-size: 30px;
    width: 190px;
    margin-top: -5px;
  }
}

.stepSection_2 {
  position: relative;
  color: #ffffff99;
  font-weight: 600;
  font-size: 50px;
  margin-top: 10px;
  margin-left: 280px;
  text-align: right;

  @media (max-width: 1500px) {
    font-size: 40px;
    line-height: 32px;
    margin-left: 120px;
    margin-top: 5px;
  }

  @media (max-width: 1200px) {
    text-align: left;
    font-size: 20px;
    font-weight: 700;
    line-height: 15.5px;
    margin-top: -60px;
    margin-left: 420px;
    padding-bottom: 50px;
    width: 100px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    margin-left: 175px;
    margin-top: -47px;
    font-weight: 600;
    line-height: 10.5px;
    padding-bottom: 20px;
  }
}

.stepContent {
  color: #ffffff;
  font-size: 25px;
  font-weight: 500;
  line-height: 37.5px;
  width: 100%;
  max-width: 580px;
  margin-left: 60px;

  > h3 {
    margin-bottom: 39px;

    @media (max-width: 1500px) {
      margin-bottom: 30px;
    }

    @media (max-width: 1200px) {
      margin-bottom: 20px;
    }
  }

  li {
    margin-bottom: 30px;

    @media (max-width: 1500px) {
      margin-bottom: 20px;
    }

    @media (max-width: 1200px) {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 1500px) {
    max-width: 480px;
    font-size: 20px;
  }

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    margin-left: 25px;
    margin-right: 25px;
    width: unset;
    font-size: 12px;
    line-height: 20px;
  }
}
