.textDisplay {
  padding: 0;
  margin: 0;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.expanded {
  white-space: normal;
}

.actions {
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.showMoreBtn,
.showLessBtn {
  color: #18397a;
  text-decoration: underline;
  border: none;
  padding: 4px 5px;
  cursor: pointer;
  font-weight: 500;
}
