@import '../../../../../src/styles/mixins';

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include for-mobile-max_767 {
    flex-wrap: wrap;
    gap: 12px;
  }
  .inputGroup {
    position: relative;
    width: calc(80% - 10px);
    @include for-mobile-max_767 {
      width: 100%;
    }
    .searchInput {
      font-family: 'Montserrat';
      width: 100%;
      height: 48px;
      padding: 0 56px;
      background: #ffffff;
      border: 1px solid #efefef;
      border-radius: 12px;
    }

    .searchIcon {
      display: flex;
      align-items: center;
      position: absolute;
      left: 22px;
      bottom: 14px;
    }
  }

  .sortSelect {
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 48px;
    max-height: 48px;
    justify-content: space-between;
    background: #fff;
    border-radius: 12px;
    width: calc(20% - 10px);
    @include for-mobile-max_767 {
      width: 100%;
    }
  }
  .filterIcons {
    width: 20px;
    height: 20px;
    margin: 5px;
    flex-shrink: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .filterNewFirst {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    text-transform: none;
    text-decoration: none;
    padding: 0;
    margin-right: 10px;
    min-width: 80px;
  }

  .selectArrow {
    width: 25px;
    height: 100%;
    display: flex;
    margin-right: 10px;

    svg {
      path {
        fill: #757575 !important;
      }
    }
  }
}

.noPost {
  padding: 24px 60px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #ffffff;
  gap: 4px;

  .mainMessage {
    font-family: 'Montserrat';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  .subMessage {
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
