@import '/src/styles/mixins';

.contentBox {
  overflow-x: hidden;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.fixedTopElement {
  top: 0;
  position: sticky;
  width: 100%;
}

.contentBox::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.middleContent {
  width: 1020px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1.2% !important;
  margin-right: 1.2% !important;

  @media only screen and (min-width: 1025px) {
    min-width: 545px;
  }

  @include for-tablet-landscape-max_1024 {
    width: 100%;
    padding-bottom: 40px;
  }
  /* @include for-desktop-up-max_1660 {
    display: block;
    max-width: 50vw;
  } */
}
.invite {
  margin-bottom: 4%;
}
.newPost {
  width: 100%;
  margin-bottom: 16px;
}

.upButton {
  position: fixed;
  bottom: 25px;
  right: calc(((100% - 1600px) / 2) + 415px);
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  cursor: pointer;
  border: 1px solid #efefef;
  z-index: 20;

  @media screen and (max-width: 1690px) {
    right: 485px;
  }

  @media screen and (max-width: 1580px) {
    right: calc(((100% - 1600px) / 2) + 250px);
  }

  @media screen and (max-width: 1385px) {
    right: 150px;
  }

  @media screen and (max-width: 1150px) {
    right: 130px;
  }

  @media screen and (max-width: 1025px) {
    right: 10px;
    bottom: 52px;
  }
}
