@import '/src/styles/mixins';

.middleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  gap: 4px;

  @include for-mobile-max_767 {
    padding-bottom: 15px;
    order: -1;
    width: 100%;
  }
}

.nameBLock {
  position: relative;
}

.name {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #212121;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @include for-mobile-max_480 {
    width: 200px;
  }
}

.successKyc,
.pendingKyc {
  position: absolute;
  top: -4px;
  right: -22px;
  border-radius: 8px;

  font-weight: 700;
  font-size: 7px;
  line-height: 9px;
  color: #ffffff;
  padding: 2px;
}

.successKyc {
  background: #4caf50;
}

.pendingKyc {
  background: #757575;
}

.position {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #808080;
}

.avatar {
}

.invitedBy {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.inviterName {
  text-decoration: underline;
  font-weight: 600;
  // cursor: pointer;
}

.countryFlag {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
  width: 90%;
  margin-top: 5px;
  font-size: 26px;
}

.flagWrapper {
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }
}
