.draftName {
  max-width: calc(300px - 32px);
  flex-wrap: nowrap;
  display: flex;
  flex-direction: row;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  width: fit-content;
  &.isEditing {
    width: 100%;
  }
  .editButton{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
  .rename {
    width: fit-content;
    border: none;
    border-bottom: #212121 1px solid;
    &:focus-visible {
      outline: none;
      border-bottom: #212121 1px solid;
    }
    &.isEditing {
      width: 100%;
    }
  }
  .name {
    margin-right: 12px;
    margin-bottom: 1px;
  }
  .title {
    font-family: 'Montserrat';
    color: #212121;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
