.howLeftSection {
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin-left: 80px;
  }
}

.howMain_1 {
  position: relative;
  color: #ffffff;
  font-weight: 800;
  font-size: 250px;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: -20px;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1200px) {
    font-size: 120px;
  }

  @media (max-width: 768px) {
    font-size: 70px;
  }
}

.howMain_2 {
  position: relative;
  color: #ffffffea;
  font-weight: 600;
  font-size: 90px;
  margin-left: 390px;
  margin-top: -120px;
  margin-bottom: -40px;
  z-index: 12;

  @media (max-width: 1200px) {
    font-size: 50px;
    margin-left: 120px;
    margin-top: -10px;
    margin-bottom: -30px;
  }

  @media (max-width: 768px) {
    font-size: 25px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 100px;
  }
}

.howMain_3 {
  position: relative;
  color: #ffffff;
  font-weight: 700;
  font-size: 170px;
  margin-left: 380px;
  margin-top: -20px;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1200px) {
    font-size: 110px;
    margin-left: 120px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    font-size: 60px;
    margin-left: 10px;
  }
}

.howStepLeft_1 {
  position: relative;
  color: #ffffff;
  font-weight: 800;
  font-size: 180px;
  margin-top: 20px;
  margin-left: 140px;
  margin-bottom: -20px;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1500px) {
    font-size: 160px;
    margin-left: 50px;
  }

  @media (max-width: 1200px) {
    font-size: 70px;
    margin-top: 0;
    margin-left: 0;
    padding-top: 10px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
  }
}

.howStepLeft_2 {
  position: relative;
  color: #ffffffea;
  font-weight: 600;
  font-size: 100px;
  margin-left: 320px;
  margin-top: -80px;
  margin-bottom: -10px;
  z-index: 12;

  @media (max-width: 1500px) {
    font-size: 80px;
    margin-left: 210px;
    margin-top: -50px;
  }

  @media (max-width: 1200px) {
    font-size: 25px;
    margin-top: -40px;
    margin-bottom: 10px;
    margin-left: 170px;
  }

  @media (max-width: 768px) {
    margin-left: 75px;
    margin-top: -7px;
    margin-bottom: 12px;
    font-size: 13px;
  }
}

.howStepLeft_3 {
  position: relative;
  color: #ffffff;
  font-weight: 700;
  font-size: 50px;
  margin-left: 330px;
  margin-top: -20px;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1500px) {
    font-size: 40px;
    margin-left: 210px;
  }

  @media (max-width: 1200px) {
    font-size: 30px;
    margin-left: 170px;
    padding-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin-left: 75px;
    font-size: 15px;
    padding-bottom: 15px;
  }
}

.howStepContentWrapper {
  color: #ffffffea;
  font-weight: 500;
  font-size: 25px;
  width: 100%;
  max-width: 580px;
  line-height: 37.5px;
  margin-left: 60px;

  @media (max-width: 1500px) {
    max-width: 450px;
    font-size: 20px;
  }

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    margin-left: 25px;
    margin-right: 25px;
    width: unset;
    font-size: 12px;
    line-height: 20px;
  }
}
