@import '/src/styles/mixins';

.menuBtn {
  width: 3.33px !important;
  height: 13.33px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-right: 10px;
}
