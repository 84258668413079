@import '../../../styles/_mixins.scss';

.contentBox {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 24px 60px;
  border-radius: 12px;
  margin-bottom: 20px;

  @include for-mobile-max_767 {
    padding: 20px;
  }
}

.emptyText {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
