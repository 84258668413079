@import '../../../styles/_mixins.scss';

.imageBg {
  height: 100vh;
  overflow-x: auto;
}

.sectionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 0 199px;
  height: calc(100% - 162px);

  @include for-desktop-up-max_1440 {
    padding: 0 70px;
  }

  @include for-mobile-max_767 {
    padding: 0 20px;
    gap: 20px;
  }
}

.title {
  max-width: 700px;

  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 50px;
  line-height: 60.95px;
  color: #ffffffb2;
  text-align: center;
  text-transform: uppercase;
  margin-left: 0;
  margin-top: 60px;
  margin-bottom: 70px;

  @include text-selection-enabled;

  @include for-desktop-up-max_1440 {
    margin: 10px 0 0 0;

    font-size: 25px;
    line-height: 21.5px;
  }
}

.subTitle {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #ffffff;

  @include text-selection-enabled;

  @include for-desktop-up-max_1440 {
    margin-bottom: 0;

    font-size: 18px;
    line-height: 24px;
  }

  @include for-mobile-max_767 {
    font-size: 10px;
    line-height: 15px;
  }
}
