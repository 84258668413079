@import '../../../../styles/_mixins.scss';

.chartWrapper {
  position: relative;
  height: unset;
  width: 50%;
  margin: unset;
  display: flex;
  justify-content: flex-end;
}

.chartWrapperGoalPage {
  width: 30%;
  align-items: flex-start;
}

.secondChartWrapper {
  position: absolute;
}

.circularBarplot {
  width: 640px;
  height: 640px;

  @include for-desktop-up-max_1860 {
    width: 300px;
    height: 300px;
  }

  .shadowedBaseSheet {
    circle {
      cx: 0;
      cy: 0;
    }

    #gradient {
      cx: 50%;
      cy: 50%;
    }

    .scoreCircle {
      // filter: drop-shadow(0px 4px 6px #dcdcdc);
      fill: #fefefe;
    }

    .scoreText {
      fill: #555;
      text-anchor: middle;
      dominant-baseline: central;
      font-size: 24px;

      &.bold {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }

  .textLabelsSheet {
    text {
      fill: #000;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .chartGrid {
    circle {
      fill: none;
    }
  }
}

.mobile {
  @include for-desktop-up-max_1440 {
    width: 640px;
    height: 640px;
  }

  @include for-mobile-max_767 {
    width: 300px;
    height: 300px;
  }
}

.mobileWrapper {
  justify-content: center;
  width: 35%;

  @include for-desktop-up-max_1440 {
    height: 640px;
  }

  @include for-mobile-max_767 {
    width: 300px;
    height: 300px;
  }
}

.iconsWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.iconWrapper {
  position: absolute;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.active {
  transform: scale(1.2);
}
