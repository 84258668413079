.container {
  width: 100%;
  position: relative;
  padding-top: 7.5%;

  .backgroundImage {
    width: 100%;
  }

  .avatar {
    width: calc((100% * 232) / 1024);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .name {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    text-align: center;
    color: #ffffff;
    position: absolute;
    width: 100%;
    bottom: 20%;

    @media screen and (max-width: 1200px) {
      font-size: 28px;
      line-height: 36px;
    }

    @media screen and (max-width: 540px) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  .titleBlock {
    position: absolute;
    top: 18.5%;
    left: 2%;
    max-width: 33%;
    border-left: 7px solid #ffffff;

    @media screen and (max-width: 750px) {
      border-left-width: 3px;
      left: 2%;
    }

    .title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      color: #ffffff;
      padding-top: 15px;
      padding-left: 15px;

      @media screen and (max-width: 1500px) {
        font-size: 16px;
        line-height: 20px;
      }

      @media screen and (max-width: 1220px) {
        font-size: 11px;
        line-height: 16px;
        padding-top: 12px;
        padding-left: 12px;
      }

      @media screen and (max-width: 1024px) {
        font-size: 20px;
        line-height: 28px;
      }

      @media screen and (max-width: 880px) {
        font-size: 14px;
        line-height: 21px;
      }

      @media screen and (max-width: 750px) {
        font-size: 11px;
        line-height: 16px;
      }

      @media screen and (max-width: 550px) {
        padding-top: 4px;
        padding-left: 8px;
        font-size: 9px;
        line-height: 12px;
      }

      @media screen and (max-width: 480px) {
        padding-top: 4px;
        padding-left: 6px;
        font-size: 6px;
        line-height: 9px;
      }
    }
  }
}
