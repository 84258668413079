@import '../../styles/mixins';

.menu * {
  text-transform: none !important;
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
  padding-bottom: 20px;

  .tabs {
    width: 100%;
    height: 56px;

    background: #ffffff;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
    border-radius: 12px;

    > div > div {
      height: 56px;
    }

    .tabItem {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-transform: capitalize;
      color: #212121;

      @media screen and (max-width: 1340px) {
        margin: 0 10px;
      }
    }

    .activeItem {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 32px;
      color: #143471 !important;
    }
  }

  .subscriptionBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 750px) {
      flex-direction: column;
      gap: 10px;
    }

    .updatePaymentBtn {
      @media screen and (max-width: 750px) {
        width: 100%;
        max-width: 100%;
      }
    }

    .paymentMethodBlock {
      display: flex;
      align-items: center;
      gap: 12px;

      .title {
        color: #212121;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }

      .paymentDescriptionBlock {
        display: flex;
        align-items: center;
        gap: 8px;

        .description {
          color: #212121;
          font-family: 'Montserrat', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    .updateButton {
      border-radius: 4px;
      background: #18397a;
      padding: 0 20px;
      border: 0;

      color: #ffffff;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      text-transform: uppercase;
    }
  }
}

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  > div:nth-child(2) {
    flex-shrink: 0;

    @media screen and (max-width: 620px) {
      flex-shrink: 1 !important;
      width: 100%;
    }
  }

  @media screen and (max-width: 1280px) {
    gap: 12px;
  }

  @media screen and (max-width: 620px) {
    flex-direction: column;
  }

  .searchFilter {
    background-color: #ffffff;
    border-radius: 12px;
    width: 100%;
  }

  .topSelect {
    width: 100%;
    min-width: 250px;
    max-width: 292px;

    @media screen and (max-width: 620px) {
      max-width: 100% !important;
      width: 100% !important;
      min-width: 100% !important;
    }
  }

  .topMenuItem {
    font-family: 'Montserrat', sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    text-transform: capitalize;
  }

  .sort {
    min-width: 100px;
    width: 100%;
    max-width: 110px;
    display: flex;
    justify-content: flex-end;

    > div {
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      margin-right: 10px;

      span {
        white-space: nowrap;
      }
    }
  }
}

.caffeeTab {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.titleBlock {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 6px;

  > h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
  }

  > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
}

.container {
  width: 100%;
  padding: 24px 40px;
  border-radius: 16px;
  gap: 20px;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1280px) {
    padding: 16px 28px;
  }

  .header {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    margin-right: 8px;
  }
}

.emptyDesc {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  > a {
    color: #18397a;
    text-decoration: underline;
  }
}
