@import '../../../src/styles/mixins';

.pageContent {
  width: 100%;
  background-color: #ffffff;
  padding: 24px 60px;
  @include for-tablet-landscape-max_1100 {
    padding: 20px 40px;
  }
  @include for-mobile-max_767 {
    padding: 12px 20px;
  }
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .mainTitle {
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 36px !important;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 400px;
    @include for-desktop-up-max_1300 {
      max-width: 300px;
    }
    @include for-mobile-max_480 {
      max-width: 215px;
    }
  }
  .headerActions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .invitationNumber {
      display: flex;
      align-items: center;
      gap: 8px;

      .backButton {
        cursor: pointer;
      }
    }
    .withdrawBtn {
      padding-right: 0 !important;
      display: flex;
      gap: 12px;
      align-items: center;
      white-space: nowrap;
    }
  }

  .infoItem {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    justify-content: space-between;

    .infoItemTitle {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }
    .infoItemValue,
    .wrapValue,
    .infoItemValueStatus {
      max-width: 70%;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      @include for-mobile-max_767 {
        max-width: 220px;
      }
    }
    .infoItemValueStatus {
      font-weight: 600;
      text-transform: uppercase;
    }
    .wrapValue {
      @include for-mobile-max_767 {
        flex-direction: column;
        max-width: unset;
      }
    }
    @include for-mobile-max_767 {
      flex-wrap: wrap;
    }
  }
}
.buttons {
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sendBtn {
    display: flex;
    gap: 15px;
  }
}
