@import '../../../styles/_mixins.scss';

.pageContentWrapper {
  display: flex;
  gap: 8vw;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin-bottom: 40px;

  @include for-desktop-up-max_1440 {
    flex-direction: column;
    height: unset;
    align-items: center;
  }
}

.sectionWrapper {
  width: 50%;
  display: flex;
  flex-direction: column;

  @include for-desktop-up-max_1860 {
    width: 80%;
    align-items: center;
  }

  @include for-mobile-max_767 {
    width: 90%;
  }

  @include for-mobile-max_480 {
    width: 100%;
  }
}

.secondWrapper {
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @include for-mobile-max_480 {
    padding: 12px 24px;
  }
}

.descriptionWrapper {
  padding: 40px 32px;
  margin-bottom: 32px;
  max-width: 656px;

  border-radius: 16px;
  background: rgba(0, 0, 0, 0.32);
  backdrop-filter: blur(16px);

  @include text-selection-enabled;

  @include for-desktop-up-max_1860 {
    max-width: none;
  }

  @include for-mobile-max_480 {
    border-radius: 0;
    background: none;
    backdrop-filter: none;
    padding: 0;
  }
}

.mobileWrapper {
  width: 70%;

  & .secondWrapper {
    max-width: 90%;

    & .descriptionWrapper {
      max-width: none;
    }

    & .title {
      font-size: 24px;
      line-height: 36px;
    }

    & .subTitle {
      font-size: 18px;
      line-height: 26px;
    }

    & .paragraph {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.title,
.subTitle {
  margin-bottom: 20px;

  font-weight: 600;

  color: rgba(255, 255, 255, 0.8);
}

.title {
  font-size: 32px;
  line-height: 48px;

  @include for-desktop-up-max_1860 {
    font-size: 24px;
    line-height: 36px;
  }

  @include for-mobile-max_480 {
    font-size: 18px;
    line-height: 24px;
  }
}

.subTitle {
  font-size: 24px;
  line-height: 36px;

  @include for-desktop-up-max_1860 {
    font-size: 18px;
    line-height: 26px;
  }

  @include for-mobile-max_480 {
    font-size: 16px;
    line-height: 20px;
  }
}

.paragraph {
  margin-bottom: 12px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;

  &:last-child {
    margin-bottom: 0;
  }

  @include for-desktop-up-max_1860 {
    font-size: 14px;
    line-height: 20px;
  }

  @include for-mobile-max_480 {
    font-size: 12px;
    line-height: 18px;
  }
}

.btn {
  align-self: end;
  padding: 0px 20px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  border: 2px solid #ffffff;
  background: transparent;
  color: #ffffff;

  &:disabled {
    background: lightgray;
    color: grey;
    border: 2px solid grey;
  }

  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.8);
  }

  @include for-mobile-max_480 {
    font-size: 14px;
    line-height: 32px;
  }
}
