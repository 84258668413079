@import '../../../../styles/_mixins.scss';

.pageContentWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;

  @include for-desktop-up-max_1440 {
    flex-direction: column;
    align-items: center;
  }
}
