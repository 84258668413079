@import '../../../../styles/_mixins.scss';

.sectionsWrapper {
  height: 100vh;
}

.stepWrapper {
  position: absolute;
  bottom: 2.7vw;
  right: 2.7vw;
  display: flex;
  flex-direction: column;
}

.rolePageContent {
  display: none;
  height: 80vh;
  margin-left: 30px;
  margin-top: 30px;
}

.active {
  display: flex;
  flex-wrap: wrap;
}

.roleTitleLeft {
  display: flex;
  align-items: flex-start;
  margin-left: 6.375vw;
  margin-top: 14vh;

  & > p {
    color: hsla(0, 0%, 100%, 0.6);
    font-size: 2.5vw;
    font-weight: 500;
    text-transform: uppercase;
    transform: rotate(-90deg);

    @include for-mobile-max_767 {
      font-size: 3.5vw;
    }

    @include for-mobile-max_480 {
      margin-left: -18vw;
      color: hsla(0, 0%, 100%, 0.3);
      font-size: 8vw;
    }
  }

  @include for-mobile-max_480 {
    margin-left: 0;
    width: 8vw;
  }
}

.content {
  margin-top: 20px;
  color: #fff;
  font-size: 1.344vw;
  font-weight: 500;
  height: auto;
  line-height: 160%;
  margin-left: 7vw;
  max-width: 52vw;
  padding: 0;

  @include text-selection-enabled;

  @include for-mobile-max_767 {
    font-size: 2.3vw;
  }

  @include for-mobile-max_480 {
    margin-top: 0;
    max-width: 58vw;
    font-size: 12px;
  }

  & > h3 {
    margin-bottom: 2vw;
  }

  & > p {
    margin-bottom: 1.62vw;
  }

  & > ul {
    margin-left: 2.25vw;

    & > li {
      list-style-type: none;
    }
  }
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.signUpBtn {
  background-color: #ffffff00;
  border: 2px solid #fff;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-size: 1.29vw;
  font-weight: 400;
  margin: 1vw auto auto;
  padding: 0.914vw 0;
  width: 18.8172vw;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    background: #fff;
    border: 1px solid #fff;
    color: #333;
  }

  @include for-mobile-max_767 {
    font-size: 2.3vw;
    width: 30vw;
  }

  @include for-mobile-max_480 {
    font-size: 3.75vw;
    padding: 2.5vw 0;
    width: 42.5vw;
  }
}
