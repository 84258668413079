@import '../../../../../src/styles/mixins';

.filters {
  display: flex;
  align-items: center;
  gap: 12px;

  @include for-mobile-max_620 {
    flex-direction: column;
    gap: 12px;
  }
  .inputGroup {
    position: relative;
    width: 100%;
    .searchInput {
      font-family: 'Montserrat';
      width: 100%;
      height: 48px;
      padding: 0 56px;
      background: #ffffff;
      border: 1px solid #efefef;
      border-radius: 12px;
    }

    .searchIcon {
      display: flex;
      align-items: center;
      position: absolute;
      left: 22px;
      bottom: 14px;
    }
  }

  .sortSelect {
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 48px;
    max-height: 48px;
    justify-content: space-between;
    background: #fff;
    border-radius: 12px;
    width: 270px;
    @include for-mobile-max_620 {
      width: 100%;
    }
  }
  .filterIcons {
    width: 20px;
    height: 20px;
    margin: 5px;
    flex-shrink: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .filterNewFirst {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    text-transform: none;
    text-decoration: none;
    padding: 0;
    margin-right: 10px;
    min-width: 80px;
  }

  .selectArrow {
    width: 25px;
    height: 100%;
    display: flex;
    margin-right: 10px;

    svg {
      path {
        fill: #757575 !important;
      }
    }
  }

  .filterActions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    @include for-tablet-landscape-max_900 {
      justify-content: space-between;
    }

    .filterIcons,
    .activeIcon {
      width: 20px !important;
      height: 20px !important;
      margin: 5px;
      flex-shrink: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .activeIcon {
      svg > path {
        fill: #18397a !important;
      }
    }
  }

  .buttonsGroup {
    display: flex !important;
    justify-content: space-between;
    background: #fff;
    height: 100%;
    width: 104px;
    border-radius: 12px;
    padding: 9px 10px;

    @include for-mobile-max_620 {
      width: 100%;
    }
  }
}

.filtersRight {
  display: flex;
  gap: 12px;

  @include for-mobile-max_620 {
    width: 100%;
  }
}
