.inputWrapper {
  position: relative;
  margin-bottom: 20px;
}

.formInput {
  background: transparent;
  border-width: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  padding: 8px 0;
  outline: none;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;

  &::-webkit-input-placeholder {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.6);
  }

  &::-moz-placeholder {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.6);
  }

  &:-ms-input-placeholder {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.6);
  }

  &::-ms-input-placeholder {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.6);
  }

  &::placeholder {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.6);
  }

  &.isInvalid {
    border-bottom: 1px solid #c73131;
  }
}

.formPassShowBtn {
  position: absolute;
  top: 14px;
  right: 0;
  width: 22px;
  height: 16px;
  border: none;
  background: none;

  .iconWrapper {
    position: relative;
    width: 100%;
    height: 100%;

    > span {
      width: 22px;
      height: 16px;

      > svg > path {
        fill: #ffffff !important;
      }
    }
  }
}

.safariEyeIcon {
  right: 30px;
}

.successText {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  white-space: pre-line;
}
