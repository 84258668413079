.slider {
  max-height: 400px !important;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row !important;
  align-items: center;
  gap: 5px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
  }
}

.leftImg {
  display: flex;
  justify-content: center;
  flex-direction: column !important;
  align-items: center;
  width: 33%;
  float: right;
  max-height: 400px !important;
  background-size: cover !important;
  position: relative;

  > img {
    height: unset !important;
  }
}

.showMoreImg {
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  gap: 5px;

  > span {
    @media screen and (max-width: 520px) {
      display: none;
    }
  }
}

.rightImg {
  height: 100%;
  max-height: 400px !important;
  float: left;
  background-size: cover !important;

  @media screen and (max-width: 635px) {
    //width: 50%;
  }
}

.singleImageContainer {
  width: 100%;
  background: #d0d0d0;
  display: flex;
  align-items: center;
  position: relative;
}

.bgImage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  z-index: 0;
  background-size: contain;
  filter: blur(15px);
}

.image,
.singleImage {
  height: 100%;
  max-height: 400px !important;
  background-size: cover !important;
  cursor: pointer;
  z-index: 1;
}

.image {
  width: 100%;
}

.right_arrow {
  position: absolute !important;
  top: 50% !important;
  right: 20px !important;
  z-index: 33333 !important;
  font-size: 20px !important;
  border-radius: 50% !important;
  padding: 10px !important;

  @media screen and (max-width: 660px) {
    right: 2px !important;
  }
}

.left_arrow {
  position: absolute !important;
  top: 50% !important;
  left: 20px !important;
  z-index: 33334 !important;
  font-size: 20px !important;
  border-radius: 50% !important;
  padding: 10px !important;

  @media screen and (max-width: 660px) {
    left: 2px !important;
  }
}

.slide {
  opacity: 0;
  transition-duration: 1s;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

.modal {
  width: 90vw;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  outline: none !important;

  @media screen and (max-width: 660px) {
    width: 80vw;
    height: auto;
  }
}

.modalImage {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  background-size: cover;
  cursor: pointer;
}

.modalBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalClose {
  position: absolute !important;
  top: 20px !important;
  right: 20px !important;
  border-radius: 50% !important;
  padding: 10px !important;
  margin: 0;

  @media screen and (max-width: 660px) {
    right: 10px !important;
  }
}
