@import '../../../../styles/_mixins.scss';

.nameColumn,
.rolesColumn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.answersColumn {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.answerItems {
  width: calc(100% + 40px);
  height: calc(100% + 24px);
  margin: -12px -20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    width: 740px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #efefef;
    min-height: 150px;

    > p {
      width: 390px;
      overflow: hidden;
      word-break: break-all;
      padding: 15px 20px;
    }

    > div {
      width: 160px;
      height: 100%;
      border-left: 1px solid #efefef;
      padding: 15px 20px;
      overflow: hidden;
      word-break: break-all;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.answerFiles {
  a {
    display: block;
    color: #18397a;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 15px;
  }
}

.answerLinks {
  a {
    display: block;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 15px;
  }
}

.rolesCollumnWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;

  > span {
    text-transform: capitalize;
  }
}

.idColumn {
  font-family: 'Montserrat', sans-serif !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tabsContainer {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;

  > div:first-child {
    border-bottom: 1px solid #c4c4c4;
  }
}

.secondTabs > div > span {
  display: none !important;
}

.tabs {
  width: 100%;
  height: 56px;

  padding-top: 4px;

  @media screen and (max-width: 1050px) {
    display: none !important;
  }

  .tabItem {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #212121;
    padding: 0;
    min-width: unset !important;
    margin: 0 20px !important;

    @media screen and (max-width: 1340px) {
      margin: 0 10px;
    }
  }

  .activeItem {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: #143471 !important;

    @include for-desktop-up-max_1500 {
      margin: 5px 10px;
    }
  }
}

.tableContent,
.emptyContainer {
  width: 100%;
  padding: 24px 60px;
  background: #ffffff;
  border-radius: 12px;
  margin-top: 20px;
  margin-bottom: 60px;

  @include for-mobile-max_767 {
    padding: 24px 20px;
  }

  .tableTitle {
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;

      > button > div > span {
        @include for-mobile-max_767 {
          display: none;
        }
      }

      @include for-mobile-max_767 {
        gap: 10px;
      }
    }
  }

  .filters {
    display: flex;
    justify-content: flex-start;
    gap: 10px;

    @include for-desktop-up-max_1500 {
      flex-wrap: wrap;
      margin-top: 0;
    }

    @include for-tablet-landscape-max_1024 {
      justify-content: space-around;
      margin-right: 0;
    }

    .searchFilter {
      // @include for-desktop-up-max_1500{
      //   margin-top: 32px;
      // }

      @media screen and (max-width: 966px) {
        width: 100% !important;
        margin-right: 0;
      }

      width: 408px;

      @include for-tablet-landscape-max_1024 {
        width: 538px;
      }

      position: relative;
      height: 48px;
      // margin: 0px 10px 0px 0px;

      .searchFilterIcon {
        position: absolute;
        left: 22px;
        top: 14px;
        z-index: 10;
      }

      .searchFilterInput {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding-left: 56px;
        width: 100%;
        background: #ffffff;
        border: 1px solid #efefef;
        border-radius: 12px;
        height: 100%;

        input {
          ::placeholder {
            font-weight: 400;
            font-size: 16px;
            color: #757575;
          }
        }
      }
    }
  }
}

.emptyContainer {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
}

.filterSelect {
  // @include for-desktop-up-max_1500{
  //   margin: 32px 0px 0px 0px !important;
  // }

  @media screen and (max-width: 966px) {
    width: 100% !important;
  }

  // margin: 0px 10px;
  width: 257px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 12px !important;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.menuItem {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.filterSelectPlaceholder {
  font-weight: 400;
  font-size: 16px;
  color: #555555;
}

.pagination {
  justify-content: center;
}

.paginationItem {
  background: #ffffff;
  border: 1px solid #efefef !important;
  border-radius: 12px !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.topMenuItem {
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-transform: capitalize;
}

.topSelect {
  display: none !important;
  line-height: 24px !important;
  margin-top: 12px !important;
  background: #ffffff !important;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px !important;
  width: 100% !important;
  height: 48px !important;
  border: none !important;

  span {
    font-family: 'Montserrat', sans-serif !important;
    color: #18397a !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
  }

  @media screen and (max-width: 1050px) {
    display: flex !important;
  }
}

.primaryButton {
  padding: 12px 20px;
  margin-left: auto !important;
  margin-right: 0;
  height: 48px;
}

.defaultButton {
  padding: 12px 20px;
  margin-left: auto;
  margin-right: 0;
  height: 48px;
}

.buttonWithImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.addBlockBtn {
  position: absolute;
  right: 0;
  padding: 12px 20px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  height: 48px;
}

// Add block component

.addQuestion {
  position: relative;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 24px 60px;
  margin: 20px 0;

  @media screen and (max-width: 768px) {
    padding: 24px 10px;
  }
}

.pageTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.formActions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.leftButton {
  padding: 0 20px !important;
  border: 1px solid #18397a !important;
  border-radius: 4px !important;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  color: #18397a !important;
}

.rightButton {
  padding: 0 20px !important;
  background: #18397a !important;
  border-radius: 4px !important;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  text-transform: uppercase;
  color: #ffffff !important;
}

.questionBlock {
  margin-bottom: 20px;
}

.questionInput {
  border-radius: 12px !important;
  font-size: 16px;

  > div {
    border-radius: 12px !important;
  }
}

.blockSelect {
  width: 100%;
  height: 48px;
  max-width: calc(50% - 10px);

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.textAreaWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 12px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.blockTextArea {
  width: 49%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.answerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  border-bottom: 2px dashed #c4c4c4;
  padding: 20px 0;

  > div {
    width: 50%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &:first-child {
    border-top: 2px dashed #c4c4c4;
  }

  &:last-child {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.answerTextArea {
  width: calc(50% - 10px) !important;

  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}

.addFileBtn {
  margin: 10px 0;
  padding: 12px 20px;
  height: 48px;
}

.uploadImgClose {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: none;
  text-align: center;
  z-index: 1;
  cursor: pointer;

  > img {
    width: 15px;
    height: 15px;
  }
}

.filePreview {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
}

.modalEditInputWrapper {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
