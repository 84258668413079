@import '../../../styles/_mixins.scss';

.navItem span {
  color: #000000;

  svg > path {
    fill: #000000 !important;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  padding: 20px 55px 30px;

  @include for-desktop-up-max_1440 {
    padding: 20px 55px;
  }

  @include for-desktop-up-max_1200 {
    padding: 20px;
  }
}

.headerLeftContainer {
  display: flex;
  align-items: flex-end;
  height: 424px;
  @include for-desktop-up-max_1440 {
    height: 256px;
  }
  @include for-mobile-max_480 {
    height: 131px;
  }
}

.headerTitle {
  text-align: right;
  font-weight: 600;
  line-height: 236px;
  font-size: 100px;
  color: #ffffff;
  @include for-desktop-up-max_1440 {
    font-size: 70px;
    line-height: 170px;
    text-align: left;
  }
  @include for-desktop-up-max_1200 {
    font-size: 50px;
    line-height: 150px;
    text-align: left;
  }
  @include for-mobile-max_620 {
    font-size: 35px;
  }
  @include for-mobile-max_480 {
    font-size: 20px;
    line-height: 60px;
  }
}

.headerTitleFirstChar {
  font-size: 290px;
  letter-spacing: -20px;
  @include for-desktop-up-max_1440 {
    font-size: 180px;
  }
  @include for-desktop-up-max_1200 {
    font-size: 140px;
  }
  @include for-mobile-max_620 {
    font-size: 110px;
  }
  @include for-mobile-max_480 {
    font-size: 72px;
    letter-spacing: -10px;
  }
}

.headerTitleSecondChar {
  font-weight: 600;
  font-size: 100px;
  @include for-desktop-up-max_1440 {
    font-size: 70px;
  }
  @include for-desktop-up-max_1200 {
    font-size: 50px;
  }
  @include for-mobile-max_620 {
    font-size: 35px;
  }
  @include for-mobile-max_480 {
    font-size: 20px;
  }
}

.headerTitleSecondPart {
  color: #46393b;
  font-weight: 600;
  font-size: 100px;
  line-height: 236px;
  display: flex;
  height: 100%;
  align-items: flex-end;
}

.logoContainer {
  height: 100%;
}

.logo {
  position: relative;
  width: 348px;
  height: 80px;
  z-index: 10;

  @include for-mobile-max_767 {
    width: 180px;
    height: 40px;
  }
  @include for-desktop-up-max_1440 {
    width: 304px;
    height: 70px;
  }

  @include for-desktop-up-max_1200 {
    width: 216px;
    height: 50px;
  }

  @include for-mobile-max_767 {
    width: 180px;
    height: 40px;
  }
}

.withoutZIndex {
  z-index: 1;
}

.logoImg {
  width: 100%;
  height: 100%;
}

.headerNav {
  display: flex;
  z-index: inherit;
}
.titleBackground {
  background-image: url('../../../../public/images/section-header-bckg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  padding: 0 138px;

  .title {
    font-family: Montserrat;
    font-size: 100px;
    font-weight: 700;
    color: #ffffff;
    width: fit-content;
    height: fit-content;
    .restOfTitle {
      font-size: 56px;
      font-weight: 600;
    }
  }

  @include for-desktop-up-max_1440 {
    height: 100px;
    padding: 0 56px;

    .title {
      font-size: 80px;

      .restOfTitle {
        font-size: 44px;
      }
    }
  }

  @include for-desktop-up-max_1200 {
    height: 80px;
    padding: 0 24px;

    .title {
      font-size: 68px;

      .restOfTitle {
        font-size: 40px;
      }
    }
  }

  @include for-mobile-max_767 {
    height: 67px;
    .title {
      font-size: 40px;
      font-weight: 600;

      .restOfTitle {
        font-size: 20px;
      }
    }
  }
}
