.mainContainer {
  background-color: white;
  border-radius: 16px;
  padding: 32px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .headerBlock {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    .returnButton {
      display: flex;
      flex-direction: row;
      gap: 20px;
      width: fit-content;
      border: none;
      background-color: transparent;
      cursor: pointer;
      margin-right: auto;
    }
  }
  .buttonsPanel {
  }
  .answerContainer {
    padding: 20px 32px;
    box-shadow: 0px 4px 24px 0px #0000000a;
    border-radius: 16px;
    .answerHeader {
      //styleName: Heading/Desktop/H7 16px-32px-normal-600;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin-bottom: 8px;
    }
  }
}
