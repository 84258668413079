@import '../../../styles/_mixins.scss';

.profBackgroundItemWrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 28px;

  &:last-child {
    margin-bottom: 0;
  }

  & > .profItemIndex {
    margin-right: 20px;
    font-weight: 500;
  }

  & > .deleteIconWrapper {
    display: none;

    @include for-mobile-max_767 {
      position: absolute;
      display: block;
      left: 0;
      top: 30px;
    }
  }
}

.profItemSecondWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.datesWrapper {
  display: flex;
  align-items: flex-start;
  gap: 20px;

  @include for-mobile-max_767 {
    flex-direction: column;
  }
}

.datePicker {
  display: flex;
  flex-direction: column;
  gap: 3px;

  .datePickerInput {
    position: relative;
    width: 100%;

    @include for-mobile-max_767 {
      // width: 90% !important ;
    }

    &[data-type='location'] {
      cursor: pointer !important;
    }

    &[data-type='location'] > label[data-shrink='false'] {
      transform: translate(56px, 14px) scale(1) !important;
    }

    & > label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;

      &[data-shrink='false'] {
        transform: translate(14px, 14px) scale(1);
      }
    }

    & > div {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #212121;
      border-radius: 12px;
    }

    & > div > input {
      padding: 12.5px 16px;
    }

    & > div > fieldset {
      border-radius: 12px;
      border: 1px solid #dcdfe2;

      & > legend {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}

.firstLineWrapper {
  display: flex;
  gap: 20px;
  width: 100%;

  @include for-mobile-max_767 {
    flex-direction: column;
  }
}

.deleteIconWrapper {
  width: 24px;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  font: inherit;
  cursor: pointer;

  @include for-mobile-max_767 {
    display: none;
  }
}

.editInput {
  position: relative;
  width: 100%;

  & > label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 0px 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 16px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.checkbox {
  position: absolute;
  z-index: 10;
  opacity: 0;

  & + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    color: #143471;
  }

  & + label::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid #143471;
    border-radius: 2px;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 80% 60%;
    cursor: pointer;
  }

  &:checked + label::before {
    background-image: url(../../../../public/images/templates/checkbox-blue.svg);
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
}
