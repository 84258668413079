/*@import 'src/design-system/src/components/common/index.scss';*/

/* @tailwind base;
@tailwind components;
@tailwind utilities; */
@import './fonts.css';
@import 'react-phone-input-2/lib/style.css';

html,
body {
  font-family: 'Montserrat', sans-serif;
}

body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#__next {
  height: 100%;
}

input:autofill {
  background-color: transparent;
  transition: all 50000s ease-in-out;
}

input:-webkit-autofill {
  background-color: transparent;
  transition: all 50000s ease-in-out;
}
