.text {
  text-align: center;
  font-weight: 500;
  font-size: 25px;
  line-height: 150%;
  color: #ffffff;
  margin: 40px;
}

.secondBtn {
  padding: 13px 28px;
  gap: 28px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  background: #ffffff;
  color: #333333;
  border: 1px solid transparent;

  &:disabled {
    background: lightgray;
    color: grey;
    border: 1px solid grey;
  }

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.8);
  }
}
