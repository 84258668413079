@import '/src/styles/mixins';

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
div,
td,
th,
b,
strong,
button {
  font-family: 'Montserrat', serif;
}

.h1 {
  margin-bottom: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  color: #212121;
}

.h2 {
  margin-bottom: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 36px;

  color: #212121;
}

.p {
  margin-bottom: 10px;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;

  color: #212121;
}

.p:last-child {
  margin-bottom: 0;
}

.link {
  text-decoration: underline;
  cursor: pointer;
  color: #18397a;
}

@mixin MuiButton-text {
  font-family: 'Montserrat', serif !important;
}

.bold {
  font-weight: bold;
}

.content {
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
  background: #e5e5e5;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.pageContent {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  padding-top: 16px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  a {
    cursor: pointer;
  }

  @include for-mobile-max_767 {
    padding-left: 3% !important;
    padding-right: 3% !important;
  }

  @include for-mobile-max_767 {
    flex-wrap: wrap;
  }
}

.midContent {
  background-color: #ffffff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 50px 88px 32px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  z-index: 8;
  display: block;
  margin-left: 1.2% !important;
  margin-right: 1.2% !important;
  width: 100%;
  height: 100%;
  @include for-tablet-landscape-max_1024 {
    margin-bottom: 60px;
  }

  h1 {
    margin: 0;
    font-size: 36px;
    line-height: 56px;
    margin-top: 28px;
    margin-bottom: 24px;

    @media only screen and (max-width: 1024px) {
      font-size: 36px;
      line-height: 56px;
    }

    @include for-mobile-max_767 {
      font-size: 24px;
      line-height: 36px;
    }
  }

  p {
    margin: 0;
    font-size: 24px;
    line-height: 36px;

    @media only screen and (max-width: 1024px) {
      font-size: 20px;
      line-height: 32px;
    }

    @include for-mobile-max_767 {
      font-size: 16px;
      line-height: 24px;
    }
  }

  button {
    padding: 19px 34px;
    min-width: fit-content;

    background: #ffffff;
    border-radius: 12px;
    border: solid 1px #efefef;
    color: #424242;

    &.active {
      background: #d6eeff !important;
    }
  }

  @media only screen and (max-width: 1660px) {
    width: 100%;
    padding: 40px 40px 32px;
  }

  @include for-mobile-max_767 {
    margin-left: 0 !important;
    margin-right: 0 !important;
    min-width: 320px !important;
    padding: 20px;
  }
  @include for-mobile-max_767 {
    flex-wrap: wrap;
  }

  .footnote {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 16px;
  }
  .textHeaderContainer {
    display: block;
    width: 100%;
    text-align: left;

    .dropdownItemCompany {
      margin: 0;
      display: flex;
      align-items: center;
      column-gap: 10px;

      li {
        font-weight: 500;
        list-style-type: none;
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        color: #ffffff;
        background: #143471;
      }
    }
  }

  .tabsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .tabsPeriod {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      div {
        background: rgba(251, 251, 251, 1);
        border-radius: 12px;
        button {
          cursor: pointer;
          padding: 19px 34px;

          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          border: none;
          background: transparent;

          &.left {
            border-radius: 12px;
          }

          &.right {
            border-radius: 12px;
          }

          @media only screen and (max-width: 1024px) {
            font-size: 14px;
            line-height: 24px;
          }

          @include for-mobile-max_767 {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }

    .tabsRole {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      column-gap: 20px;

      @include for-mobile-max_480 {
        column-gap: 0;
      }

      button {
        cursor: pointer;
        padding: 24px 60px;
        width: 33%;

        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
        color: rgba(33, 33, 33, 1);

        @media only screen and (max-width: 1024px) {
          padding: 18px 30px;
          font-size: 20px;
          line-height: 32px;
        }

        @include for-mobile-max_767 {
          padding: 8px 18px;
          font-size: 16px;
          line-height: 24px;
        }

        @include for-mobile-max_480 {
          padding: 8px 0;

          &.left {
            border-radius: 12px 0 0 12px;
          }
          &.middle {
            border-radius: 0;
          }
          &.right {
            border-radius: 0 12px 12px 0;
          }
        }
      }
    }
  }

  .tariffFilter {
    margin-top: 20px;

    .tariffFilterSelect {
      width: 100%;
    }
  }

  .planContainer {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    border-spacing: 0;

    td,
    th {
      position: relative;
      width: 18%;
    }

    th:first-child,
    td:first-child {
      width: 27%;
    }

    td:hover::after,
    th:hover::after {
      content: '';
      position: absolute;
      background-color: #d6eeff;
      left: 0;
      top: -5000px;
      height: 10000px;
      width: 100%;
      z-index: -1;
    }

    th:first-child:hover::after,
    td:first-child:hover::after {
      display: none;
    }

    .planHeader {
      width: 100%;

      th {
        //position: sticky;
        //top: 100px;
        height: 100%;
        background: #fff;
        z-index: 9;
        height: 300px;
        @media (max-width: 1025px) {
          height: 200px;
        }
      }
      .tariffContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        max-height: 100%;
        padding-left: 8px;
        padding-right: 8px;

        .planName {
          padding: 20px 0;
          font-weight: 600;
          font-size: 28px;
          line-height: 36px;

          @media screen and (max-width: 1024px) {
            padding: 0;
          }
        }

        .planPrice {
          font-weight: 800;
          font-size: 36px;
          line-height: 56px;
          color: #212121;
        }

        .planDetail {
          color: #757575;
          .monthDetailsCompany {
            p {
              &:first-child {
                font-size: 16px;
                font-weight: 600;
                text-align: center;
                list-style: 0px;
              }
              &:last-child {
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                line-height: 10px;
                margin-bottom: 32px;
              }
            }
          }
        }

        .planEnterpr {
          color: #143471;
          font-size: 24px;
          font-weight: 600;
          padding: 32px 0;
        }

        .planButton {
          margin-bottom: 20px;
          margin-top: auto;
          align-items: flex-end;

          button {
            cursor: pointer;
            background: #143471;
            border-radius: 4px;
            color: #ffffff;
            font-weight: 600;
            font-size: 16px;
            text-transform: uppercase;
            padding: 14px 26px !important;

            @media screen and (max-width: 1280px) {
              height: 68px;
            }
            @media screen and (max-width: 1024px) {
              width: 100%;
              height: unset;
            }
            &.current {
              color: #143471;
              border: solid 1px #143471;
              background: #ffffff;
            }
          }
        }
      }
    }

    .planTable {
      overflow: hidden;

      button.tooltipButton {
        cursor: pointer;
        padding: 0;
        margin: 0 0 0 10px;
        border: 0;
        vertical-align: baseline;
      }

      .categoryRow {
        background-color: rgba(239, 239, 239, 0.4);
      }

      td {
        text-align: center;
        font-weight: 200;
        border-collapse: collapse;
        border-top: solid 1px #efefef;
        padding: 20px;

        &:first-child {
          text-align: left;
        }
      }

      .planItemCategory {
        text-align: left;
        font-weight: 600;
      }

      .planItemName {
        text-align: left;
        font-weight: 400;
        display: inline-flex;
        gap: 8px;
        align-items: center;
      }

      .planItemDetail {
        font-weight: 500;
        height: fit-content;
        position: relative;
        z-index: 9999;

        .yesIco {
          color: #143471;
        }

        .noIco {
          color: #c4c4c4;
        }
      }
    }
  }
}

.mobileNavigation {
  display: none;

  @include for-tablet-landscape-max_1024 {
    display: block;
    z-index: 10;
  }
}

.companyCreate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  @media screen and (max-width: 1025px) {
    flex-direction: column;
  }
}

.companyCreate {
  p {
    color: rgba(117, 117, 117, 1);
    font-weight: 600;
    font-size: 24px;
  }
}

.buttonCreate {
  height: 48px;
  background-color: rgba(24, 57, 122, 1) !important;
  font-size: 16px;
  line-height: 48px;
  letter-spacing: 0em;
  text-transform: uppercase;
  color: #fff !important;
  padding: 0px 20px !important;
  width: max-content !important;
  border-radius: 4px !important;
  cursor: pointer;
  @media screen and (max-width: 1025px) {
    font-size: 12px;
    height: 38px;
    line-height: 0px;
    margin-top: 12px;
  }
}

.typeList {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 20px;

  color: rgb(25, 118, 210);
  text-decoration: underline rgba(25, 118, 210, 0.4);
  &:hover {
    text-decoration-color: inherit;
  }
}
