.dialogTitle {
  padding: 32px 42px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}
.dialogContent {
  padding: 32px 42px;
}

.subTitle {
  font-weight: 600;
  font-size: 16px;
  color: #212121;
}
