.contentBlock {
  width: 100%;
  display: flex;
  height: max-content;
  flex-direction: column;
  border-radius: 12px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.04);
  align-content: center;
  padding: 28px 20px 26px 20px;
  gap: 20px;

  .blockHeader {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    .nameBlock {
      max-width: 520px;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      //styleName: Heading/Desktop/H5 24px-36px-normal-600;
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .buttonBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 20px;
  }
}
.warningModal {
  .buttonBlock {
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 400px) {
      display: flex;
      flex-direction: column;
      gap: 10px;
      button {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
