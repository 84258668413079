.modal {
  max-width: 480px;

  .popupBlockContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    font-size: 16px;
    font-weight: 500;

    .rowContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .reports {
        min-width: 70px;
      }
      button {
        width: fit-content;
        align-items: center;
        svg {
          margin-right: 10px;
        }
      }
    }
  }
}

.processModal {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .text {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .buttonBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 400px) {
      flex-direction: column;
      justify-content: flex-end;
      gap: 10px;
      button {
        max-width: 100%;
        width: 100%;
      }
    }

    .downloadIco {
      > svg {
        width: auto;
        height: 18px;
        margin-right: 10px;
      }
    }
  }
}

.questionModal {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;

  .question {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .subheader {
      //styleName: Heading/Desktop/H6 20px-32px-normal-600;
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
    }
    .questionText {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      > span {
        margin-left: 10px;
        display: inline-block !important;
      }
    }
    .exampleHead {
      padding: 0 10px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
    .exampleBody {
      padding: 0 10px;
    }
    .text {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
    }
    .buttonBlock {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media screen and (max-width: 400px) {
        display: flex;
        flex-direction: column;
        gap: 10px;
        button {
          max-width: 100%;
          width: 100%;
        }
      }
    }
    .inputContainer {
      width: 100%;
      > div {
        width: 100%;
        > div {
          padding: 0;
        }
      }
    }
    .pagination {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #18397a;
    }
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
