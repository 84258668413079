@import '/src/styles/mixins';

.loadingContainer {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  border-radius: 12px;
}

.tabContent {
  font-family: 'Montserrat';
  padding: 24px 60px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;

  @include for-tablet-landscape-max_1024 {
    padding: 24px 40px;
  }

  @include for-tablet-landscape-max_900 {
    padding: 20px;
  }

  .accordionTitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #212121;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;

    @include for-tablet-landscape-max_900 {
      font-size: 16px;
    }
  }

  .accordionDetails {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .tableActions {
      display: flex;
      align-items: center;
      gap: 10px;

      svg {
        cursor: pointer;
      }
    }
  }

  .accordionActions {
    display: flex;
    align-items: center;
    gap: 24px;

    @include for-tablet-landscape-max_900 {
      gap: 12px;
      margin-right: -12px;
    }

    svg {
      cursor: pointer;
    }
  }

  .actionBtn {
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
    width: fit-content !important;
    min-width: unset !important;
  }

  .addBtn {
    display: flex;
    align-items: center;
    gap: 12px;
    svg > path {
      fill: #18397a;
    }
    .btnText {
      font-family: 'Montserrat';
      font-size: 16px;
      font-weight: 600;
      text-align: left;

      @include for-tablet-landscape-max_900 {
        font-size: 12px;
      }
    }
  }
}

table th {
  background-color: #ffffff !important;
}
