$whiteColor: #fff;

$modalMobileShadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
$modalDesktopShadow: 0px 4px 77px 0px rgba(0, 0, 0, 0.08);

.modal {
  .cover {
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 0;
    pointer-events: none;
    transition: opacity 100ms ease-in;
  }

  .container {
    position: fixed;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: fit-content;
    min-width: 350px;
    height: fit-content;
    padding: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    background-color: rgba($whiteColor, 0.8);
    box-shadow: $modalMobileShadow;
    backdrop-filter: blur(20px);

    opacity: 0;
    pointer-events: none;
    transition: opacity 150ms ease-in;

    .headerContainer {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .closeBtn {
        border: none;
        background: none;
        padding: 0;
        min-width: 14px;
        margin: 5px;
        margin-left: 10px;

        .closeSvg {
          width: 14px;
          height: 14px;
        }
      }

      .header {
        font-size: 20px;
        line-height: 32px;
        font-weight: 600;
      }
    }

    .content {
      max-height: 50vh;
      margin: 12px 0 28px 0;
      font-size: 12px;
      line-height: 20px;

      &.scrolled {
        overflow: auto;
      }
    }

    .agreeCheck {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .checkboxContainer {
        max-height: 20px;
        label {
          transform: translateY(-25%);
        }
      }

      .buttons {
        margin-left: auto;
        margin-top: 20px;

        &.wide {
          display: flex;
          justify-content: space-between;
          max-width: 260px;
          width: 100%;
          margin-left: auto;
        }
      }
    }
  }

  &.opened {
    .cover {
      opacity: 1;
      pointer-events: all;
      transition: opacity 100ms ease-out 50ms;
    }

    .container {
      opacity: 1;
      pointer-events: all;
      transition: opacity 150ms ease-out;
    }
  }

  @media screen and (min-width: 1024px) {
    .container {
      max-width: 800px;
      padding: 32px 40px;
      box-shadow: $modalDesktopShadow;

      .headerContainer {
        .header {
          font-size: 24px;
          line-height: 36px;
        }
      }

      .content {
        max-height: 320px;
        // margin: 20px 0 42px 0;
        font-size: 16px;
        line-height: 24px;

        &.scrolled {
          overflow: auto;
        }
      }

      .agreeCheck > .buttons {
        margin-top: 0;

        &.wide {
          max-width: 320px;
          width: 50%;
          margin-left: auto;
        }

        & > button:last-child {
          margin-left: 40px;
        }
      }
    }
  }
}
.buttonCont {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;

  @media screen and (max-width: 1024px) {
    margin-right: 0;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  .backBtn,
  .okBtn {
    cursor: pointer;
    padding: 0px 20px;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    white-space: nowrap;
    @media screen and (max-width: 1024px) {
      width: 48%;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .backBtn {
    background: white;
    border: 1px solid #18397a;
    color: #18397a;
  }

  .okBtn {
    background: #18397a;
    border: none;
    color: white;

    &:hover {
      border: none;
    }

    &.disabled {
      background-color: #8c9dbd;
    }
  }
}
