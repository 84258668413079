.backDrop {
  background-color: #d2d0ce !important;
}

.consentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 95%;
}

.fullScreenContentWrapper {
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
}
