.profBackgroundItemWrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  & > .profItemIndex {
    margin-right: 20px;
    font-weight: 500;
  }

  & > .profItemSecondWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > .profItemTitle {
      font-weight: 500;
    }

    & > .profItemSubTitle,
    & > .profItemTimeInterval {
      font-weight: 400;
    }
  }
}
