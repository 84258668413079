@import '/src/styles/mixins';

.avatarOverlay {
  position: relative;
  &:hover {
    .avatarRow {
      box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.06);
      border-radius: 12px 12px 0px 0px;
    }
  }
}

.avatarRow {
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.avatar {
  width: 40px;
  height: 40px;

  @include for-mobile-max_767 {
    width: 33px;
    height: 33px;
  }
}

.dropdown {
  position: absolute;
  z-index: 3231;
  top: 0;
  right: -5px;
  background-color: white;
  width: max-content;
  padding: 13px 0 18px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.13);
  border-radius: 12px;

  .dropdownItem {
    color: #212121;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 6px 57px 6px 19px;
    list-style-type: none;
    position: relative;

    &:last-child {
      color: #d32f2f;
      margin-top: 20px;
      border-top: 1px solid #efefef;
    }
    &:hover {
      color: #ffffff;
      background: #143471;
    }
  }

  .notActiveDropdownItem {
    color: #212121;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 6px 57px 6px 19px;
    list-style-type: none;
    position: relative;
    cursor: auto;
    &:last-child {
      color: #d32f2f;
      margin-top: 20px;
      border-top: 1px solid #efefef;
    }
  }

  @include for-tablet-landscape-max_1024 {
    display: none;
  }
}
.dropdownText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
}
.dropdownBox {
  position: relative !important;
  background: #ffffff;
  border-radius: 0px 0px 12px 12px;
  display: block;
  @include for-tablet-landscape-max_1024 {
    display: none;
  }
}
.settingModal {
  position: relative !important;
  display: none;
  @include for-tablet-landscape-max_1024 {
    display: block;
  }
}
.downArrowDiv {
  position: absolute;
  bottom: -2px;
  right: -2px;
  z-index: 4;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  padding: 3px 5px 2px 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow .arrowActive {
  width: 9px;
  height: 20px;
  margin-top: 5px;
}

.arrowActive {
  margin-top: -2px;
  path {
    fill: #ffffff;
  }
}

.dropdownCollapse {
  display: flex;
  align-items: center;
  position: relative;
}

.dropdownArrowCollapse {
  position: absolute;
  right: 10px;
}

.dropdownItemCollapse {
  color: #212121;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 6px 57px 6px 19px;
  list-style-type: none;
  position: relative;
}

.dropdownItemCompany {
  margin: 0px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 6px 0px;
  padding-left: 35px;

  li {
    font-weight: 500;
    list-style-type: none;
    width: 98px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    color: #ffffff;
    background: #143471;
  }
}

@media screen and (max-width: 765px) {
  .avatarRow {
    padding: 0;
  }

  .avatar {
    width: 33px;
    height: 33px;
  }

  .downArrowDiv {
    top: 25px;
    right: -2px;
  }
}

@media screen and (max-width: 375px) {
  .avatar {
    width: 26px;
    height: 26px;
  }
}

.avatarBadge {
  position: absolute;
  bottom: -8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  background: #fcfcfc;
  border-radius: 6px;
  padding: 3px 4px;
  right: 0;
  left: 0;
  margin: auto;
  width: fit-content;
}
