@import '../../../styles/_mixins.scss';

.warningWrapper {
  display: flex;
  width: 100%;

  @include for-mobile-max_767 {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

.warningIcon {
  position: relative;
  width: 24px;
  height: 24px;

  & > span {
    position: absolute;
    top: 0;
    left: -50px;
    width: 24px !important;

    @include for-mobile-max_767 {
      top: auto;
      left: auto;
    }
  }
}

.warningUl {
  @include for-mobile-max_767 {
    padding-left: 20px;
  }
}

.warning {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
}

.inputWrapper {
  min-height: 71px;
}

.textInput {
  width: 100%;
}

.textInput input {
  color: #fff;
  outline-color: #fff;
  padding: 8px 0;
  width: 100%;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.textInput button {
  color: #abaaad;
  width: 100%;
}

.formLeftContent {
  width: 115px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
