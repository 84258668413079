.postFeedContainer {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 20px 32px;
  border-radius: 12px;
  margin-bottom: 16px;
  user-select: text;

  @media only screen and (max-width: 370px) {
    padding: 20px;
  }
}

.postHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.articleHeadButton {
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  font-size: 20px;
  font-weight: 600;
}

.postAvatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.postAvatarTitle {
  display: flex;
  flex-direction: column;
  margin-left: 16px;

  > p {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 2px;
  }

  > span {
    font-size: 12px;
    font-weight: 400;
    color: #757575;
  }
}

.postBody,
.postBodyFeed {
  figure {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  p {
    width: 100%;
  }

  ol {
    margin-left: 20px;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  pre {
    @media only screen and (max-width: 700px) {
      width: 100% !important;
    }
  }

  code {
    display: block;
    max-width: 100%;
    overflow-y: auto;
  }

  iframe {
    max-width: 100% !important;
  }
}

.postBodyFeed {
  overflow: hidden;
  position: relative;
}

.postBodyFeedCroped {
  display: block;
  margin-top: 2px;
  color: #18397a;
  font-size: 16px;
  font-weight: 500;
  //background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 1) 15%);
  //padding: 2px 2px 2px 30px;
  cursor: pointer;
}

.postBodyFeedShowMore {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  //background: #ffffff;
  background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 1) 10%);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  color: #18397a;
  font-weight: 600;
}

.articlePageBody {
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
}

.fileBox {
  display: flex;
  max-width: 1020px !important;
  padding: 10px 0;
  margin-left: 0 !important;
  margin-right: 0 !important;

  @media only screen and (max-width: 1660px) {
    width: 100%;
    max-width: 100% !important;
    //overflow: hidden;
  }
}

.videoBox {
  max-height: 400px !important;
}

.videoplayer {
  max-height: 800px !important;
  width: 42vw !important;
  max-width: 1020px !important;
}

.audioPlayer {
  width: 100%;
}

.audioPlayerDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  @media screen and (max-width: 500px) {
    margin: 0;
  }
}

.audioPlayerName {
  padding-left: 4%;
  width: max-content;
  white-space: nowrap;
  line-height: 20px;
  text-align: right;
  overflow: hidden;
  font-size: 16px;
  -webkit-animation: alternat 20s 1s infinite;
  animation: alternat 20s 1s infinite;
}

.imagesWrapper {
  max-width: unset !important;
  width: calc(100% + 64px);
  margin: 0 -32px !important;
}

.postStatistics {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  //margin-top: 16px;

  span {
    font-size: 14px;

    @media screen and (max-width: 400px) {
      font-size: 12px;
      margin-left: 20px !important;
    }
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    color: #555555;

    > span {
      margin-left: 60px;
    }
  }
}

.postStatisticsReacts,
.unauthPostStatisticsReacts {
  > div {
    margin: 0 !important;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    > p {
      margin-left: 14px;
    }

    @media screen and (max-width: 400px) {
      font-size: 12px;
    }
  }

  span {
    margin-left: 3px !important;
  }
}

.unauthPostStatisticsReacts {
  > div {
    cursor: default;
  }
}

.postFooter,
.postFooterUnauth {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 14px;
  margin-top: 14px;
  border-top: 1px solid #efefef;
  height: 48px;
}

.postFooterUnauth {
  justify-content: flex-end;
}

.selectItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  padding: 5px 0;

  > img {
    width: 24px !important;
    height: 24px !important;
    margin-right: 9px;
  }
}

.select {
  > div > div {
    font-size: 16px;
    font-weight: 500;

    > img {
      height: 26px;
    }

    @media only screen and (max-width: 676px) {
      font-size: 14px;
    }
  }
}

.selectBody {
  box-shadow: 0 4px 32px 0 #00000014 !important;
  border-radius: 12px !important;

  li {
    margin: 5px 0;
    background-color: transparent !important;

    &:hover {
      background-color: inherit !important;
      color: #143471 !important;
    }

    & > div > span {
      display: block !important;
    }
  }
}

.avatar {
  width: 30px !important;
  height: 30px !important;
  font-size: 12px !important;
  margin-right: 10px;
}

.postFooterButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 5px 20px;

  &:last-child {
    padding: 5px 0 5px 20px;
  }

  > span {
    margin-left: 10px;

    @media only screen and (max-width: 572px) {
      display: none;
    }
  }

  @media only screen and (max-width: 572px) {
    padding: 5px 10px;

    &:last-child {
      padding: 5px 0 5px 10px;
    }
  }
}

.inputOptions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ::before,
  ::after {
    border: none !important;
  }

  *:focus {
    background: transparent !important;
  }

  > div {
    box-shadow: none !important;
  }

  > div > div {
    border: none !important;
    border-radius: 12px !important;
  }
}

.articleFeedPreview {
  font-size: 16px;
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 16px;
}

.articleFeedCover,
.articlePageCover {
  background-size: cover;
  width: calc(100% + 64px);
  max-height: 500px;
  margin-bottom: -4px;
  margin-right: -32px;
  margin-left: -32px;
}

.articlePageCover {
  max-height: 500px;
  margin-bottom: 24px;

  @media screen and (max-width: 400px) {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
  }
}

.articlePageTitle {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
}

.articlePageUserRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.pageUserRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 16px;
}

.articleBodyFeed {
  position: relative;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;

  * {
    font-weight: normal !important;
    font-style: normal !important;
  }

  img {
    margin: 10px 0 !important;
  }
}

.articleBodyFeedEnd {
  position: absolute;
  right: 2px;
  top: 4px;
}

.articleFeedTitle {
  color: #18397a;
  font-size: 16px !important;
  font-weight: 600;
}

.articleFeedLinkContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.articleFeedDesc {
  width: 100%;
}

.articleFeedLink {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #e0edfa;
  width: calc(100% + 64px);
  padding: 12px 32px;
  margin-right: -32px;
  margin-left: -32px;
  //margin-bottom: 10px;
  cursor: pointer;

  * {
    margin: 0 !important;
  }
}

.filesWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.docsFileWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 500px) {
    margin: 0;
  }
}

.docTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.postAction {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px 8px;

  &:hover {
    background: #f0f0f0;
    border-radius: 8px;
  }
}
