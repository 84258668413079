@import '../../styles/_mixins.scss';

.formItemRight {
  display: flex;
  align-items: center;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 360px;

  @include for-mobile-max_767 {
    max-width: 100%;
  }
}
