@import '../../../../../styles/_mixins.scss';

$red: #cd0606;

.secondWrapper {
  width: 100%;
  padding: 12px 30px;
  display: flex;
  border-radius: 10px;

  @include for-mobile-max_767 {
    flex-direction: column;
  }
}

.checkboxWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkboxesWrapper {
  width: 65%;
  display: flex;
  align-items: center;
  gap: 35px;
  border-right: 2px solid #212121;

  @include for-mobile-max_767 {
    width: 100%;
    border-right: none;
    border-bottom: 2px solid #212121;
  }

  @include for-mobile-max_480 {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}

.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;

  & + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
  }

  & + label::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid #757575;
    border-radius: 2px;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 80% 60%;
    cursor: pointer;
  }

  &:checked + label::before {
    background-color: #757575;
    background-image: url(../../../../../../public/images/templates/checkbox.svg);
  }

  //   /* стили при наведении курсора на checkbox */
  // .custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
  //   border-color: #b3d7ff;
  // }
  // /* стили для активного состояния чекбокса (при нажатии на него) */
  // .custom-checkbox:not(:disabled):active+label::before {
  //   background-color: #b3d7ff;
  //   border-color: #b3d7ff;
  // }
  // /* стили для чекбокса, находящегося в фокусе */
  // .custom-checkbox:focus+label::before {
  //   box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  // }
  // /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
  // .custom-checkbox:focus:not(:checked)+label::before {
  //   border-color: #80bdff;
  // }
  // /* стили для чекбокса, находящегося в состоянии disabled */
  // .custom-checkbox:disabled+label::before {
  //   background-color: #e9ecef;
  // }
}

.descriptionWrapper {
  width: 35%;
  margin-left: 20px;
  line-height: 20px;

  @include for-mobile-max_767 {
    width: 100%;
    margin-left: 0;
  }
}

.description {
  display: flex;
  width: 100%;
  color: #000;
  font-size: 14px;
}

.error {
  font-size: 14px;
  color: $red;
}

.errorFieldWrapper {
  padding: 4px;

  & > .secondWrapper {
    border: 2px solid $red;
    padding: 6px 24px;
    // box-shadow: 0 0 10px #D32F2F inset;

    & > .checkboxesWrapper {
      border-right: 2px solid $red;

      @include for-mobile-max_767 {
        border-right: none;
        border-bottom: 2px solid $red;
      }
    }
  }
}
