.contentEditorWrapper {
  border-radius: 10px;
  width: 100%;
  max-height: 50vh;
  min-height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;

  > div {
    height: 100% !important;
    min-height: 400px;
  }
}
.error {
  border: 1px solid red;
}
.errorMessage {
  font-size: 14px;
  color: #bb1d20;
  margin-top: 10px;
}
