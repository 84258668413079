.error {
  position: absolute;
  left: 0;
  top: 50px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #c73131;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(15px);
  border-radius: 8px;
  padding: 10px 20px;
}

.iconWrapper {
  position: relative;
  width: 20px;

  & > span {
    width: 20px !important;
  }
}
