@import '/src/styles/mixins';

.contentBox {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;

  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px;

  h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin: 0;
  }

  .info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #212121;

    span {
      margin-left: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .payMethod {
    box-sizing: border-box;
    width: 140px;
    height: 80px;
    left: 0px;
    top: 0px;
    background: #ffffff;
    border: 1px solid #efefef;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    padding-top: 6px;
    cursor: pointer;
    &.selected {
      border: 2px solid #18397a;
    }
  }
}
.buttonCont {
  margin: 32px 0 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;

  .payButton {
    cursor: pointer;
    padding: 0px 20px;
    background: #18397a;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    color: #ffffff;

    &.disabled {
      background-color: #8c9dbd;
    }
  }
  .backButton {
    cursor: pointer;
    padding: 0px 20px;
    background: transparent;
    border-color: transparent;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: uppercase;
    color: #18397a;
  }
}

.contentBox::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.middleContent {
  width: 1020px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1.2% !important;
  margin-right: 1.2% !important;

  @media only screen and (min-width: 1025px) {
    min-width: 545px;
  }

  @include for-tablet-landscape-max_1024 {
    width: 100%;
  }
  /* @include for-desktop-up-max_1660 {
    display: block;
    max-width: 50vw;
  } */
}
.formContainer {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: start;
  justify-content: flex-start;
  height: max-content;
  background-color: white;
  padding-top: 20px;
  /* width: 100vw; */
  max-width: 1020px;
  border-radius: 12px;
  margin-bottom: 10px;
}

.cardsBlock {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 12px;

  @include for-mobile-max_620 {
    flex-wrap: wrap;
    .select {
      width: 100%;
    }
  }

  .select {
    border: 1px solid #efefef;
    border-radius: 12px;
    min-width: 250px;
  }

  .addCardBtn {
    white-space: nowrap;
    display: flex;
    gap: 12px;
    align-items: center;

    svg > path {
      fill: #143471;
    }
  }
}
.addCardBtn {
  white-space: nowrap;
  display: flex;
  gap: 12px;
  align-items: center;

  svg > path {
    fill: #ffffff;
  }
}
