.leftSection {
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin-left: 80px;
  }
}

.mainSection_1 {
  position: relative;
  color: #ffffff99;
  font-weight: 600;
  font-size: 30px;
  margin-top: 20px;
  margin-left: 330px;
  margin-bottom: -5px;

  @media (max-width: 1500px) {
    color: #ffffff80;
    font-size: 25px;
    margin-left: 230px;
  }

  @media (max-width: 1200px) {
    margin-left: 0;
    margin-top: 60px;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    margin-bottom: 3px;
    margin-left: 2px;
    margin-top: 40px;
  }
}

.mainSection_2 {
  position: relative;
  color: #ffffff99;
  font-weight: 800;
  font-size: 120px;
  margin-left: 140px;
  margin-top: -10px;
  margin-bottom: -10px;

  @media (max-width: 1500px) {
    font-size: 100px;
    margin-left: 70px;
  }

  @media (max-width: 1200px) {
    font-size: 120px;
    margin-left: 0;
  }

  @media (max-width: 768px) {
    font-size: 40px;
  }
}

.mainSection_3 {
  position: relative;
  color: #ffffff66;
  font-weight: 700;
  font-size: 120px;
  margin-left: 610px;
  margin-top: -120px;

  @media (max-width: 1500px) {
    margin-top: -115px;
    margin-left: 460px;
  }

  @media (max-width: 1200px) {
    color: #ffffff99;
    margin-left: 447px;
    margin-top: -127px;
  }

  @media (max-width: 768px) {
    font-size: 40px;
    margin-left: 149px;
    margin-top: -36px;
  }
}

.mainSection_4 {
  position: relative;
  color: #ffffff66;
  font-weight: 700;
  font-size: 25px;
  margin-left: 620px;
  width: 220px;
  margin-top: -20px;

  @media (max-width: 1500px) {
    margin-left: 470px;
  }

  @media (max-width: 1200px) {
    font-size: 25px;
    margin-left: 390px;
    margin-bottom: 60px;
  }

  @media (max-width: 768px) {
    width: unset;
    font-size: 10px;
    margin-left: 118px;
    margin-top: -7px;
    margin-bottom: 40px;
  }
}
