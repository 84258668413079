@import '/src/styles/mixins';

.pageContent {
  font-family: 'Montserrat';
  padding: 24px 60px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  border-radius: 12px;

  @include for-tablet-landscape-max_1024 {
    padding: 24px 40px;
  }

  @include for-tablet-landscape-max_900 {
    padding: 20px;
    gap: 16px;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  .form {
    display: flex;
    align-items: flex-start;
    gap: 50px;

    @include for-desktop-up-max_1660 {
      gap: 40px;
    }

    @media (max-width: 900px) {
      flex-direction: column;
    }

    .inputsBlock {
      min-width: 602px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      @include for-desktop-up-max_1660 {
        min-width: unset;
        width: 100%;
      }
      .textarea {
        border-radius: 12px !important;
        font-size: 16px;

        > div {
          border-radius: 12px !important;
        }
      }
      .select {
        line-height: 24px;
        cursor: pointer;
        margin-top: 0;
        border-radius: 12px;
        width: 100%;
        height: 48px;
        > div {
          padding-left: 14px;
          height: 48px;
          display: flex;
          align-items: center;
        }
      }

      .titleWithBtn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #efefef;

        .title {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 32px;
          color: #212121;
          display: flex;
        }

        .addDocBtn {
          display: flex;
          align-items: center;
          gap: 12px;
          right: 60px;
          font-family: 'Montserrat';
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          text-transform: uppercase;
          padding: 12px 20px;
          top: 20px;
          cursor: pointer;
          color: #18397a;

          svg > path {
            fill: #18397a !important;
          }
        }
      }

      .image {
        width: 156px;
        height: 156px;
        border-radius: 12px;
        overflow: hidden;
        position: relative;

        .imageLoader {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          background: rgba(0, 0, 0, 0.2);
          backdrop-filter: blur(6px);
          display: flex;
          justify-content: center;
          align-items: center;
          .reloadBtn {
            width: 30px;
            height: 30px;
            cursor: pointer;
            svg > path {
              fill: #ffffff;
            }
          }

          .lineProgress {
            border-radius: 2px;
            width: 80px;
            height: 4px;
            background-color: rgba(0, 0, 0, 0.6);
            span {
              background-color: #ffffff;
            }
          }
        }

        .removeImage {
          position: absolute;
          display: flex;
          background: rgba(0, 0, 0, 0.4);
          backdrop-filter: blur(2px);
          justify-content: center;
          align-items: center;
          cursor: pointer;
          top: 6px;
          width: 24px;
          height: 24px;
          right: 6px;
          border-radius: 2px;
          z-index: 1;
        }
        @include for-mobile-max_767 {
          width: 110px;
          height: 110px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .deleteIcon {
          cursor: pointer;
          position: absolute;
          bottom: 0;
          right: 0;
          display: none;
          opacity: 0;
          background: #00000066;
          padding: 4px 0;
          transition: opacity 0.3s ease;
          span {
            display: flex;
            svg > path {
              fill: #ffffff;
            }
          }
        }

        &:hover .deleteIcon {
          display: flex;
          opacity: 1;
          width: 100%;
          justify-content: center;
        }
      }
      .linkAndFileBlock {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .pdfFile {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          display: flex;
        }

        .fileName {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          max-width: 40%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

.textField {
  position: relative;
  width: 100%;

  @include for-mobile-max_767 {
    // width: 90% !important ;
  }

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }

  & > label {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 16px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.errorMessage {
  color: red;
  font-size: 12px !important;
}
