@import '../../../../src/styles/mixins';

.container {
  width: 100%;
  height: 80%;
  margin-bottom: 120px;

  .actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 22px;

    @include for-tablet-landscape-max_900 {
      margin-top: 12px;
    }

    .tabs {
      display: flex;
      align-items: center;
      gap: 20px;

      @include for-mobile-max_480 {
        gap: unset;
        justify-content: space-between;
        width: 100%;
      }

      .tab {
        border-radius: 12px;
        background-color: #ffffff;
        box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.04);
        padding: 6px 16px;
        cursor: pointer;

        p {
          color: #212121;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;

          @include for-mobile-max_480 {
            font-family: 'Montserrat';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }

      .activeTab {
        background-color: #ceeaff;

        p {
          color: #143471;
          @include for-mobile-max_480 {
            font-family: 'Montserrat';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }

    .primaryButton {
      display: flex;
      align-items: center;
      gap: 12px;

      @media screen and (max-width: 660px) {
        display: none;
      }

      p {
        color: #ffffff;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }

  .countInfo {
    color: #757575;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 8px;
  }

  .filterContent {
    margin-top: 20px;
    margin-bottom: -15px;

    @include for-tablet-landscape-max_900 {
      margin-top: 12px;
    }
    .filterItems {
      display: flex;
      align-items: center;
      gap: 16px;

      .search {
        display: flex;
        width: 100%;
      }
    }
    .inputGroup {
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;

      .searchInput {
        font-family: 'Montserrat';
        width: 100%;
        height: 48px;
        padding: 0 56px;
        background: #ffffff;
        border: 1px solid #efefef;
        border-radius: 12px;
      }

      .searchIcon {
        display: flex;
        align-items: center;
        position: absolute;
        left: 22px;
        bottom: 14px;
      }
    }
  }

  .filterActions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .filterIcons,
    .activeIcon {
      width: 20px;
      height: 20px;
      margin: 5px;
      flex-shrink: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .activeIcon {
      svg > path {
        fill: #18397a !important;
      }
    }
  }

  .list {
    margin-top: 10px;
    height: 64vh;
    padding-bottom: 20px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .listItem {
    margin-top: 16px;
    display: flex;
    align-items: center;
    width: 100%;
    background: #ffffff;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding: 12px 24px;
    gap: 12px;
    cursor: pointer;

    .avatar {
      cursor: pointer;
      border-radius: 50%;
      width: 57px;
      height: 57px;
    }

    .listItemBlock {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .info {
        .name {
          display: flex;
          align-items: center;
          gap: 4px;

          p {
            color: #212121;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        .type {
          color: #212121;
          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        .description {
          color: #757575;
          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }

  .gridContent {
    height: 60vh;
    margin-top: 20px !important;
    padding-bottom: 20px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-radius: 12px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .card {
    min-height: 294px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    overflow: unset !important;
    width: 100%;
    border-radius: 16px !important;
    position: relative;

    @include for-mobile-max_620 {
      padding: 20px;
    }

    .cardImageBlock {
      min-width: 100%;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .gridCardName {
      margin-top: 8px;
      display: flex;
      align-items: center;
      gap: 4px;

      p {
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include for-mobile-max_620 {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }

    .type {
      color: #212121;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }

    .description {
      color: #757575;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      max-height: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .cardActions {
      display: flex;
      justify-content: center;
      position: absolute;
      right: 2px;
      top: 8px;
    }
  }

  .buttonsGroup {
    display: flex !important;
    justify-content: space-between;
    background: #fff;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    padding: 9px 10px;
  }

  .newConnectionsBlock,
  .invitationsBlock {
    background-color: #fff;
    margin-top: 16px;
    border-radius: 12px;
    display: flex;
    padding: 20px 40px;
    gap: 16px;
    flex-direction: column;

    .title {
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }

    .connectedUsers {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
      width: 100%;

      @media (max-width: 1560px) {
        flex-direction: column;
      }
      @media (max-width: 1024px) {
        flex-direction: row;
      }
      @media (max-width: 960px) {
        flex-direction: column;
      }
      .connectItem {
        width: calc(50% - 10px);
        padding: 12px 24px;
        border-radius: 12px;
        box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 1560px) {
          width: 100%;
        }

        @media (max-width: 1024px) {
          width: calc(50% - 10px);
        }

        @media (max-width: 960px) {
          width: 100%;
        }

        @media (max-width: 520px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
        }

        .infoBlock {
          display: flex;
          gap: 12px;

          .userInfo {
            display: flex;
            flex-direction: column;
            min-width: 143px;
            max-width: 143px;
            .userName {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-family: 'Montserrat';
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
            .invitedBy {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-family: 'Montserrat';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }
            .experiences {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: flex;
              font-family: 'Montserrat';
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;

              .experienceName {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: flex;
                align-items: center;
                flex-wrap: nowrap;

                .greenText {
                  color: #4caf50;
                  margin-left: 3px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
        .connectionActions {
          display: flex;
          flex-direction: column;
          align-items: center;
          @media (max-width: 520px) {
            flex-direction: row;
            gap: 12px;
          }

          .btn {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            color: #18397a;
            font-family: 'Montserrat' !important;
            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 600 !important;
            line-height: 40px !important;
            text-transform: uppercase !important;
          }
        }
      }
    }
    .viewAllBlock {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .viewAllButton {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;

        .text {
          color: #18397a;
          text-decoration: underline;
          font-family: 'Montserrat';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }

        .downIcon {
          height: 20px;
          width: 20px;

          svg path {
            fill: #18397a;
          }
        }
      }
    }
  }

  .invitationsBlock {
    .headerBlock {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;

      .createInviteBtn {
        display: flex;
        gap: 12px;

        &:hover {
          background-color: #18397a !important;
          color: #ffffff !important;
          svg > path {
            fill: #ffffff !important;
          }
        }
      }
    }
    .invitationsList {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
      @include for-desktop-up-max_1200 {
        grid-template-columns: 1fr 1fr;
      }
      @include for-tablet-landscape-max_1024 {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @include for-tablet-landscape-max_1000 {
        grid-template-columns: 1fr 1fr;
      }
      @include for-mobile-max_767 {
        grid-template-columns: 1fr;
      }
    }
    .invItem {
      cursor: pointer;
      padding: 16px;
      border: 1px solid #efefef;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .invName {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
      }

      .infoItem {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .infoItemName {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #212121;
        }
        .infoItemValue,
        .infoItemStatusValue {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          max-width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #555555;
        }
        .infoItemStatusValue {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
    }
  }

  .tableHeading {
    font-weight: 600;
  }

  .mobileListMode {
    display: flex;
    flex-direction: column;

    .mobileListItem {
      border-top: 1px solid #efefef;
      border-bottom: 1px solid #efefef;
      padding: 12px 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .infoItemName {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
        }
        .infoItemValue {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          max-width: 225px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }
      }
    }
  }
}
.idColumn {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}

.invitationsBlock {
  @include for-tablet-landscape-max_1024 {
    padding: 20px 40px !important;
  }
  @include for-tablet-landscape-max_1000 {
    padding: 20px !important;
  }
}
.comingSoonBlock {
  margin-top: 16px;
}
.options {
  font-family: Montserrat !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  white-space: nowrap;
}
.addGroupMobile {
  display: none !important;

  @media screen and (max-width: 660px) {
    display: flex !important;
  }
}
.paginationBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;

  .pagination {
    display: flex;
    justify-content: center;
  }
  .paginationItem {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 8px 15px !important;
    background: #ffffff !important;
    border: 1px solid #efefef;
    border-radius: 12px !important;
    color: #212121 !important;
  }

  .activePaginationItem {
    border: 1px solid #143471 !important;
    border-radius: 12px !important;
  }
  .paginationText {
    margin-top: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #757575;
  }
}
.loadingBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 450px;
}
