@import '../../../../../styles/_mixins.scss';

$red: #cd0606;

.secondInputWrapper {
  position: relative;
  display: flex;
  width: 100%;
  padding: 12px 30px 12px 32px;

  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  border-radius: 10px;

  @include for-mobile-max_767 {
    flex-direction: column;
  }
}

.errorInputWrapper {
  padding: 4px;

  & > .secondInputWrapper {
    border: 2px solid $red;
    padding: 6px 24px 6px 26px;
    // box-shadow: 0 0 10px #D32F2F inset;

    & > .mapBtn {
      @include for-mobile-max_767 {
        top: 6px;
      }
    }

    & > input {
      border-right: 2px solid $red;

      @include for-mobile-max_767 {
        border-right: none;
        border-bottom: 2px solid $red;
      }
    }
  }
}

.line {
  border: 1px solid #212121;
  width: 0px;
}

.input {
  width: 65%;
  background: transparent;
  border-width: 0px;
  outline: none;
  color: #212121 !important;
  border-right: 2px solid #212121;
  padding-right: 50px;
  border-radius: 0px;

  &::-webkit-input-placeholder {
    font-size: 14px;
    font-weight: 500;
    color: #555555;
  }

  &::-moz-placeholder {
    font-size: 14px;
    font-weight: 500;
    color: #555555;
  }

  &:-ms-input-placeholder {
    font-size: 14px;
    font-weight: 500;
    color: #555555;
  }

  &::-ms-input-placeholder {
    font-size: 14px;
    font-weight: 500;
    color: #555555;
  }

  &::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: #555555;
  }

  @include for-mobile-max_767 {
    width: 100%;
    line-height: 24px;
    border-right: none;
    border-bottom: 2px solid #212121;
  }
}

.placeholderRed {
  &::-webkit-input-placeholder {
    color: $red;
  }

  &::-moz-placeholder {
    color: $red;
  }

  &:-ms-input-placeholder {
    color: $red;
  }

  &::-ms-input-placeholder {
    color: $red;
  }

  &::placeholder {
    color: $red;
  }
}

.descriptionWrapper {
  width: 35%;
  margin-left: 20px;
  line-height: 20px;

  @include for-mobile-max_767 {
    width: 100%;
    margin-left: 0;
  }
}

.description {
  display: flex;
  width: 100%;
  color: #000;
}

.successMessage {
  color: #237d26;
}

.error {
  color: $red;
}

.privacyLink {
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  cursor: pointer;
}

.formPassShowBtn {
  position: absolute;
  left: 58%;
  top: calc(50% - 8px);
  width: 22px;
  height: 22px;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -3px;

  .iconWrapper {
    position: relative;
    width: 22px;
    height: 22px;

    > span {
      width: 22px;
      height: 22px;

      > svg > path {
        fill: #757575 !important;
      }
    }
  }

  @include for-tablet-landscape-max_1024 {
    left: 55%;
  }

  @include for-mobile-max_767 {
    top: 16px;
    left: 85%;
  }

  @include for-mobile-max_480 {
    left: 80%;
  }
}

.safariEyeIcon {
  position: absolute;
  left: 53%;
  top: calc(50% - 8px);
  width: 22px;
  height: 16px;
  border: none;
  background: none;

  .iconWrapper {
    position: relative;
    width: 100%;
    height: 100%;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  @include for-desktop-up-max_1440 {
    left: 50%;
  }

  @include for-tablet-landscape-max_1024 {
    left: 48%;
  }

  @include for-mobile-max_767 {
    top: 16px;
    left: 77%;
  }

  @include for-mobile-max_620 {
    left: 70%;
  }

  @include for-mobile-max_480 {
    left: 65%;
  }
}

.mapBtn {
  position: absolute;
  left: 58%;
  top: calc(50% - 11px);
  width: 22px;
  height: 22px;
  border: none;
  background: none;

  > span {
    width: 22px;
    height: 22px;

    > svg > path {
      fill: #757575 !important;
    }
  }

  @include for-tablet-landscape-max_1024 {
    left: 55%;
  }

  @include for-mobile-max_767 {
    top: 12px;
    left: 88%;
  }

  @include for-mobile-max_480 {
    left: 82%;
  }
}

.locationInput {
  cursor: pointer;
}

.formError {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: unset;
  color: $red;
}

.passwordValidationWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 10px;
  padding-left: 30px;
  width: 80%;

  > div {
    margin: 2px 7px;
    font-size: 14px;
    font-weight: 400;
    // letter-spacing: 0.03333em;
  }
}
