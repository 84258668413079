.leftSection {
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin-left: 80px;
  }
}

.mainSection_1 {
  position: relative;
  color: #ffffff99;
  font-weight: 800;
  font-size: 80px;
  margin-top: 20px;
  margin-left: 80px;
  width: 500px;
  margin-bottom: -5px;
  text-align: right;
  line-height: 82.12px;

  > span {
    font-size: 120px;
  }

  @media (max-width: 1500px) {
    font-size: 70px;
    margin-left: 0;
    width: 440px;
    line-height: 56.98px;

    > span {
      font-size: inherit;
    }
  }

  @media (max-width: 1200px) {
    font-size: 40px;
    font-weight: 600;
    margin-left: -220px;
    color: #ffffff80;
    line-height: 36.56px;
    margin-top: 200px;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 16.29px;
    margin-top: 107px;
    margin-bottom: -2px;
    margin-left: -370px;
  }
}

.mainSection_2 {
  position: relative;
  color: #ffffff66;
  font-weight: 700;
  font-size: 120px;
  line-height: 105.48px;
  margin-top: -190px;
  margin-left: 610px;
  margin-bottom: -10px;

  @media (max-width: 1500px) {
    font-size: 80px;
    margin-top: -117px;
    margin-left: 470px;
    line-height: 74.32px;
  }

  @media (max-width: 1200px) {
    color: #ffffff99;
    font-weight: 800;
    font-size: 120px;
    margin-left: 0;
    text-align: right;
    line-height: 97.68px;
    margin-top: -180px;
    padding-bottom: 80px;
  }

  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 36.76px;
    margin-top: -70px;
    margin-left: 0;
    padding-bottom: 60px;
  }
}

.stepSection_1 {
  position: relative;
  color: #ffffff99;
  font-weight: 600;
  font-size: 50px;
  line-height: 40px;
  width: 320px;
  margin-top: 20px;
  margin-left: 90px;
  margin-bottom: 0;
  text-align: right;

  @media (max-width: 1500px) {
    font-size: 40px;
    line-height: 32px;
    margin-left: -30px;
  }

  @media (max-width: 1200px) {
    color: #ffffff80;
    font-size: 20px;
    font-weight: 700;
    line-height: 15.5px;
    width: 120px;
    margin-left: 0;
    padding-top: 20px;
  }

  @media (max-width: 768px) {
    font-weight: 600;
    font-size: 12px;
    line-height: 10.5px;
    padding-top: 0;
    margin-left: -30px;
  }
}

.stepSection_img {
  width: 200px;
  margin-left: 400px;
  margin-top: -120px;

  @media (max-width: 1500px) {
    width: 150px;
    margin-left: 275px;
  }

  @media (max-width: 1200px) {
    display: none;
  }
}

.stepSection_2 {
  position: relative;
  color: #ffffff99;
  font-weight: 700;
  font-size: 100px;
  line-height: 87.9px;
  width: 500px;
  margin-top: -45px;
  margin-left: 75px;
  text-align: right;

  @media (max-width: 1500px) {
    font-size: 80px;
    line-height: 65.92px;
    width: 400px;
    margin-left: 10px;
    margin-top: -20px;
  }

  @media (max-width: 1200px) {
    font-weight: 800;
    font-size: 70px;
    margin-top: -53px;
    width: 550px;
    margin-left: 125px;
    padding-bottom: 25px;
  }

  @media (max-width: 768px) {
    margin-top: -43px;
    font-weight: 800;
    font-size: 24px;
    margin-left: 85px;
    width: 200px;
    padding-bottom: 0;
  }
}

.stepContent {
  color: #ffffff;
  font-size: 25px;
  font-weight: 500;
  line-height: 37.5px;
  width: 100%;
  max-width: 580px;
  margin-left: 60px;

  > div {
    margin-bottom: 40px;

    @media (max-width: 1500px) {
      margin-bottom: 30px;
    }

    @media (max-width: 1200px) {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1500px) {
    max-width: 480px;
    font-size: 20px;
  }

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    margin-left: 25px;
    margin-right: 30px;
    width: unset;
    font-size: 12px;
    line-height: 20px;
  }
}
