@import '../../styles/_mixins.scss';

.formBody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 64px;
  max-width: 510px;
  width: 100%;
  margin-bottom: 100px;

  @include for-mobile-max_767 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px 24px;
    max-width: 100%;
    gap: 0;
    margin-bottom: 0;
  }
}
