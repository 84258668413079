@import '../../../../styles/_mixins.scss';

.itemWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: center;
  margin-bottom: 12px;

  @include for-mobile-max_480 {
    max-width: 150px;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
}

.text {
  margin: 0 6px 0 10px;
}
