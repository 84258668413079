.companySelect {
  width: 100%;
}
.tabWrapper {
  margin-bottom: 16px;
  @media screen and (max-width: 480px) {
    display: none !important;
  }
}
.selectWrapper {
  @media screen and (min-width: 480px) {
    display: none !important;
  }
}
.selectType {
  background: #ffffff;
  border-radius: 12px !important;
  border: none !important;
  outline: none !important;
  margin-bottom: 16px;
  width: 100%;

  &:active,
  &:hover,
  &:focus {
    outline: 0 !important;
    outline-offset: 0 !important;
  }

  & > div {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #143471 !important;
  }

  @media screen and (max-width: 1350px) {
    display: flex !important;
  }
}
.listWrapper {
  height: 260px;
  overflow-y: auto;

  &.filter {
    height: 200px;
  }

  .listItem {
    align-items: center;
    &.selected {
      &::after {
        content: url(../../../../../public/images/checkMark.svg);
        width: 20px;
        height: 20px;
      }
    }
    .listText {
      &.selected {
        color: #143471;
        span {
          font-weight: 500;
        }
      }
    }
  }
}

.modal {
  .text {
    margin-top: 16px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
  }

  .buttonBlock {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.companyBB {
      @media screen and (max-width: 540px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 16px;
        button {
          max-width: 100%;
          width: 100%;
        }
      }
    }
    &.roleBB {
      @media screen and (max-width: 460px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 16px;
        button {
          max-width: 100%;
          width: 100%;
        }
      }
    }
    &.alertBB {
      @media screen and (max-width: 400px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: fit-content;
        gap: 16px;
        button {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
}
