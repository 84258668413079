@import '../../../styles/_mixins.scss';

.roles {
  width: 40vw;
  margin: 5vh auto 5vh;

  @include for-mobile-max_767 {
    width: 70vw;
    margin: 25.25vh auto 10vh;
  }

  @include for-mobile-max_480 {
    margin: 10vh auto;
  }

  @include for-screen_3_4 {
    width: 50vw;
  }
}

.rolesWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include for-mobile-max_480 {
    display: block;
  }
}

.rolesTitle {
  font-weight: 600;
  color: #fff;
  text-align: center;
  font-size: 1.875vw;
  margin-bottom: 3.125vw;
  margin-left: 0px;

  @include for-mobile-max_767 {
    font-size: 3.255vw;
    margin-bottom: 8.75vw;
  }

  @include for-screen_3_4 {
    font-size: 2vw;
    margin-bottom: 1.5vw;
  }

  @include for-mobile-max_480 {
    font-size: 4.6875vw;
    margin-bottom: 6vw;
  }
}
