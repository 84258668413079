#deleteButton {
  border-color: #d32f2f !important;
  color: #d32f2f !important;
  > svg > path {
    fill: #d32f2f;
  }
}

.warningModal {
  max-width: 400px !important;
  .buttonBlock {
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 400px) {
      display: flex;
      flex-direction: column;
      gap: 10px;
      button {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
