.menuBtn {
  position: absolute !important;
  top: 5px;
  right: 50px;
  width: 26px !important;
  height: 26px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  @media screen and (max-width: 480px) {
    right: 15px;
  }
}
