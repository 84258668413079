@import '../../../styles/_mixins.scss';

.fieldContainer {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

.changedPadding {
  padding: 20px 60px !important;

  @include for-mobile-max_480 {
    padding: 20px !important;
  }
}

.editFieldButton {
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  outline: none;
  font: inherit;
  cursor: pointer;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
}

.editButtonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.cancelBtn,
.saveBtn {
  margin: 0;
  padding: 0 20px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
}

.hidden {
  display: none;
}
