@import '../../../../styles/_mixins.scss';

.nameColumn,
.rolesColumn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rolesColumn {
  align-items: flex-start !important;
}

.rolesCollumnWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;

  > span {
    text-transform: capitalize;
  }
}

.idColumn {
  font-family: 'Montserrat' !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 10px;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f1f1f1;
}

.tabs {
  width: 100%;
  height: 56px;

  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding-top: 4px;

  @media screen and (max-width: 1050px) {
    display: none !important;
  }

  .tabItem {
    @media screen and (max-width: 1340px) {
      margin: 0 10px;
    }

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #212121;
    padding: 0;
  }

  .activeItem {
    @include for-desktop-up-max_1500 {
      margin: 5px 10px;
    }
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: #143471 !important;
  }
}

.tableContent {
  width: 100%;
  padding: 24px 60px;
  background: #ffffff;
  border-radius: 12px;
  margin-top: 20px;
  margin-bottom: 60px;

  @include for-mobile-max_767 {
    padding: 24px 20px;
  }

  .tableTotal {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }

  .filters {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 20px !important;

    @include for-desktop-up-max_1500 {
      flex-wrap: wrap;
      margin-top: 0;
    }

    @include for-tablet-landscape-max_1024 {
      justify-content: space-around;
      margin-right: 0;
    }

    .filterSelect {
      // @include for-desktop-up-max_1500{
      //   margin: 32px 0px 0px 0px !important;
      // }

      @media screen and (max-width: 966px) {
        width: 100% !important;
      }

      // margin: 0px 10px;
      width: 257px;
      height: 48px;
      background: #ffffff;
      border: 1px solid #efefef;
      border-radius: 12px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    .searchFilter {
      // @include for-desktop-up-max_1500{
      //   margin-top: 32px;
      // }

      @media screen and (max-width: 966px) {
        width: 100% !important;
        margin-right: 0;
      }

      width: 408px;

      @include for-tablet-landscape-max_1024 {
        width: 538px;
      }

      position: relative;
      height: 48px;
      // margin: 0px 10px 0px 0px;

      .searchFilterIcon {
        position: absolute;
        left: 22px;
        top: 14px;
        z-index: 10;
      }

      .searchFilterInput {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding-left: 56px;
        width: 100%;
        background: #ffffff;
        border: 1px solid #efefef;
        border-radius: 12px;
        height: 100%;

        input {
          ::placeholder {
            font-weight: 400;
            font-size: 16px;
            color: #757575;
          }
        }
      }
    }
  }
}

.menuItem {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.filterSelectPlaceholder {
  font-weight: 400;
  font-size: 16px;
  color: #555555;
}

.pagination {
  justify-content: center;
}

.paginationItem {
  background: #ffffff;
  border: 1px solid #efefef !important;
  border-radius: 12px !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.topMenuItem {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-transform: capitalize;
}

.topSelect {
  display: none !important;
  line-height: 24px !important;
  background: #ffffff !important;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px !important;
  width: 100% !important;
  height: 48px !important;
  border: none !important;

  span {
    font-family: 'Montserrat' !important;
    color: #18397a !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
  }

  @media screen and (max-width: 1050px) {
    display: flex !important;
  }
}
