@import '../../styles/mixins';

.middleContent {
  width: 1020px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1.2% !important;
  margin-right: 1.2% !important;

  @media only screen and (min-width: 1025px) {
    min-width: 545px;
  }

  @include for-tablet-landscape-max_1024 {
    width: 100%;
    padding-bottom: 40px;
  }
}

.tabsContainer {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-bottom: 15px;

  @media screen and (max-width: 1050px) {
    display: none !important;
  }
}

.tabs {
  width: 100%;
  height: 56px;

  padding-top: 4px;

  @media screen and (max-width: 1050px) {
    display: none !important;
  }

  .tabItem {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #212121;
    padding: 0;
    min-width: unset !important;
    margin: 0 20px !important;

    @media screen and (max-width: 1340px) {
      margin: 0 10px;
    }
  }

  .activeItem {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: #143471 !important;

    @include for-desktop-up-max_1500 {
      margin: 5px 10px;
    }
  }
}

.navbarSelectWrapper {
  display: none !important;
  background: #ffffff;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 12px !important;
  border: none !important;
  outline: none !important;
  margin-bottom: 16px;
  width: 100%;

  &:active,
  &:hover,
  &:focus {
    outline: 0 !important;
    outline-offset: 0 !important;
  }

  & > div {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #143471 !important;
  }

  @include for-tablet-landscape-max_1024 {
    display: flex !important;
  }
}

.contentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.noContentWrapper {
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  padding: 24px 60px;

  @media screen and (max-width: 500px) {
    padding: 24px 20px;
  }
}

.noContentHead {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;

  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
}

.noContentText {
  font-size: 16px;
  font-weight: 400;

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
}

.fixedTopElement {
  top: 0;
  position: sticky;
  width: 100%;
}

.newPost {
  width: 100%;
  margin-bottom: 16px;
}

.feedPartLoader {
  margin: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
}
