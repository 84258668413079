@import '/src/styles/mixins';

$greenColor: #4caf50;
$greyColor: #757575;

.globalMainFrame {
  margin-top: 20px;
  .questionTitle {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 10px;
    -ms-flex-align: center;
    align-items: center;
    width: 240px;
    margin-right: 20px;
    word-wrap: break-word;
  }
  .qbuttonsvg {
    overflow: visible;
  }
  .answered {
    fill: $greenColor;
    color: $greenColor;
  }
  .required {
    fill: $greyColor;
    color: $greyColor;
  }
  .sourceBlock {
    margin-bottom: 20px;
    padding: 24px 24px;
    isolation: isolate;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: flex-start;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 6%);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    border-radius: 12px;

    @media screen and (max-width: 580px) {
      padding: 16px;
      flex-direction: column;
      row-gap: 16px;
    }
  }

  .componentNum {
    margin-bottom: 10px;
    font-weight: 700;
  }

  .sourceName {
    font-weight: 700;
    margin-bottom: 20px;
  }
  .componentBlock {
    display: -ms-flexbox;
    display: flex;
    width: 550px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    row-gap: 30px;
    word-wrap: break-word;

    @media screen and (max-width: 580px) {
      width: 100%;
    }
  }

  .componentDetails {
    display: flex;
    width: fit-content;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 50px;

    div {
      span {
        font-weight: 700;
        color: rgb(100, 100, 100);
      }
    }
  }
  .buttonBlock {
    width: 100%;
    text-align: right;
  }
  .spanText {
    font-weight: 700;
    color: rgb(100, 100, 100);
  }
  .globalTitle {
    display: flex;
    flex-direction: row;

    h4 {
      margin-left: 10px;
    }
  }

  .subquestionBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .subquestionText {
      font-weight: 600;
    }
  }
}

.summary {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
