@import '../../../../styles/_mixins.scss';

.accordion {
  width: 100%;
  margin-right: 10px;

  &:last-child {
    padding-bottom: 100px;

    @include for-desktop-up-max_1440 {
      padding-bottom: 50px;
    }

    @include for-mobile-max_767 {
      padding-bottom: 24px;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    label {
      font-weight: 500;
      font-size: 30px;
      line-height: 60px;
      color: rgba(255, 255, 255, 0.7);
      text-transform: uppercase;
      cursor: pointer;

      @include for-desktop-up-max_1440 {
        font-size: 20px;
        line-height: 24px;
      }

      @include for-mobile-max_767 {
        font-size: 14px;
        line-height: 17px;
      }
    }

    button {
      border: none;
      background-color: inherit;
      font-size: 50px;
      height: 36px;
      opacity: 0.4;
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 0.7);
      cursor: pointer;

      @include for-mobile-max_767 {
        height: 12px;
        font-size: 24px;
      }
    }
  }

  .content {
    transition: all 0.3s ease-out;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    cursor: pointer;

    @include text-selection-enabled;

    &.expanded {
      opacity: 1;
      margin-top: 15px;

      @include for-mobile-max_767 {
        margin-bottom: 30px;
      }
    }

    .text {
      font-size: 24px;
      font-weight: 400;
      line-height: 40px;
      color: #ffffff;

      @include for-desktop-up-max_1440 {
        font-size: 14px;
        line-height: 20px;
      }

      @include for-mobile-max_767 {
        font-size: 10px;
        line-height: 15px;
      }
    }
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    @include for-mobile-max_767 {
      width: 100%;
    }
  }

  .contactUsButton {
    padding: 23px 28px;
    width: 304px;
    border-radius: 10px;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    cursor: pointer;
    border: 2px solid #ffffff;
    background: transparent;
    color: #ffffff;

    &:disabled {
      background: lightgray;
      color: grey;
      border: 2px solid grey;
    }

    &:hover {
      border: 2px solid rgba(255, 255, 255, 0.8);
    }

    @include for-mobile-max_767 {
      width: 200px;
      padding: 13px 28px;
      font-size: 18px;
      line-height: 22px;
    }
  }
}
