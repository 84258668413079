.modalBody {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.formRow {
  width: 100%;
  justify-content: space-between;
  gap: 20px;

  > div {
    width: 100%;
  }
}

.column {
  flex-direction: column;
  gap: 10px;
  width: 100%;

  > div {
    width: 100%;
  }
}

.collapse {
  width: 100%;

  > div {
    border-bottom: none !important;
  }
}

.formLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
}

.formImageWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
}

.formImageLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}

.settingsPhotoWrapper {
  position: relative;
  width: 100%;
  height: 300px;

  > img {
    border-radius: 12px;
  }
}
