.panelStyle {
  background-color: white;
  box-shadow: 0px 4px 24px 0px #0000000a;
  border-radius: 16px;
  .collapseText {
    //styleName: Heading/Tab H6 16px-24px-semibold-640;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .childrenContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .answerListContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
