@import '/src/styles/mixins';

.modal {
  width: 608px;
}

.container {
  padding: 18px 0;
  display: flex;
  flex-direction: column;
  gap: 18px;

  .modalItemBlock {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      font-family: Montserrat !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      color: #212121;

      @include for-mobile-max_620 {
        font-size: 16px;
        line-height: 22px;
      }
    }

    .subTitle {
      font-family: Montserrat !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: #212121;
      @include for-mobile-max_620 {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 18px;

    .inputsGroups {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .inputItems {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 12px;

        @include for-mobile-max_620 {
          flex-direction: column;
        }

        .datePicker {
          display: flex;
          flex-direction: column;
          gap: 3px;

          .datePickerInput {
            position: relative;
            width: 100%;

            @include for-mobile-max_767 {
              // width: 90% !important ;
            }

            &[data-type='location'] {
              cursor: pointer !important;
            }

            &[data-type='location'] > label[data-shrink='false'] {
              transform: translate(56px, 14px) scale(1) !important;
            }

            & > label {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 400;

              &[data-shrink='false'] {
                transform: translate(14px, 14px) scale(1);
              }
            }

            & > div {
              font-family: 'Montserrat';
              font-weight: 400;
              font-size: 16px;
              line-height: 23px;
              color: #212121;
              border-radius: 12px;
            }

            & > div > input {
              padding: 12.5px 16px;
            }

            & > div > fieldset {
              border-radius: 12px;
              border: 1px solid #dcdfe2;

              & > legend {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
              }
            }
          }
        }
      }

      .inputItem {
        width: 100%;
        cursor: pointer;
      }
    }

    .buttonGroup {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    .experience {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .uploadAvatar {
      border-radius: 12px;
      border: 1px solid #efefef;
      padding: 20px;
    }
  }
}
