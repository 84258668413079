.radioContainer {
  margin-top: 16px;
}

.body {
  position: relative;
}

.main {
  display: flex;
  flex-direction: column;
}

.text {
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 16px;
  margin-top: 16px;
}

.refreshBlock {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.refreshBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  > span {
    width: 20px;
    height: 20px;

    svg > path {
      fill: #18397a;
    }
  }

  > p {
    margin: 0;
    font-size: 12px;
    color: #18397a;
    text-decoration: underline;
    text-transform: initial;
  }
}

.partInputCell {
  color: #1a1a1a;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
}
