@import '../../../../../styles/_mixins.scss';

.modalRoot {
  background: rgba(250, 250, 250, 0.8);
  backdrop-filter: blur(14px);
}

.passwordModalWrapper {
  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  width: 394px;
  padding: 12px 24px;
}

.modalTitle {
  align-self: flex-start;
  margin-bottom: 24px;

  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #555555;
}

.changeBtn {
  padding: 0 20px;
  margin-bottom: 32px;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
  width: 100%;
}

.modal_input {
  position: relative;
  width: 100%;

  @include for-mobile-max_767 {
    width: 90% !important ;
  }

  & > label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 16px;
    border: 1px solid #efefef;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}

.inputWrapper {
  min-height: 71px;
}

.passwordInputContainer {
  position: relative;

  .passwordEyeIcon {
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
  }
}
