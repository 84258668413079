.parserContentWrapper {
  pre {
    width: 90%;
    color: #41314e;
    line-height: 1.6em;
    font-size: 12px;
    padding: 10px;
    background: #f8f7fa;
    border: 1px solid #f1f1f4;
    box-shadow: none;
    white-space: pre;
    word-wrap: normal;
    resize: vertical;
  }

  a {
    color: #1f4a9d;
    text-decoration: underline;
  }

  p {
    margin-bottom: 16px;
    word-break: break-word;
  }

  ol {
    padding-left: 4px;
    margin-bottom: 16px;
  }

  ul {
    padding-left: 20px;
    margin-bottom: 16px;
  }

  img {
    margin: 10px 0 !important;
  }
}
