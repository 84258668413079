@import '../../../styles/_mixins.scss';

.pageContainer {
  height: 100vh;
  overflow-x: auto;
  padding-bottom: 112px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 5px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  .content {
    margin-top: 32px;
    padding: 0 138px;
    @include for-desktop-up-max_1860 {
      padding: 0 108px;
    }
    @include for-desktop-up-max_1660 {
      padding: 0 44px;
    }
    @include for-desktop-up-max_1440 {
      padding: 0 24px;
    }

    .contentHeader {
      margin-bottom: 24px;

      .contentTitleBlock {
        display: flex;
        align-items: start;
        margin-bottom: 12px;
        justify-content: space-between;
        gap: 24px;

        @include for-mobile-max_620 {
          align-items: center !important;
        }

        .shareButton {
          display: flex;
          align-items: center;
          gap: 12px;
          min-width: 136px;
          border: 1px solid #212121 !important;

          .shareText {
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            color: #212121;
          }

          svg {
            height: 24px;
            width: 24px;
          }
        }
      }

      .backIcon {
        padding: 0;
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;

        @include for-mobile-max_620 {
          flex-direction: column;
          align-items: flex-start;
        }

        &:hover {
          background-color: transparent !important;
        }

        .contentTitle {
          font-family: 'Montserrat';
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;

          @include for-tablet-landscape-max_900 {
            font-size: 20px;
          }
          @include for-mobile-max_620 {
            white-space: normal;
          }
        }
      }
    }

    .video {
      position: relative;

      .videoBackgroundImage {
        width: 100%;
        max-height: 690px;
        object-fit: none;

        @include for-tablet-landscape-max_900 {
          object-fit: cover;
        }
      }

      .videoIconBlock {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .textBlock {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .title {
      font-family: 'Montserrat';
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      color: #212121;
    }

    .description {
      word-wrap: break-word;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
