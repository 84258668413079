.modal_footer {
  display: flex;
  align-items: center;
}

.modal_footer {
  border-top: 2px solid #dbdbdb;
  justify-content: flex-end;
}

.modal_body {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 300px;

  @media only screen and (max-width: 676px) {
    overflow-x: hidden;
    height: 100%;
  }
}

.modal_content {
  padding: 1rem;
  margin: 1rem;
}

.dropdown {
  position: absolute;
  z-index: 3231;
  background-color: white;
}

.modal_textArea {
  position: relative;
  padding-bottom: 16px;

  @media only screen and (max-width: 676px) {
    overflow-x: hidden;
    height: 100%;
  }
}

.textArea {
  width: 100%;
  height: 100% !important;
  border-radius: 12px;
  border: 1px solid #efefef;
  outline: none;
  padding: 12px 20px;
}

.buttonField {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.optionButton {
  display: flex;
  justify-content: space-between;
  width: 17%;
  gap: 10px;

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
}

.prewiew {
  width: 97%;
  height: min-content;
  padding-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.fileBox {
  width: 97%;
  display: flex;
  flex-direction: column;
  height: max-content;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
}

.prewiewImg {
  padding-bottom: 1%;
  width: 100%;
  height: 100% !important;
}

.textNameFile {
  max-width: 90%;
  max-height: max-content;
  border-radius: 12px;
  border: 1px solid #a6a6a6;
  padding: 5px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.prewiewImgDelete {
  position: absolute;
  top: auto;
  z-index: 2;
  left: 95%;
  margin-top: 10px;
  cursor: pointer;
}

.prewiewImgDelete {
  position: absolute;
  top: auto;
  z-index: 2;
  left: 95%;
  margin-top: 10px;
  cursor: pointer;
}

.modal_option {
  margin: 16px 0;
}

.select {
  > div > div {
    font-size: 16px;
    font-weight: 500;

    > img {
      height: 26px;
    }

    @media only screen and (max-width: 676px) {
      font-size: 14px;
    }
  }
}

.selectBody {
  box-shadow: 0px 4px 32px 0px #00000014 !important;
  border-radius: 12px !important;

  li {
    margin: 5px 0;
    background-color: transparent !important;

    &:hover {
      background-color: inherit !important;
      color: #143471 !important;
    }
  }
}

.selectItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  height: 32px;

  > span {
    margin-right: 9px;
  }
}

.avatar {
  width: 24px !important;
  height: 24px !important;
  font-size: 12px !important;
  margin-right: 10px;
}

.inputOptions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;

  > div {
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: none !important;

    > div {
      border: none !important;
    }
  }

  ::before,
  ::after {
    border: none !important;
  }

  *:focus {
    background: transparent !important;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-150px);
  }
  to {
    transform: translateY(0);
  }
}

.submitButton {
  font-weight: 700;
  font-size: 16px;
  height: 48px;
  width: 150px;
  padding: 0 20px;
  margin-left: 15px;

  @media screen and (max-width: 768px) {
    font-size: 12px !important;
    height: 40px !important;
    padding: 0 12px !important;
    width: 111px !important;
    margin-left: 0 !important;
  }
}

.backButton {
  font-weight: 700;
  font-size: 16px;
  height: 48px;
  width: 150px;
  padding: 0 20px;

  @media screen and (max-width: 768px) {
    font-size: 12px !important;
    height: 40px !important;
    padding: 0 12px !important;
    width: 111px !important;
    margin-left: 0 !important;
  }
}

.disabledUploadButton {
  opacity: 0.5 !important;
}

.previewContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;

  > div {
    margin-right: 10px;
  }
}

.uploadFileBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}

.uploadFileTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.uploadFileBoxLoading {
  width: 90px;
  height: 14px;
}

.uploadFileBoxClose {
  cursor: pointer;
  width: 20px;
  height: 20px;

  > img {
    width: 14px;
    height: 14px;
  }
}

.uploadVideoBox {
  width: 100%;
  min-height: 250px;
  margin-bottom: 12px;
  position: relative;
  margin: 0 !important;
}

.uploadVideoBoxPreview {
  background: transparent !important;

  > div > div {
    background: transparent !important;
  }

  video {
    border-radius: 12px;
  }
}

.videoPreviewButtons {
  position: absolute;
  top: 25px;
  right: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  > button {
    background-color: transparent;
    border: none;
    outline: none;
    width: 20px;
    height: 20px;
    text-align: center;
    z-index: 1;
    cursor: pointer;

    > img {
      width: 20px;
      height: 20px;
    }
  }
}

.videoPreviewClose {
  filter: invert(100%);
}

.uploadImgBox {
  width: 100px;
  height: 100px;
  margin-bottom: 12px;
  position: relative;

  > img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 12px;
  }
}

.uploadImgBoxFull {
  //width: 100%;
  height: 100%;
  max-height: 300px;
  margin-bottom: 0;
  margin-top: 5px;
  position: relative;

  > img {
    //width: 100%;
    height: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 12px;
  }
}

.uploadImgBoxLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  z-index: 300;
  background-color: rgb(0, 0, 0, 0.5);
}

.uploadImgBoxLoaderProgressBar,
.uploadFileBoxLoaderProgressBar {
  width: 80%;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  overflow: hidden;
  z-index: 301;
}

.uploadImgBoxLoaderProgress,
.uploadFileBoxLoaderProgress {
  height: 100%;
  background-color: #ffffff;
  border-radius: 2px;
  animation: progress-bar 10s ease-out infinite;
  z-index: 302;
}

.uploadFileBoxLoaderProgressBar {
  background-color: rgba(0, 0, 0, 0.3);
}

.uploadFileBoxLoaderProgress {
  background-color: #c2c2c2;
}

@keyframes progress-bar {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.uploadImgClose {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: none;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 8px;
  top: 8px;
  text-align: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;
  align-items: center;
  display: flex;
}

.dndCover {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: calc(100% - 15px);
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  border: 2px dashed #757575;
  z-index: 100;
}

.articleInput,
.articleTextArea {
  width: 100%;
}

.articleTextArea > div {
  padding: 0;

  > textarea {
    padding: 12px 20px !important;
  }
}

.dndArticleCover {
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  z-index: 100;
  margin: 16px 0;
  cursor: pointer;

  * {
    cursor: pointer;
  }
}

.articleEditor {
  height: 100px;
}

.dndCoverContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 8px;
  color: #757575;
}

.dndCoverContentHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
}

.dndIcon {
  width: 20px;
  height: 20px;
  opacity: 0.8;
}

.dndCoverContentDesc {
  font-size: 12px;
  font-weight: 400;
  width: 80%;
  text-align: center;
}

.articleCover {
  background-size: cover;
  width: 100%;
  height: 366px;
}

.articleLink {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #e0edfa;
  width: 100%;
  padding: 12px 32px;
  cursor: pointer;

  * {
    margin: 0 !important;
  }
}

.articleTitle {
  color: #18397a;
  font-size: 16px;
  font-weight: 600;
}

.articleLinkContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.articleBody {
  position: relative;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 22px;

  * {
    font-weight: normal !important;
    font-style: normal !important;
  }
}

.articlePreviewBlock {
  margin-top: 16px;
}

.linkPreviewWrapper {
  position: relative;
  width: calc(100% + 64px);
  margin: 0 -32px !important;
}

.linkPreview {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 56px;
  margin-top: 10px;
}

.linkPreviewError {
  margin-top: 10px;
}

.linkPreviewImage {
  width: 100%;
  height: 100%;
  max-height: 265px;
  background-size: cover;
  object-fit: cover;
  //border-radius: 12px 12px 0 0;
}

.linkPreviewText {
  display: flex;
  flex-direction: column;
  padding: 12px 32px;
  background: #e0edfa;
  gap: 5px;

  > div {
    font-size: 16px;
    font-weight: 600;
    color: #18397a;
  }

  > p {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
}
