@import '../../../../../styles/_mixins.scss';

.buttonsWrapper {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @include for-mobile-max_767 {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

.buttonsWrapperContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.oneChild {
  justify-content: flex-end;
}

.firstBtn,
.secondBtn {
  padding: 13px 28px;
  width: 146px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  border: 2px solid #ffffff;

  &:disabled {
    background: lightgray;
    color: grey;
    border: 2px solid grey;
  }

  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.8);
  }
}

.firstBtn {
  background: transparent;
  color: #ffffff;

  @include for-mobile-max_480 {
    margin-bottom: 16px;
  }
}

.secondBtn {
  background: #ffffff;
  color: #333333;
}

.rightButtons {
  display: flex;
  align-items: center;
  gap: 20px;

  @include for-mobile-max_767 {
    flex-direction: column;
    align-items: center;
  }
}
.googleBtn {
  cursor: pointer;
  border-radius: 10px;
  border: none;
  color: #18397a;
  background-color: #ffffff;
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 3px 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  text-transform: uppercase;
  span {
    height: 40px;
  }
}
