@import '../../../../styles/_mixins.scss';

.ofiColumn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nameColumn {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 16px;

  button {
    padding: 0;
  }
}

.nameColumnIcon {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 16px;

  button {
    padding: 0;
  }
}

.createdByColumn {
  cursor: pointer;
  text-decoration-line: underline;
  color: #143471;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    padding: 0;
  }
}
.idColumn {
  font-family: 'Montserrat' !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tabs {
  width: 100%;
  height: 56px;

  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 12px;

  .tabItem {
    @media screen and (max-width: 1340px) {
      margin: 0 10px;
    }

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #212121;
    margin-top: 5px;
    padding: 0;
  }
  .activeItem {
    @include for-desktop-up-max_1500 {
      margin: 5px 10px;
    }
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: #143471 !important;
  }
}

.tableContent {
  width: 100%;
  padding: 24px 60px;
  background: #ffffff;
  border-radius: 12px;
  margin-top: 20px;
  margin-bottom: 60px;
  @include for-desktop-up-max_1200 {
    padding: 24px 40px;
  }
  @include for-mobile-max_767 {
    padding: 24px 20px;
  }
  .tableTotal {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }
  .filters {
    display: flex;
    width: 83%;
    @include for-desktop-up-max_1660 {
      width: 100%;
    }
    @include for-desktop-up-max_1300 {
      flex-wrap: wrap;
    }
    column-gap: 20px;
    row-gap: 12px;

    .filterSelect {
      width: 257px;
      @include for-tablet-landscape-max_900 {
        width: 100%;
      }
      height: 48px;
      background: #ffffff;
      border: 1px solid #efefef;
      border-radius: 12px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    .searchFilter {
      width: 408px;
      @include for-desktop-up-max_1300 {
        width: 100%;
      }
      position: relative;
      height: 48px;
      .searchFilterIcon {
        position: absolute;
        left: 22px;
        top: 14px;
        z-index: 10;
      }
      .searchFilterInput {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding-left: 56px;
        width: 100%;
        background: #ffffff;
        border: 1px solid #efefef;
        border-radius: 12px;
        height: 100%;
        input {
          ::placeholder {
            font-weight: 400;
            font-size: 16px;
            color: #757575;
          }
        }
      }
    }

    .selects {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;

      @include for-tablet-landscape-max_900 {
        flex-direction: column;
      }
    }
  }
}

.menuItem {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.filterSelectPlaceholder {
  font-weight: 400;
  font-size: 16px;
  color: #555555;
}
.pagination {
  justify-content: center;
}
.paginationItem {
  background: #ffffff;
  border: 1px solid #efefef !important;
  border-radius: 12px !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.topMenuItem {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-transform: capitalize;
}
.topSelect {
  line-height: 24px !important;
  background: #ffffff !important;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px !important;
  width: 100% !important;
  height: 48px !important;
  border: none !important;
  p {
    font-family: 'Montserrat' !important;
    color: #18397a !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
  }
}
.datePickerInput {
  position: relative;

  @include for-tablet-landscape-max_900 {
    width: 100%;
  }

  &[data-type='location'] {
    cursor: pointer !important;
  }

  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }

  & > label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;

    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }

  & > div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
    border-radius: 12px;
  }

  & > div > input {
    padding: 12.5px 16px;
  }

  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #dcdfe2;

    & > legend {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
    }
  }
}
