.messageBubble {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 12px 12px 12px 0;
  margin: 10px;
  padding: 10px;
  height: max-content !important;
  width: max-content;
  max-width: 95%;
  gap: 8px;
}
.date {
  text-align: center;
  font-family: Montserrat;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: #757575 !important;
}
.messageBubbleBlue {
  display: flex;
  align-items: flex-end;
  text-align: left;
  background: #e8f5fe;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 12px 12px 0 12px;
  margin: 10px;
  padding: 10px;
  height: max-content !important;
  width: max-content;
  max-width: 95%;
  gap: 8px;
  float: right;
}

.messageText {
  white-space: pre;
  user-select: text;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  word-wrap: break-word;
  height: max-content;
  max-width: 300px;
  span {
    white-space: break-spaces;
  }
}

.time {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 8px;
}

.callBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
}

.callIcon {
  width: 28px !important;
  height: 28px !important;
  background: #18397a !important;
  border-radius: 50% !important;
  padding: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.callTime {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 8px;
  margin: 0 5px;
}

.callBubble {
  padding: 12px 24px 12px 20px;
  gap: 48px;
}

.linkMessage {
  cursor: pointer;
  color: blue;
  text-underline-mode: blue;
}
