@import '../../../styles/_mixins.scss';

.filterSelect {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  width: 100% !important;
  height: 48px !important;
  border-radius: 12px !important;
}
.menuItem {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #424242 !important;
  white-space: normal !important;
  word-break: break-all;
}

.tabItem {
  font-family: 'Montserrat' !important;
  text-transform: none !important;
  font-style: normal !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  max-width: fit-content !important;
}

.pageContainer {
  height: 100vh;
  overflow-x: auto;
  padding-bottom: 112px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 5px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
}

.content {
  margin-top: 30px;
  padding: 0 138px;
  @include for-desktop-up-max_1860 {
    padding: 0 108px;
  }
  @include for-desktop-up-max_1660 {
    padding: 0 44px;
  }
  @include for-desktop-up-max_1440 {
    padding: 0 56px;
    margin-top: 20px;
  }

  @include for-desktop-up-max_1200 {
    padding: 0 24px;
  }

  @include for-mobile-max_767 {
    margin-top: 15px;
  }
}

.filterBar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: 48px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    height: auto;
  }

  .filterTabs {
    width: 70%;
    height: 48px;
    padding-right: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    @include for-desktop-up-max_1440 {
      height: 50px;
    }
    align-items: center;
  }

  .searchFilter {
    width: 30%;
    height: 100%;
    position: relative;

    @media screen and (max-width: 600px) {
      width: 100% !important;
    }

    .searchFilterIcon {
      position: absolute;
      left: 22px;
      top: 14px;
      z-index: 10;
    }

    .searchFilterInput {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-left: 56px;
      width: 100%;
      border-radius: 12px;
      background: #ffffff;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
      border: none;
      height: 100%;

      input {
        ::placeholder {
          font-weight: 400;
          font-size: 16px;
          color: #757575;
        }
      }
    }
  }
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;

  .noDataText {
    font-size: 16px;
    font-weight: 600;
    @include for-mobile-max_767 {
      font-size: 12px;
      height: 150px;
    }
  }
}

.card {
  position: relative;
  width: 100%;
  height: 428px;
  border-radius: 11px !important;
  margin-top: 32px;
  @include for-desktop-up-max_1440 {
    height: 340px;
    margin-top: 20px;
  }

  @include for-mobile-max_767 {
    margin-top: 16px;
  }
}

.cardImage {
  width: 100%;
  height: 100%;
}

.cardContent {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  height: 40%;
}

.cardTitle {
  font-weight: 700;
  font-size: 24px !important;
  @include for-desktop-up-max_1440 {
    font-size: 16px !important;
  }
  text-transform: uppercase;
  color: #ffffff;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.cardActions {
  font-weight: 500;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.88);
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  padding: 16px;
  right: 0;
}
.videoPlayButtonBlock {
  position: absolute;
  top: 0;
  bottom: 150px;
  left: 0;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
