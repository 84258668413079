@import '/src/styles/mixins';

.container {
  width: 100%;
  max-width: 1020px;
  border-radius: 12px;
  display: flex;
  align-content: center;
  flex-direction: column;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include for-mobile-max_767 {
    font-size: 14px !important;
  }
  @include for-desktop-up-max_1200 {
    max-width: 1020px !important;
  }
}

.contentBox {
  padding-bottom: 10px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0px !important;
  margin-bottom: 20px;
}

.buttonMenu {
  margin-bottom: 20px;
  height: auto;
  background-color: white;
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
  @include for-tablet-landscape-max_1024 {
    display: none;
  }
}

.slideButton {
  display: flex;
  justify-content: space-around;
  width: 160px;
  height: 24px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  background-color: white;
  color: #757575;
  border: none;
  outline: none;
}

.modal_button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal_buttonCancel {
  display: flex;
  justify-content: center;
  width: 69px;
  height: 48px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  background-color: #e5e5e5;
  color: #18397a;
  border: none;
  outline: none;
}

.modal_buttonSend {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 48px;
  width: 160px; /*  */
  background: rgba(24, 57, 122, 1);
  border: none;
  outline: none;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  color: rgba(255, 255, 255, 1);
  border-radius: 6px;
}

.loaderWrapper,
.topLoader {
  width: 100%;
  max-width: 1020px;
  background-color: white;
  display: flex;
  //justify-content: center;
  //align-items: center;
  flex-direction: column;
  padding: 30px;
  margin-bottom: 15px;
  gap: 10px;
  border-radius: 12px;
}

.topLoader {
  padding: 20px 30px;
}

.loaderAvatar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div,
  > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  @include for-mobile-max_767 {
    justify-content: center;

    > div {
      display: none;
    }
  }
}

.deletedUser {
  background: #fff;
  border-radius: 12px;
  padding: 24px 0px;
  display: flex;
  justify-content: center;
}

.deletedText {
  p {
    &:last-child {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      a {
        color: #143471;
        text-decoration: underline;
      }
    }
    &:first-child {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}
