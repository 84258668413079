@import '../../../../styles/_mixins.scss';

.imageBg {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-repeat: space;
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.35);
  overflow-x: hidden;
  overflow-y: auto;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include for-desktop-up-min_1860 {
    height: calc(100% - 100px);
  }
  @include for-desktop-up-max_1860 {
    height: calc(100% - 100px);
  }
  @include for-mobile-max_767 {
    height: calc(100% - 60px);
  }

  @include for-mobile-max_767 {
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.menuPageWrapper {
  @include for-desktop-up-min_1860 {
    height: calc(100% - 293px);
  }
  @include for-desktop-up-max_1860 {
    height: calc(100% - 290px);
  }
  @include for-desktop-up-max_1440 {
    height: calc(100% - 290px);
  }
  @include for-tablet-landscape-max_1024 {
    height: calc(100% - 250px);
  }
  @include for-mobile-max_767 {
    height: calc(100% - 210px);
  }

  @include for-mobile-max_767 {
    align-items: center;
    justify-content: center;
  }

  @include for-mobile-max_480 {
    height: calc(100% - 280px);
  }
}

.signUpFormWrapper {
  padding: 40px 14vw;
  justify-content: flex-start;

  @include for-desktop-up-max_1860 {
    padding: 40px 10vw;
  }

  @include for-desktop-up-max_1440 {
    padding: 40px 6vw;
  }

  @include for-desktop-up-max_1200 {
    padding: 40px 5px;
  }

  @include for-tablet-landscape-max_1024 {
    padding: 40px 0px;
  }
  @include for-mobile-max_767 {
    padding: 0;
  }
}

.sdgWrapper {
  padding: 40px 14vw;
  justify-content: flex-start;
  height: max-content !important;

  @include for-desktop-up-max_1860 {
    padding: 0 10vw;
  }

  @include for-desktop-up-max_1440 {
    padding: 40px 6vw;
  }

  @include for-desktop-up-max_1200 {
    padding: 40px 5px;
  }

  @include for-tablet-landscape-max_1024 {
    padding: 40px 0px;
  }
}

.goalWrapper {
  padding: 12px 50px;

  @include for-desktop-up-max_1440 {
    height: max-content;
    padding: 12px 40px 30px 40px;
  }

  @include for-mobile-max_480 {
    padding: 12px 24px 30px 24px;
  }
}

.goalPageBg {
  backdrop-filter: blur(6px);
}
