.tagWrapper {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  height: max-content;
  text-align: center;
  width: max-content;
  background: #efefef;
  border-radius: 12px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  color: #212121;
}

.deleteIconWrapper {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.hidden {
  display: none;
}
