@import '../../../../../../src/styles/mixins';

.gridContent {
  height: 60vh;
  margin-top: 20px !important;
  padding-bottom: 20px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-radius: 12px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.card {
  min-height: 294px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  overflow: unset !important;
  width: 100%;
  border-radius: 16px !important;
  position: relative;

  @include for-mobile-max_620 {
    padding: 20px;
  }

  .cardImageBlock {
    min-width: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .type {
    color: #212121;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }

  .description {
    color: #757575;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cardActions {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 2px;
    top: 8px;
  }
  .userInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    .userName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: 'Montserrat';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .experiences {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      font-family: 'Montserrat';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      .experienceName {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        .greenText {
          color: #4caf50;
          margin-left: 3px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .roleName {
      text-transform: capitalize;
      color: #d8913c;
      font-family: 'Montserrat';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .connectionActions {
    display: flex;
    align-items: center;

    .unFollowBtn {
      padding-right: 15px !important;
      padding-left: 15px !important;
      font-family: 'Montserrat' !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      text-transform: uppercase;
    }

    .iconBtn {
      border-radius: 50% !important;
    }

    .itemIcon {
      width: 24px;
      height: 24px;

      svg > path {
        fill: #18397a !important;
      }
    }
  }
}

.options {
  font-family: Montserrat !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  white-space: nowrap;
}
