@import '../../../styles/_mixins.scss';

.groupName {
  text-decoration: underline !important;
  color: #143471 !important;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  .select {
    width: 100% !important;
    height: 48px !important;
    background: #ffffff !important;
    border: none !important;
    font-family: 'Montserrat' !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    border-radius: 12px !important;
  }

  .autocomplete {
    padding: 0 !important;
  }
  .inputGroup {
    position: relative;
    width: 100%;

    .textField {
      position: relative;
      width: 100%;

      &[data-type='location'] {
        cursor: pointer !important;
      }

      &[data-type='location'] > label[data-shrink='false'] {
        transform: translate(56px, 14px) scale(1) !important;
      }

      & > label {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        padding-left: 40px;

        &[data-shrink='false'] {
          transform: translate(14px, 14px) scale(1);
        }

        &[data-shrink='true'] {
          padding: 5px;
        }
      }

      & > div {
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #212121;
      }

      & > div > input {
        padding: 12.5px 54px !important;
      }
      & > div > input:not(:empty) {
        padding: 12.5px;
      }
      & > div > fieldset {
        border-radius: 12px;
        border: 1px solid #efefef;

        & > legend {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
        }
      }
    }

    .searchInput {
      font-family: 'Montserrat';
      width: 100%;
      height: 48px;
      padding: 0 56px;
      background: #ffffff;
      border: 1px solid #efefef;
      border-radius: 12px;
    }

    .searchIcon {
      display: flex;
      align-items: center;
      position: absolute;
      left: 22px;
      bottom: 14px;
    }
  }

  .radioBlock {
    display: flex;
    flex-direction: column;

    .radioLabel {
      font-family: 'Montserrat';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .subLabel {
      font-family: 'Montserrat';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .formFields {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .inputsRow {
      display: flex;
      gap: 20px;
      align-items: start;

      @include for-mobile-max_620 {
        flex-direction: column;
        gap: 12px;
      }
    }
    .textField {
      position: relative;
      width: 100%;

      &[data-type='location'] {
        cursor: pointer !important;
      }

      &[data-type='location'] > label[data-shrink='false'] {
        transform: translate(56px, 14px) scale(1) !important;
      }

      & > label {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;

        &[data-shrink='false'] {
          transform: translate(14px, 14px) scale(1);
        }
      }

      & > div {
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #212121;
      }

      & > div > input {
        padding: 12.5px 16px;
      }

      & > div > fieldset {
        border-radius: 12px;
        border: 1px solid #efefef;

        & > legend {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }

  .modalButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .sendBtn {
      display: flex;
      align-items: center;
      gap: 8px;
      span:first-child {
        height: 24px;

        svg {
          height: 24px;
          width: 24px;

          path {
            stroke: white;
            fill: none;
          }
        }
      }
    }
  }
}

.listItem {
  display: flex;
  gap: 8px;
  align-items: center;
  min-height: 80px;
  cursor: pointer;
  padding: 0 25px;
}

.usersList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;

  .userListItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .leftSide {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .deleteIcon {
      border-radius: 50% !important;
    }
  }
}
