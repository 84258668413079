.middleContent {
  width: 1020px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1.2% !important;
  margin-right: 1.2% !important;

  @media only screen and (min-width: 1025px) {
    min-width: 545px;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding-bottom: 40px;
  }
}

.postContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 20px 32px;
  border-radius: 12px;
  margin-bottom: 16px;
  user-select: text;

  @media screen and (max-width: 400px) {
    padding: 20px 15px;
  }
}

.commentsWrapper {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #efefef;
  margin-bottom: 5px;
}

.commentUnauthTooltip {
  margin-top: 20px;

  > div {
    margin-bottom: 0 !important;
    //background: #CCE7FC !important;
    //color: #000000 !important;
  }
}
