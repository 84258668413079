.secureLeftSection {
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin-left: 80px;
  }
}

.secureMain_1 {
  color: #ffffffcc;
  font-weight: 600;
  font-size: 50px;
  margin-top: 20px;
  margin-left: 120px;
  margin-bottom: -30px;

  @media (max-width: 1500px) {
    font-size: 35px;
    margin-left: 90px;
  }

  @media (max-width: 1200px) {
    margin-left: 30px;
    margin-bottom: -10px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    margin-left: 0;
    margin-bottom: -20px;
  }
}

.secureMain_2 {
  position: relative;
  color: #ffffff;
  font-weight: 800;
  font-size: 180px;
  margin-left: 200px;
  margin-top: -5px;
  margin-bottom: -20px;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1500px) {
    margin-left: 80px;
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 768px) {
    font-size: 110px;
    margin-left: 20px;
  }
}

.secureMain_3 {
  position: relative;
  color: #ffffff;
  font-weight: 700;
  font-size: 90px;
  margin-left: 200px;
  margin-top: -20px;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1500px) {
    margin-left: 150px;
    font-size: 50px;
  }

  @media (max-width: 1200px) {
    margin-left: 160px;
  }

  @media (max-width: 768px) {
    font-size: 35px;
    margin-left: 20px;
    margin-top: -8px;
  }
}

.secureMain_4 {
  position: relative;
  color: #ffffffcc;
  font-weight: 600;
  font-size: 50px;
  margin-left: 200px;
  margin-top: -20px;
  margin-bottom: -40px;
  z-index: 12;

  @media (max-width: 1500px) {
    margin-left: 150px;
    margin-bottom: -15px;
    font-size: 30px;
  }

  @media (max-width: 1200px) {
    margin-left: 160px;
    margin-top: -15px;
  }

  @media (max-width: 768px) {
    font-size: 25px;
    margin-left: 200px;
    margin-bottom: -5px;
  }
}

.secureMain_5 {
  position: relative;
  color: #ffffff;
  font-weight: 700;
  font-size: 90px;
  margin-left: 300px;
  margin-top: -20px;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1500px) {
    margin-left: 210px;
    font-size: 50px;
  }

  @media (max-width: 1200px) {
    margin-left: 220px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    font-size: 35px;
    margin-left: 20px;
    margin-top: -10px;
  }
}

.secureStepLeft_1 {
  color: #ffffffcc;
  font-weight: 600;
  font-size: 50px;
  margin-top: 20px;
  margin-left: 120px;
  margin-bottom: -30px;

  @media (max-width: 1500px) {
    font-size: 40px;
    margin-left: 60px;
  }

  @media (max-width: 1200px) {
    width: 75px;
    font-size: 20px;
    line-height: 16.8px;
    text-align: right;
    margin-left: 0;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 9.71px;
  }
}

.secureStepLeft_2 {
  position: relative;
  color: #ffffff;
  font-weight: 800;
  font-size: 180px;
  margin-left: 200px;
  margin-top: -5px;
  margin-bottom: -20px;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1500px) {
    font-size: 160px;
    margin-left: 95px;
  }

  @media (max-width: 1200px) {
    font-size: 70px;
    margin-top: -34px;
    margin-left: 80px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    margin-top: 3px;
  }
}

.secureStepLeft_3 {
  position: relative;
  color: #ffffff;
  font-weight: 700;
  font-size: 40px;
  margin-left: 200px;
  margin-top: -20px;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1500px) {
    font-size: 40px;
    margin-left: 104px;
    margin-top: -18px;
  }

  @media (max-width: 1200px) {
    font-size: 20px;
    margin-left: 225px;
    margin-top: -40px;
    padding-bottom: 20px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    margin-top: -8px;
    margin-left: 145px;
  }
}

.secureStepLeft_4 {
  position: relative;
  color: #ffffffea;
  font-weight: 600;
  font-size: 25px;
  margin-left: 200px;
  margin-top: -10px;
  margin-bottom: -10px;
  z-index: 12;

  @media (max-width: 1500px) {
    font-size: 25px;
    margin-left: 106px;
  }

  @media (max-width: 1200px) {
    font-size: 15px;
    margin-top: -38px;
    margin-left: 350px;
  }

  @media (max-width: 768px) {
    font-size: 10px;
    margin-top: -30px;
    margin-left: 218px;
  }
}

.secureStepLeft_5 {
  position: relative;
  color: #ffffff;
  font-weight: 700;
  font-size: 40px;
  margin-left: 260px;
  margin-top: -20px;
  opacity: 0.5;
  z-index: 11;

  @media (max-width: 1500px) {
    font-size: 40px;
    margin-left: 160px;
  }

  @media (max-width: 1200px) {
    font-size: 20px;
    margin-left: 225px;
    margin-top: 5px;
    padding-bottom: 25px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    margin-left: 145px;
    padding-bottom: 20px;
  }
}

.secureStepContentWrapper {
  color: #ffffffea;
  font-weight: 500;
  font-size: 25px;
  width: 100%;
  max-width: 580px;
  line-height: 37.5px;
  margin-left: 60px;

  @media (max-width: 1500px) {
    max-width: 450px;
    font-size: 20px;
  }

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    margin-left: 25px;
    margin-right: 25px;
    width: unset;
    font-size: 12px;
    line-height: 20px;
  }
}
